import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showTimeTrackerWidget } from '../../../store/actions/dashboard.actions'
import {
  Close,
  PlayArrowRounded,
  Minimize,
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons'
import { Container, PlayButton } from './styles'
import { IconButton, Box } from '@material-ui/core'
import { RiStopCircleLine } from "react-icons/all";
import { setTasksByProject } from "../../../store/actions/task.action";
import { getTasksByProject } from "../../../store/selectors/task.selector";
import { getProjects } from "../../../store/selectors";
import { setProjects } from "../../../store/actions/project.actions";
import { timeStyle } from "../../../pages/Dashboard/TimeSheets/ViewAndEdit/timeCalculator";
import Select, { Option } from '../Select'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import * as ProjectApi from '../../../apis/project.api';
import * as TaskApi from '../../../apis/task.api';

const TimeTrackerWidget = () => {
  const dispatch = useDispatch();
  const [isCollapsed, setCollapsed] = useState<boolean>(true);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const [projectId, setProjectId] = useState<string>();
  const [playState, setPlayState] = useState<boolean>(true);
  const [secondReading, setSecondReading] = useState<number>(0);
  const draggleRef = useRef(null);
  const tasks = useSelector(getTasksByProject);
  const projects = useSelector(getProjects);

  useEffect(() => {
    ProjectApi.getAll('').then((res) => dispatch(setProjects(res)));
  }, []);

  useEffect(() => {
    if (projectId) {
      TaskApi.getByProjectId(projectId).then((res) => dispatch(setTasksByProject(res)));
    }
  }, [projectId]);

  const onStart = (event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    // @ts-ignore
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    })
  };

  const onClose = () => {
    dispatch(showTimeTrackerWidget(false));
    const prev = Number(localStorage.getItem('second-reading'));
    localStorage.setItem('second-reading', (secondReading + prev).toString());
  };

  const onPlay = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setPlayState(!playState);
    if (playState) {
      const prev = Number(localStorage.getItem('second-reading'));
      localStorage.setItem('second-reading', (secondReading + prev).toString());
      setSecondReading(0)
    }
  };

  useEffect(() => {
    let interval: number;
    if (playState) {
      interval = setInterval(() => {
        setSecondReading((prevState) => prevState + 1);
      }, [1000]);

      return () => {
        clearInterval(interval);
      }
    }
  }, [playState]);

  return (
    <Draggable
      bounds={bounds}
      onStart={(event, uiData) => onStart(event, uiData)}
    >
      <Container ref={draggleRef}>
        <div className='header'>
          <IconButton
            className='close-btn'
            size='small'
            onClick={onClose}
          >
            <Minimize />
          </IconButton>
          <IconButton
            className='close-btn'
            size='small'
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </div>
        <Box display='flex' flexDirection='column' px={3}>
          <div className='main-content'>
            <Box mr={1} display='flex' alignItems='center'>
              <PlayButton
                onClick={(e) => onPlay(e)}
              >
                {
                  playState ? (
                    <RiStopCircleLine />
                  ) : (
                    <PlayArrowRounded />
                  )
                }
              </PlayButton>
              <Box ml={2}>
                <h2>_</h2>
                <span>No limit</span>
              </Box>
            </Box>
            <Box mr={1}>
              <h1>
                {timeStyle(secondReading).hour}:{timeStyle(secondReading).minute}:{timeStyle(secondReading).second}
              </h1>
              <span>
                Today &nbsp;
                {timeStyle(Number(localStorage.getItem('second-reading'))).hour}
                :{timeStyle(Number(localStorage.getItem('second-reading'))).minute}
                :{timeStyle(Number(localStorage.getItem('second-reading'))).second}
              </span>
            </Box>
          </div>
          {
            !isCollapsed &&
            <Box>
              <div className='divider' />
              <Box mb={2}>
                <Select
                  label='Project'
                  placeholder='Select a project'
                  value={projectId}
                  onChange={(e: string) => setProjectId(e)}
                >
                  {
                    projects.map((project) => (
                      <Option key={project.id} value={project.id as string}>{project.name}</Option>
                    ))
                  }
                </Select>
              </Box>
              <Box mb={2}>
                <Select
                  label='To-do'
                  placeholder='Select a to-do'
                >
                  {
                    tasks.map((task) => (
                      <Option key={task.id} value={task.id as string}>{task.name}</Option>
                    ))
                  }
                </Select>
              </Box>
            </Box>
          }
          <Box
            className='collapse-tag'
            onClick={() => setCollapsed(!isCollapsed)}
          >
            { !isCollapsed ? <ExpandLess /> : <ExpandMore /> }
          </Box>
        </Box>
      </Container>
    </Draggable>
  )
}

export default TimeTrackerWidget;
