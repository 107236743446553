import React, { useMemo } from 'react';
import { useHistory } from "react-router";
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { PlayArrow } from '@material-ui/icons';
import * as S from './styles';
import * as AuthApi from '../../../apis/auth.api';
import Input from '../../../components/Common/Input';
import Checkbox from '../../../components/Common/Checkbox';
import { Button } from '../../../components/Common/Button';
import { GrouponLogo, InstacartLogo, KellerWilliamsLogo, ClippersLogo, CardImage2 } from '../../../components/Common/Images';
import { Form, Formik, FormikProps } from "formik";
import { signUpValidationSchema } from "../../Auth/SignUp";

interface ISignUpInterface {
  name: string;
  email: string;
  password: string;
  isActive: boolean;
}

const TopSection = () => {
  const history = useHistory();

  const initialValues = useMemo(() => {
    return {
      name: "",
      email: "",
      password: "",
      isActive: false
    };
  }, []);

  const handleSubmit = (values: ISignUpInterface) => {
    AuthApi.register(values).then(() => history.push('/auth/sign-in'));
  };

  return (
    <S.Container>
      <div className='container'>
        <S.VideoContainer>
          <img src={CardImage2} alt="card_image" />
          <S.PlayButton><PlayArrow /></S.PlayButton>
        </S.VideoContainer>
        <S.TopSection>
          <div className='left-container'>
            <Formik
              initialValues={initialValues}
              validationSchema={signUpValidationSchema}
              onSubmit={handleSubmit}
            >
              {(props: FormikProps<ISignUpInterface>) => (
                <Form onSubmit={props.handleSubmit}>
                  <div className='message-content'>
                    <h1>Spend less time tracking and more time <span>growing.</span></h1>
                    <p>The all-in-one work time tracker for managing field or remote teams.</p>
                    <Input
                      name='name'
                      placeholder='Name'
                      value={props.values.name}
                      onChange={props.handleChange}
                      error={props.errors.name && props.touched.name ? props.errors.name : ''}
                    />
                    <Input
                      name='email'
                      type='email'
                      placeholder='E-mail'
                      value={props.values.email}
                      onChange={props.handleChange}
                      error={props.errors.email && props.touched.email ? props.errors.email : ''}
                    />
                    <Input
                      name='password'
                      placeholder='Password'
                      type='password'
                      value={props.values.password}
                      onChange={props.handleChange}
                      error={props.errors.password && props.touched.password ? props.errors.password : ''}
                    />
                    <Box display='flex' alignItems='center' mt={2}>
                      <Checkbox
                        name='isActive'
                        checked={props.values.isActive}
                        onChange={props.handleChange}
                      >
                        I agree to the
                      </Checkbox>
                      <Link to='#'>Terms</Link>,&nbsp;
                      <Link to='#'>Privacy Policy</Link>&nbsp;and&nbsp;
                      <Link to='#'>DPA</Link>
                    </Box>
                    <Box my="24px">
                      <Button
                        htmlType='submit'
                        loading={props.isSubmitting}
                        disabled={!props.isValid}
                      >
                        Create account
                      </Button>
                    </Box>
                    <div className='no-required-text'>
                      <span>No credit card required</span>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className='logos-container'>
            <p>Trusted by 40,000+ businesses.</p>
            <div className='logos'>
              <div className='logo-item'>
                <img src={GrouponLogo} alt='Groupon Logo' />
              </div>
              <div className='logo-item'>
                <img src={KellerWilliamsLogo} alt='Keller Williams Logo' />
              </div>
              <div className='logo-item'>
                <img src={ClippersLogo} alt='Clippers Logo' />
              </div>
              <div className='logo-item'>
                <img src={InstacartLogo} alt='Instacart Logo' style={{ maxWidth: 120 }}/>
              </div>
            </div>
          </div>
        </S.TopSection>
      </div>
    </S.Container>
  )
}

export default TopSection;
