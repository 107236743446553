import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import * as S from "./styles";
import AsanaIcon from "../../../../assets/images/dashboard/asana-icon.png";
import JiraIcon from "../../../../assets/images/jira-icon.png";
import ClickUpIcon from "../../../../assets/images/click_up-icon.png";
import TrelloIcon from "../../../../assets/images/trello-icon.png";
import GCalendarIcon from "../../../../assets/images/g-calendar-icon.png";
import MicrosoftIcon from "../../../../assets/images/microsoft-logo-icon.png";
import SlackIcon from "../../../../assets/images/slack-icon.png";
import GithubIcon from "../../../../assets/images/github-icon.png";
import MondayIcon from "../../../../assets/images/monday-icon.png";
import HubstaffIcon from "../../../../assets/images/hubstaff-task-icon.png";
import WiseIcon from "../../../../assets/images/wise-icon.png";
import PayoneerIcon from "../../../../assets/images/payoneer-icon.png";
import GustoIcon from "../../../../assets/images/gusto-icon.png";
import PaypalIcon from "../../../../assets/images/payoneer-icon.png";
import BitwageICon from "../../../../assets/images/bitwage-icon.png";
import TextArea from "../../../../components/Common/TextArea";
import { ArrowBack, ArrowForward, Favorite } from "@material-ui/icons";
import { DefaultButton, OptionalButton, PrimaryButton } from "../../../../components/Common/Button";
import { IOrganization } from "../../../../shared/interfaces";
import * as OrganizationApi from "../../../../apis/organization.api";

const integrations = [
  { label: "Asana", icon: AsanaIcon, value: "asana" },
  { label: "Jira", icon: JiraIcon, value: "jira" },
  { label: "Clickup", icon: ClickUpIcon, value: "clickup" },
  { label: "Trello", icon: TrelloIcon, value: "trello" },
  { label: "G-Calendar", icon: GCalendarIcon, value: "g-calendar" },
  { label: "Microsoft", icon: MicrosoftIcon, value: "microsoft" },
  { label: "Slack", icon: SlackIcon, value: "slack" },
  { label: "Github", icon: GithubIcon, value: "github" },
  { label: "Monday", icon: MondayIcon, value: "monday" },
  { label: "Hubstaff Tasks", icon: HubstaffIcon, value: "hubstaff" }
];

const teams = [
  { label: "Wise", icon: WiseIcon, value: "wise" },
  { label: "Payoneer", icon: PayoneerIcon, value: "payoneer" },
  { label: "Gusto", icon: GustoIcon, value: "gusto" },
  { label: "PayPal", icon: PaypalIcon, value: "paypal" },
  { label: "Bitwage", icon: BitwageICon, value: "bitwage" },
  { label: "Other", value: "other" }
];

interface Props {
  onNext: (value: IOrganization) => void;
  onBack: () => void;
}

const OrganizationWizardIntegrations = (props: Props) => {
  const { onBack, onNext } = props;
  const { id } = useParams();
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
  const [selectedTools, setSelectedTools] = useState<string[]>([]);
  const [extraText, setExtraText] = useState<string>('');

  useEffect(() => {
    OrganizationApi.getById(id).then((res) => {
      if (res.teams && res.tools) {
        setSelectedTeams(JSON.parse(res.teams));
        setSelectedTools(JSON.parse(res.tools));
        setExtraText(JSON.parse(res.teams).reverse()[0]);
      }
    });
  }, []);

  const handleSelectTeams = (value: string) => {
    const newSelectedTeams = selectedTeams.includes(value)
      ? selectedTeams.filter((selectedTeam) => selectedTeam !== value)
      : [...selectedTeams, value];
    setSelectedTeams(newSelectedTeams);
  };

  const handleSelectTools = (value: string) => {
    const newSelectedTools = selectedTools.includes(value)
      ? selectedTools.filter((selectedTool) => selectedTool !== value)
      : [...selectedTools, value];
    setSelectedTools(newSelectedTools);
  };

  return (
    <S.Container>
      <h1 className="title">Unlock the power of integrations</h1>
      <div className="sub-title">
        Seamlessly connect TimeGo with your favorite tools and platforms to streamline processes, boost productivity,
        and achieve greater efficiency.
      </div>

      <div className="field integrations">
        <div className="subtitle">What other tools do you use?</div>
        <div className="options">
          {integrations.map((integration) => (
            <span key={integration.value}>
              <OptionalButton
                onClick={() => handleSelectTools(integration.value)}
                ghost={selectedTools.includes(integration.value)}
              >
                <img alt="Asana" src={integration.icon} />
                {integration.label}
              </OptionalButton>
            </span>
          ))}
        </div>
      </div>

      <div className="field integrations">
        <div className="subtitle">How do you pay your team?</div>
        <div className="options">
          {teams.map((team, index) => (
            <span key={team.value}>
              <OptionalButton
                onClick={() => handleSelectTeams(team.value)}
                ghost={selectedTeams.includes(team.value)}
              >
                {index === 0 && (
                  <div className="preferred-ribbon">
                    <Favorite />
                  </div>
                )}
                {team.icon && <img alt="Asana" src={team.icon} />}
                {team.label}
              </OptionalButton>
            </span>
          ))}
        </div>
        {selectedTeams.includes("other") && (
          <TextArea
            value={extraText}
            onChange={(e) => setExtraText(e.target.value)}
            placeholder="Tell us what payment method you may use separated by new lines"
          />
        )}
      </div>
      <div className="buttons">
        <DefaultButton onClick={onBack}>
          <ArrowBack />
          Back
        </DefaultButton>
        <PrimaryButton
          onClick={() => onNext({
            teams: JSON.stringify([...selectedTeams, extraText]),
            tools: JSON.stringify(selectedTools)
          })}
        >
          Next
          <ArrowForward />
        </PrimaryButton>
      </div>
    </S.Container>
  );
};

export default OrganizationWizardIntegrations;
