import styled from "styled-components";

export const ResetContainer = styled.div`
  display: flex;
  background-color: #f7f7f7;
  height: 100%;

  .reset_container {
    width: 35rem;
    height: 32rem;
    margin: 10rem auto 0;
    background-color: white;
    border-radius: 1rem;
    padding: 0 3rem;
    box-shadow: 1px 2px 6px 2px lightgray;

    @media(max-width: 320px) {
      height: 35rem;
    }

    .title {
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      margin-top: 2rem;
      margin-bottom: 0.8rem;
    }

    Form {
      margin-top: 2.5rem;

      .form-control {
        margin-bottom: 1.5rem;
      }

      .reset-button {
        width: 10rem;
        margin: 1rem auto 2rem;
        height: 2.7rem !important;
      }
    }
  }
`