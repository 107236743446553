import React, { useState, useEffect, useMemo } from 'react'
import { Box } from '@material-ui/core'
import { Form, Formik, FormikProps } from "formik";
import { InfoOutlined } from '@material-ui/icons'
import { SectionTitle, FormControl, StyledTextArea as TextArea, Tooltip } from './styles'
import { Button } from "../../../components/Common/Button";
import styled from 'styled-components'
import Input from '../../../components/Dashboard/Input'
import * as Yup from 'yup'

const Container = styled(Box)`
  max-width: 550px;
  padding-left: ${props => props.theme.spacing(2)}px;

  .logo {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 50px;
      border: 1px solid ${props => props.theme.colors.gray100};
    }

    span {
      font-size: 13px;
      color: ${props => props.theme.palette.dashboard.textPrimary};
      margin-top: 3px;
    }
  }

  .input-group {
    position: relative;
    max-width: 200px;

    .addon-after {
      position: absolute;
      right: 1px;
      top: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: calc(100% - 2px);
      background: #eeeeee;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      color: ${props => props.theme.palette.dashboard.textPrimary};
    }
  }

  ${props => props.theme.breakpoints.down('xs')}{
    padding-left: 0;
    max-width: 100%;

    .input-group {
      max-width: unset;
    }
  }
`

interface IPaymentInterface {
  address: string;
  tax_id: string;
  tax_rate: string;
  pay_note: string;
  pay_term: string;
}

const Invoicing = () => {
  const [imgFile, setImgFile] = useState(null)
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(null)

  const initialValues = useMemo(() => {
    return {
      address: '',
      tax_id:　'',
      tax_rate: '',
      pay_note: '',
      pay_term: ''
    }
  }, []);

  const paymentValidationSchema = Yup.object().shape({
    address: Yup.string().required('This field is required'),
    tax_id: Yup.string().required('This field is required'),
    tax_rate: Yup.string().required('This field is required'),
    pay_note: Yup.string().required('This field is required'),
    pay_term: Yup.string().required('This field is required')
  });

  const handleFileChange = (e) => {
    const file =  e.target.files.length ? e.target.files[0] : null;
    if (file) {
      setImgFile(file);
    }
  }

  useEffect(() => {
    if (imgFile) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        setPreview(reader.result);
      }, false);

      // @ts-ignore
      reader.readAsDataURL(imgFile);
    }
  }, [imgFile]);

  const handleSubmit = (value: IPaymentInterface, { setSubmitting }) => {
    setSubmitting(false);
    console.log('payment', value);
  }

  return (
    <Container pt={3}>
      <Formik
        initialValues={initialValues}
        validationSchema={paymentValidationSchema}
        onSubmit={handleSubmit}
      >
        {(props: FormikProps<IPaymentInterface>) => (
          <Form onSubmit={props.handleSubmit}>
            <SectionTitle>Organization</SectionTitle>
            <FormControl>
              <span className='label'>Address</span>
              <TextArea
                name='address'
                value={props.values.address}
                onChange={(e) => props.setFieldValue('address', e.target.value)}
                rows={4}
                placeholder='Enter your address for the team invoice'
              />
            </FormControl>
            <FormControl>
              <span className='label'>Logo</span>
              <div className='logo'>
                {
                  preview &&
                  <img src={preview as string} alt='logo' />
                }
                <input type='file' onChange={handleFileChange} />
                <span>File size limit: 1MB</span>
              </div>
            </FormControl>
            <SectionTitle>Taxes</SectionTitle>
            <FormControl>
              <span className='label'>Tax ID <Tooltip overlay={'Used to show your tax id for team invoices'} placement='bottom'><InfoOutlined /></Tooltip></span>
              <Input
                name='tax_id'
                value={props.values.tax_id}
                onChange={props.handleChange}
              />
            </FormControl>
            <FormControl>
              <span className='label'>Tax Rate <Tooltip overlay={'Used as your default tax rate for team invoices'} placement='bottom'><InfoOutlined /></Tooltip></span>
              <div className='input-group'>
                <Input
                  name='tax_rate'
                  value={props.values.tax_rate}
                  onChange={props.handleChange}
                />
                <div className='addon-after'>%</div>
              </div>
            </FormControl>
            <SectionTitle>Payment Terms</SectionTitle>
            <FormControl>
              <span className='label'>Notes <Tooltip overlay={'Used as your default note for team invoices'} placement='bottom'><InfoOutlined /></Tooltip></span>
              <TextArea
                name='pay_note'
                value={props.values.pay_note}
                onChange={(e) => props.setFieldValue('pay_note', e.target.value)}
                rows={4}
                placeholder='Enter notes for the term invoice'
              />
            </FormControl>
            <FormControl>
              <span className='label'>Net Terms <Tooltip overlay={'Used as your default net terms for team invoices'} placement='bottom'><InfoOutlined /></Tooltip></span>
              <div className='input-group'>
                <Input
                  name='pay_term'
                  value={props.values.pay_term}
                  onChange={props.handleChange}
                  type='number'
                />
                <div className='addon-after'>days</div>
              </div>
            </FormControl>
            <Box style={{ width: '6rem', float: 'right' }}>
              <Button
                htmlType='submit'
                disabled={!props.isValid}
                loading={props.isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default Invoicing;
