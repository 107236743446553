import React, { useState } from 'react'
import { ExportOutlined, MenuOutlined } from '@ant-design/icons'
import { Box, Hidden } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'

import * as S from './styles'
import { Logo } from '../../Common/Icons'
import ProductsMenu from './ProductsMenu'
import FeaturesMenu from './FeaturesMenu'
import HowItWorksMenu from './HowItWorksMenu'
import WhyHubstaffMenu from './WhyHubstaffMenu'
import SideMenu from '../SideMenu'

interface HeaderProps {
}

const Header = (props: HeaderProps) => {
  const history = useHistory()
  const [activeMenu, setActiveMenu] = useState<string>('')
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)

  const toggleDrawer = (open: boolean) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setOpenDrawer(open)
  }

  const goToSignInPage = () => {
    history.push('/auth/sign-in')
  }

  return (
    <S.HeaderSection {...props}>
      <div className='container'>
        <S.Content>
          <Hidden smDown>
            <Box height={50}><Link to={'/'}><Logo height={50} /></Link></Box>
            <div className='right'>
              <ProductsMenu active={activeMenu === 'products'} setActive={setActiveMenu} />
              <FeaturesMenu active={activeMenu === 'features'} setActive={setActiveMenu} />
              <HowItWorksMenu active={activeMenu === 'how_it_works'} setActive={setActiveMenu} />
              <WhyHubstaffMenu active={activeMenu === 'why_hubstaff'} setActive={setActiveMenu} />
              <Box ml={2}><S.SignInButton onClick={goToSignInPage}><ExportOutlined width={20} height={20}/>Sign in</S.SignInButton></Box>
            </div>
          </Hidden>
          <Hidden mdUp>
            <S.MobileContainer>
              <Link to={'/'}><Logo width={135} height={30} /></Link>
              <S.MobileMenuButton onClick={toggleDrawer(true)}>
                <MenuOutlined />
              </S.MobileMenuButton>
            </S.MobileContainer>
            <SideMenu open={openDrawer} onClose={toggleDrawer(false)} />
          </Hidden>
        </S.Content>
      </div>
    </S.HeaderSection>
  )
}

export default Header
