import React, { useMemo } from 'react'
import { useSelector } from "react-redux";
import { getAccount } from "../../../store/selectors";
import { stringAvatar } from "../Financials/Expenses/helper";
import { Box } from '@material-ui/core'
import { TableContainer } from "../../../components/Dashboard/CommonStyled";
import { Avatar, Table } from "antd";
import styled from 'styled-components'
import * as S from './styles'

const Container = styled(Box)`

  h3 {
    font-weight: bold;
    font-size: 20px;
    color: ${props => props.theme.palette.dashboard.textLight};
  }
`

const Integrations = () => {
  const account = useSelector(getAccount);

  const columns = [
    {
      title: 'Organization',
      dataIndex: 'name',
      render: (name: string) => (
        <>
          <Avatar
            {...stringAvatar(name)}
          />
          <span style={{ marginLeft: 5, fontWeight: 500 }}>{name}</span>
        </>
      )
    },
    {
      title: 'Integrations',
      dataIndex: 'integration',
      render: (integration: string) => (
        <div>{integration}</div>
      )
    }
  ];

  const tableData = useMemo(() => {
    if (account?.organization) {
      return [{
        ...account.organization,
        key: account.organization.id,
        name: account.organization.name
      }]
    }
  }, [account]);

  return (
    <Container pt={4}>
      {
        account?.organization ? (
          <TableContainer>
            <Table
              columns={columns}
              dataSource={tableData}
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
          </TableContainer>
        ) : (
          <>
            <S.TableHeader>
              <Box width='50%'>Organization</Box>
              <Box width='50%'>Integrations</Box>
            </S.TableHeader>
            <Box mt={2} ml={2}>
              <h3>No integrations</h3>
            </Box>
          </>
        )
      }
    </Container>
  )
}

export default Integrations;
