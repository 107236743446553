import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { Logo } from "../../components/Common/Icons";
import { Link } from "react-router-dom";
import * as S from "./styles";
import * as UserApi from "../../apis/user.api"

const Welcome = () => {
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    UserApi.fetchMe().then((res) => setEmail(res.email));
  }, []);

  return (
    <S.Container>
      <div className="illustration-container no-steps"></div>
      <div className="onboarding-container">
        <div className="content welcome">
          <div className="hubstaff-logo">
            <a
              title="Hubstaff online time tracking"
              className="mb-5 ml-auto welcome-top-logo logo-new-onboarding"
              target="_blank"
              href="/"
            >
              <Box height={50} className="mb-5 ml-auto welcome-top-logo logo-new-onboarding">
                <Link to={"/"}>
                  <Logo height={50} />
                </Link>
              </Box>
            </a>
          </div>
          <div className="form-wrapper">
            <div className="form">
              <h1 className="title">Welcome to TimeGo!</h1>
              <div className="text">
                It looks like you're new to <b>TimeGo</b>. There aren't any invitations for <b>{email}</b>. If
                your team isn't on TimeGo yet you can create an organization for them.
              </div>
              <div className="">
                <div className="default">
                  <Box my="24px">
                    <a href="/organizations/wizard" className="primary-btn">
                      Create organization
                    </a>
                  </Box>
                  <img
                    className="welcome-illustration"
                    src="https://server-assets.hubstaff.com/62899462f8/illustrations/welcome_illustration-a3f806f16900b8b546a29189dc8ad38e04203dc6a9872d008f319f78d6dd71f6.svg"
                    alt="welcome"
                  />
                </div>
                <div className="alternative">
                  👋 Not seeing your organization?
                  <div className="paths">
                    <a className="outlined-button" href="/logout?ab=customer_retention">
                      Try a different email
                    </a>
                    <div className="mx-20">or</div>
                    <a className="outlined-button" href="/welcome/request_join">
                      Request access to join
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </S.Container>
  );
};

export default Welcome;
