import React from 'react';
import { Input as AInput, InputProps } from 'antd'
import styled, { css } from "styled-components";

interface Props extends InputProps {
  error?: string;
  label?: string;
  values: { cost: string };
  setFieldValue: (key: string, target: string) => void;
}

const CostInputContainer = styled.div`
  label {
    color: #79899d;
    font-size: 11px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
`;

const InputGroup = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  border-collapse: separate;

  .input-group-addon {
    padding: 9px 20px;
    font-size: 14px;
    font-weight: 400;
    color: #3e4956;
    text-align: center;
    background-color: #eeeeee;
    border: 1px solid #e4e9ef;
    border-radius: 6px 0 0 6px;
    height: 40px;
  }
`;

const StyledInput = styled(AInput)<{error?: string}>`
  &.ant-input {
    padding: 12px 12px !important;
    color: ${(props) => props.theme.palette.text.primary} !important;
    font: 14px 'Lato-Regular' !important;
    line-height: 1.25 !important;
    border: 1px solid #CCCCCC;
    border-radius: 0 8px 8px 0;
    outline: 0 !important;
    box-sizing: border-box !important;
    width: 100% !important;
    height: 40px;

    &:hover:not(:focus) {
      border-color: #c4cfdc;
    }
    &:focus {
      border-color: #2aa7ff;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(42, 167, 255, 0.6);
    }

    ${(props) => props.error && css`
      border-color: red !important;
    `}
  }
`

const ErrorText = styled.p`
  margin: 2px 0 0 0 !important;
  font-size: 12px !important;
  color: red !important;
  line-height: 20px !important;
`

const CostInput = (props: Props) => {
  return (
    <CostInputContainer>
      {!!props.label && <label>{props.label}</label>}
      <InputGroup>
        <span className="input-group-addon">$</span>
        <StyledInput
          id="budget-attributes-cost"
          name="cost"
          type="text"
          placeholder="0.0"
          value={props.values.cost}
          onChange={(e) => props.setFieldValue('cost', e.target.value)}
          className="form-control"
        />
        {
          props.error &&
          <ErrorText>{props.error}</ErrorText>
        }
      </InputGroup>
    </CostInputContainer>
  )
};

export default CostInput;
