import React, { FC } from 'react'
import { addDays } from 'date-fns'
import moment from 'moment'
import { DateRangePicker as RangePicker } from 'react-date-range'
import styled from 'styled-components'
import {
  CalendarToday as CalendarIcon,
  ArrowForward,
  ArrowBack
} from '@material-ui/icons'
import { Dropdown, Menu } from 'antd'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export interface IDateRange {
  startDate: Date
  endDate: Date
}

export interface IDateRangeProps {
  dateRange: IDateRange
  setDateRange: (value: IDateRange) => void
  showActionButtons?: boolean
}

const useStyles = makeStyles(() => ({
  dropdown: {
    marginTop: 15,
    width: 'fit-content'
  }
}));

const Container = styled.div`
  position: relative;
  display: inline-flex;
  height: 38px;

  .button {
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${props => props.theme.colors.gray100};
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    transition: all ease 0.3s;

    svg {
      color: ${props => props.theme.palette.dashboard.textPrimary};
      transition: all ease 0.3s;
      font-size: 20px;
    }

    &:hover {
      svg {
        color: ${props => props.theme.colors.blue50};
      }
    }

    &.disabled {
      background-color: #f4f5f8;
      pointer-events: none;
    }
  }

  .show-date {
    display: flex;
    align-items: center;
    width: 300px;
    border: 1px solid ${props => props.theme.colors.gray100};
    border-radius: 5px;

    .content {
      position: relative;
      display: flex;
      flex: 1;
      align-items: center;
      padding-left: 10px;
      padding-right: 30px;
      user-select: none;
      cursor: pointer;

      span {
        font-size: 13px;
        color: ${props => props.theme.palette.dashboard.textPrimary};
      }

      svg {
        position: absolute;
        right: 10px;
        color: ${props => props.theme.colors.blue50};
        font-size: 17px;
      }
    }

    ${props => props.theme.breakpoints.down(500)} {
      width: fit-content;

      .content {
        span {
          font-size: 11px;
        }
      }
    }
  }
`

const DateRangePicker = styled(RangePicker)`
  ${props => props.theme.breakpoints.down('sm')} {
    .rdrDefinedRangesWrapper {
      display: none;
    }
  }
`

interface IDatePicker {
  range1: {
    startDate: Date,
    endDate: Date
  }
}

const DateRange: FC<IDateRangeProps> = ({ setDateRange, dateRange, showActionButtons }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleDateRangeChange = (item: IDatePicker) => {
    setDateRange({
      startDate: item.range1.startDate,
      endDate: item.range1.endDate
    });
  };

  const onPrev = () => {
    const endDate = addDays(dateRange.startDate, -1)
    const startDate = addDays(endDate, - 6)
    setDateRange({ startDate, endDate })
  }

  const onNext = () => {
    const startDate = addDays(dateRange.endDate, 1)
    const endDate = addDays(startDate, 6)
    setDateRange({ startDate, endDate })
  }

  return (
    <Container>
      {
        showActionButtons &&
          <>
            <div onClick={onPrev} className='button prev'>
              <ArrowBack />
            </div>
            <div onClick={onNext} className={clsx('button next', moment().isSameOrBefore(dateRange.startDate, 'week') && 'disabled')}>
              <ArrowForward />
            </div>
          </>
      }
      <div className='show-date'>
        <Dropdown
          overlay={
            <Menu
              className={classes.dropdown}>
              <DateRangePicker
                moveRangeOnFirstSelection={false}
                onChange={(item: IDatePicker) => handleDateRangeChange(item)}
                months={isMobile ? 1 : 2}
                minDate={new Date(1970, 1, 1)}
                maxDate={addDays(new Date(), 300)}
                direction={isMobile ? 'vertical' : 'horizontal'}
                ranges={[dateRange]}
              />
            </Menu>
          }
          trigger={['click']}
        >
          <div className="content">
            <span className="date-text">
              {moment(dateRange.startDate).format('ddd, MMM DD, YYYY')}
            </span>
            <span>&nbsp;~&nbsp;</span>
            <span className="date-text">
              {moment(dateRange.endDate).format('ddd, MMM DD, YYYY')}
            </span>
            <CalendarIcon fontSize='small'/>
          </div>
        </Dropdown>
      </div>
    </Container>
  )
}

export default DateRange