import styled from 'styled-components'

const Industries = styled.div`
  width: 67%;
  border-right: 1px solid ${props => props.theme.colors.gray100};

  .header {
    border-bottom: 1px solid ${props => props.theme.colors.gray100};
    padding: 10px 20px 20px 20px;

    h3 {
      margin: 0;
    }
  }

  .body {
    padding: 20px 20px 0 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
  }
`

// @ts-ignore
const Teams = styled(Industries)`
  width: 33%;

  .body {
    grid-template-columns: 1fr;
  }
`

const MenuItem = styled.h4`
  font-size: 15px;
  margin-bottom: 25px;
  transition: all ease 0.3s;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }
`

export { Industries, Teams, MenuItem }
