import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 0 0 100px;
  margin: 80px 0;

  .background {
    position: absolute;
    left: 325px;
    top: 0;
    width: 1000px;
    height: 100%;
    background-color: white;
    box-shadow: 0 2px 6px rgba(47,83,151,0.1);
  }

  .carousel-footer {
    position: relative;
    display: flex;
    justify-content: center;

    .tool-bar {
      position: relative;
      width: 174px;
      justify-content: space-between;
      display: inline-flex;
      align-items: center;
      margin: 0 auto;
      z-index: 1;

      svg {
        position: absolute;
        top: -22px;
        color: ${(props) => props.theme.colors.blue};
        cursor: pointer;

        &.next-button {
          right: 0;
        }
      }
    }

    .view-all-case {
      position: absolute;
      right: 10px;
      bottom: 0;
      display: flex;
      align-items: center;
      z-index: 1;
      cursor: pointer;

      p {
        color: ${(props) => props.theme.colors.blue};
        font-size: 14px;
        font-weight: bold;
        margin: 0;
        line-height: 1;
        transition: all ease 0.3s;
      }

      svg {
        color: ${(props) => props.theme.colors.blue};
        margin-left: 5px;
        transition: all ease 0.3s;
      }

      &:hover {
        p {
          color: ${(props) => props.theme.colors.dark};
        }

        svg {
          transform: translate(5px, 0);
        }
      }

      ${(props) => props.theme.breakpoints.down(550)} {
        bottom: 35px;
        right: 0;
      }
    }
  }

  .owl-carousel {
    .owl-dots {
      display: flex;
      justify-content: center;
      margin-top: 50px !important;

      ${(props) => props.theme.breakpoints.down(550)} {
        margin-top: 10px !important;
      }

      .owl-dot {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        margin: 0 5px;

        span {
          width: 5px;
          height: 5px;
        }

        &.active {
          span {
            background: ${(props) => props.theme.colors.dark};
          }
        }
      }
    }
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding: 0 0 50px;
    margin: 50px 0;

    .background {
      left: 210px;
    }
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    padding: 0 0 50px;
    margin: 50px 0;

    .background {
      left: -20px;
    }
  }
`

const CarouselItem = styled.div`
  display: flex;
  margin-left: 25px;
  margin-right: 55px;
  padding-top: 85px;

  .media-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 460px;
    min-width: 460px;
    height: 460px;
    border-radius: 5px;
    overflow: hidden;
    background-size: cover !important;
    background-position: 50% 50%;
  }

  .content {
    position: relative;
    margin-left: 50px;
    padding-top: 30px;
    padding-left: 15px;
    border-bottom: 2px solid #e9eef5;

    .quote-mark {
      position: absolute;
      top: -40px;
      left: -15px;
      width: 113px;
      height: 90px;
      z-index: -1;
    }

    p {
      font-size: 24px;
      margin: 0;
    }

    .content-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 55px;

      > div:first-child {
        flex: 1;

        .author-info {
          display: flex;
          flex-wrap: wrap;

          .author-name {
            font-size: 14px;
            font-weight: 600;
          }

          .author-job {
            font-size: 14px;
          }
        }

        .logo-img {
          height: 45px;
          width: auto;
        }
      }

      button {
        width: 180px !important;
      }
    }
  }

  ${(props) => props.theme.breakpoints.down('md')} {
    margin: 0;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    padding-top: 35px;

    .media-container {
      width: 300px;
      min-width: 300px;
      height: 300px;
    }

    .content {
      margin-left: 30px;
      padding-top: 5px;

      .quote-mark {
        top: -30px;
        left: -10px;
        width: 75px;
        height: 60px;
      }

      p {
        font-size: 18px;
      }

      .content-footer {
        margin-top: 30px;

        .logo-img {
          height: 35px !important;
        }

        button {
          width: 160px !important;
        }
      }
    }
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    padding-top: 35px;

    .media-container {
      width: 200px;
      min-width: 200px;
      height: 200px;
    }

    .content {
      margin-left: 0;
      padding-top: 5px;

      .quote-mark {
        top: -30px;
        left: -10px;
        width: 75px;
        height: 60px;
      }

      p {
        font-size: 16px;
      }

      .content-footer {
        margin-top: 30px;

        .logo-img {
          height: 35px !important;
        }

        button {
          width: 160px !important;
        }
      }
    }
  }

  ${(props) => props.theme.breakpoints.down(550)} {
    padding-top: 35px;
    padding-bottom: 140px;

    .media-container {
      width: 150px;
      min-width: 150px;
      height: 150px;
    }

    .content {
      margin-left: 0;
      padding-top: 5px;

      .quote-mark {
        top: -20px;
        left: 3px;
        width: 55px;
        height: 40px;
      }

      p {
        font-size: 14px;
        overflow: hidden;
        white-space: pre-wrap;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-box-orient: vertical;
      }

      .content-footer {
        position: absolute;
        top: 150px;
        left: -140px;
        width: calc(100vw - 45px);
        margin-top: 30px;

        .logo-img {
          height: 25px !important;
        }

        button {
          width: 160px !important;
          height: 50px !important;
        }
      }
    }
  }
`

const PlayButton = styled.button`
  position: relative;
  width: 100px;
  height: 100px;
  margin: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  padding: 0;
  background-color: ${(props) => props.theme.colors.blue};
  color: white;
  border-radius: 50px;
  border: none;
  z-index: 10;
  cursor: pointer;
  transition: all ease 0.3s;

  svg {
    width: 35px;
    height: 35px;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    width: 60px;
    height: 60px;

    ::before {
      border-width: 8px !important;
      left: -8px !important;
      top: -8px !important;
      width: calc(100% + 16px) !important;
      height: calc(100% + 16px) !important;
    }
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    width: 40px;
    height: 40px;

    svg {
      width: 25px;
      height: 25px;
    }

    ::before {
      border-width: 4px !important;
      left: -4px !important;
      top: -4px !important;
      width: calc(100% + 8px) !important;
      height: calc(100% + 8px) !important;
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.dark};
  }

  ::before {
    left: -12px;
    top: -12px;
    width: calc(100% + 24px);
    height: calc(100% + 24px);
    border: 12px solid rgba(41,77,255,0.2);
    box-shadow: 0 22px 24px rgba(0,0,0,0.1);
    content: '';
    display: block;
    border-radius: 50%;
    box-sizing: border-box;
    position: absolute;
    z-index: -1;
  }
`

export { Container, CarouselItem, PlayButton }
