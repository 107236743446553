import React from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import { Formik, Form } from 'formik'
import { Box } from '@material-ui/core'
import { Tooltip } from "../../Dashboard/Settings/styles";
import { Alert } from 'antd'
import { Button } from '../../../components/Common/Button'
import { SignUpBg } from '../../../components/Common/Images'
import { IRegisterFormData } from "../../../shared/interfaces";
import Checkbox from '../../../components/Common/Checkbox'
import * as Yup from 'yup'
import * as S from './styles'
import * as AuthApi from '../../../apis/auth.api'

export const signUpValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  email: Yup.string()
    .email('Email is not valid.')
    .required('Email is required.'),
  password: Yup.string()
    .required('Password is required.')
    .matches(/[a-zA-Z]/, 'Password should contain at least one alphabet.')
    .matches(/\d/, 'Password should contain at least one number.')
    .min(6, 'Password should contain at least 6 characters.'),
  isActive: Yup.boolean().isTrue().required()
});

const SignUp = () => {
  const history = useHistory()
  const handleSubmit = (values: IRegisterFormData, { setErrors, setStatus, setSubmitting }) => {
    const { name, email, password, isActive} = values
    AuthApi.register({name, email, password, isActive})
      .then((res) => {
        setSubmitting(false)
        history.push(`/confirmation_sent/${res.accessToken}`)
      })
      .catch((error) => {
        const message = error.message || 'Something went wrong'
        setStatus({ success: false })
        setErrors({ submit: message })
        setSubmitting(false)
      })
  }

  return (
    <S.Container>
      <div className='hsds-signup__container'>
        <div className='signup__container-inner'>
          <h1>Your most productive work starts right here</h1>
          <p>Streamline how you manage teams and your business with TimeGo.</p>
          <Formik
            initialValues={{ name: '', email: '', password: '', isActive: false, submit: false }}
            validationSchema={signUpValidationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              isValid
            }) => (
              <Form className="sign-up-form" onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert message={errors.submit} type="error" showIcon />
                )}
                <div className='form-control'>
                  <span>Full name <abbr title="required">*</abbr></span>
                  <S.FormInput
                    name='name'
                    type='name'
                    value={values.name}
                    onChange={handleChange}
                    error={errors.name && touched.name ? errors.name : ''}
                    placeholder='Enter your full name'
                  />
                </div>
                <div className='form-control'>
                  <span>Email <abbr title="required">*</abbr></span>
                  <S.FormInput
                    name='email'
                    type='email'
                    value={values.email}
                    onChange={handleChange}
                    error={errors.email && touched.email ? errors.email : ''}
                    placeholder='Enter your email'
                  />
                </div>
                <div className='form-control'>
                  <span>Password <abbr title="required">*</abbr></span>
                  <S.FormInput
                    name='password'
                    type='password'
                    value={values.password}
                    onChange={handleChange}
                    error={
                      errors.password && touched.password ? errors.password : ''
                    }
                    placeholder='Enter 6 or more characters'
                  />
                </div>
                <Box display='flex' width='100%' mb='16px'>
                  <Tooltip title="Please check this box if you want to proceed" placement="top">
                    <Checkbox
                      name='isActive'
                      checked={values.isActive}
                      onChange={handleChange}
                    >
                      I agree to the
                    </Checkbox>
                  </Tooltip>
                  <Link to='#'>Terms</Link>,&nbsp;
                  <Link to='#'>Privacy Policy</Link>&nbsp;and&nbsp;
                  <Link to='#'>DPA</Link>
                </Box>
                <Button
                  htmlType='submit'
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  Create my account
                </Button>
              </Form>
              )}
          </Formik>
        </div>
      </div>
      <div className='hsds-signup__illustration'>
        <img src={SignUpBg} alt='Sign Up Background' />
      </div>
    </S.Container>
  )
}

export default SignUp
