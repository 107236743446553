import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getMembers } from "../../../../store/selectors";
import { ApproveContainer } from "../style";
import { Button, Select } from "antd";
import { Box } from "@material-ui/core";
import ApproveTimesheetTable from "./ApproveTimesheetTable";

const { Option } = Select;

const ApproveTimesheet = () => {
  const [teamMember, setTeamMember] = useState<string>();
  const members = useSelector(getMembers);

  return (
    <ApproveContainer>
      <div className='approve_title'>Approve a Timesheet</div>
      <div className='approve_description'>
        Review what your team worked on and how much they spent on tasks before you approve their timesheet.
      </div>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Select
          placeholder='Team member'
          className='team_member_select'
          value={teamMember}
          onChange={(e) => setTeamMember(e)}
        >
          {
            members.map((member) => (
              <Option key={member.id} value={member.id as string} >{member.name}</Option>
            ))
          }
        </Select>
        <Button className='approve_button'>Approve Timesheet</Button>
      </Box>
      <Box mt={4}>
        <ApproveTimesheetTable
          teamMember={teamMember}
        />
      </Box>
    </ApproveContainer>
  )
}
export default ApproveTimesheet;
