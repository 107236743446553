import React, { useEffect } from "react";
import { useParams } from "react-router";
import { CloseOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { DefaultButton, PrimaryButton } from "../../../../components/Common/Button";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { IOrganization } from "../../../../shared/interfaces";
import CopyInput from "../../../../components/Common/CopyInput";
import SelectInput from "../../../../components/Common/SelectInput";
import * as S from "./styles";
import * as OrganizationApi from "../../../../apis/organization.api";

const options = [
  { value: "member", label: "Member" },
  { value: "manager", label: "Manager" }
];

interface Props {
  onNext: (value: IOrganization) => void;
  onBack: () => void;
}
const OrganizationWizardManageTeam = (props: Props) => {
  const { onNext, onBack } = props;
  const { id } = useParams();
  const [isCopied, setIsCopied] = React.useState(false);
  const [members, setMembers] = React.useState<Array<{ email: string; role: "manager" | "member" }>>([]);

  useEffect(() => {
    OrganizationApi.getById(id).then((res) => {
      if (res.members) {
        setMembers(JSON.parse(res.members));
      }
    });
  }, []);

  const handleCopy = (inputValue: string) => {
    navigator.clipboard
      .writeText(inputValue)
      .then(() => {
        setIsCopied(true);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  useEffect(() => {
    let timeoutId: number;

    if (isCopied) {
      timeoutId = setTimeout(() => {
        setIsCopied(false);
      }, 300); // 3 seconds
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isCopied]);

  const handleChangeMember = (id: number, value: string, field: "role" | "email") => {
    const newMembers = members.map((member, index) => (index === id ? { ...member, [field]: value } : member));
    setMembers(newMembers);
  };

  const handleAddMember = () => {
    setMembers([...members, { email: "", role: "member" }]);
  };

  const handleRemoveMember = (id: number) => {
    setMembers(members.filter((member, index) => index !== id));
  };

  return (
    <S.Container>
      <h1 className="title">Manage your team remotely</h1>
      <div className="sub-title">
        Seamlessly connect TimeGo with your favorite tools and platforms to streamline processes, boost productivity,
        and achieve greater efficiency.
      </div>
      <div className="field integrations" style={{ marginBottom: 30 }}>
        <div className="subtitle">Send an invite link</div>
        <CopyInput
          value="https://app.hubstaff.com/organizations/invite/NL4KP6AKeKgc5vYtNbc6WA?ab=customer_retention"
          onCopy={handleCopy}
          isCopied={isCopied}
        />
      </div>

      <div className="field integrations">
        <div className="subtitle">Invite members via email</div>
        <div className="sub-title">Note: You can add members later on</div>
        {members.map((member, index) => (
          <Space key={index} style={{ marginBottom: 24 }}>
            <SelectInput
              inputValue={member.email}
              selectValue={member.role}
              onSelectChange={(value) => handleChangeMember(index, value, "role")}
              onChange={(e) => handleChangeMember(index, e.target.value, "email")}
              options={options}
            />
            <S.CloseButton onClick={() => handleRemoveMember(index)}>
              <CloseOutlined />
            </S.CloseButton>
          </Space>
        ))}
        <S.AddMemberButton onClick={handleAddMember}>+ Add Another member</S.AddMemberButton>
      </div>
      <div className="buttons">
        <DefaultButton onClick={onBack}>
          <ArrowBack />
          Back
        </DefaultButton>
        <PrimaryButton
          onClick={() => onNext({
            members: JSON.stringify(members),
          })}
        >
          Next
          <ArrowForward />
        </PrimaryButton>
      </div>
    </S.Container>
  );
};

export default OrganizationWizardManageTeam;
