import Http from "./http";

export const uploadAvatar = (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  return Http.post('/file/upload', formData);
};

export const updateAvatar = async (id: string, file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  return Http.patch(`/file/upload/${id}`, formData);
};

export const deleteAvatar = async (id: string) => {
  return Http.delete(`/file/${id}`);
};
