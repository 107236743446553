import { Button as AButton } from "antd";
import styled, { css } from "styled-components";

export const Button = styled(AButton)`
  width: 100%;
  background-color: ${(props) => props.theme.colors.blue} !important;
  color: ${(props) => props.theme.colors.white} !important;
  height: 56px !important;
  padding: 10px 20px !important;
  border-radius: 7px !important;
  transition: all ease 0.3s;
  border: none !important;

  span {
    font: 16px "Proxima Nova Bold";
    font-weight: 600;
    line-height: 1.5;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const OutlinedButton = styled(AButton)`
  width: 100%;
  background-color: transparent !important;
  color: ${(props) => props.theme.colors.blue} !important;
  height: 56px !important;
  padding: 10px 20px !important;
  border-radius: 7px !important;
  border: 2px solid ${(props) => props.theme.colors.blue} !important;
  transition: all ease 0.3s;

  span {
    font: 16px "Proxima Nova Bold";
    font-weight: 600;
    line-height: 1.5;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const DefaultButton = styled(AButton)`
  margin-bottom: 0;
  font-weight: 600 !important;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  font-size: 14px !important;
  line-height: 1.45 !important;
  border-radius: 6px !important;
  user-select: none;
  color: #3e4956;
  background-color: #fff;
  border: 1px solid #e4e9ef;
  font-family: Roboto, serif;
  min-width: 84px;
  height: inherit !important;
  padding: 9px 20px !important;
  display: flex !important;
  align-items: center;

  &:hover {
    border-color: #c4cfdc !important;
    color: #3e4956 !important;
  }

  &:focus {
    border-color: #94a8c0;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
`;

export const PrimaryButton = styled(AButton)`
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: #2aa7ff !important;
  border-color: #2aa7ff !important;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-image: none;
  padding: 9px 20px !important;
  font-size: 14px !important;
  line-height: 1.45 !important;
  border-radius: 6px !important;
  height: inherit !important;
  color: white !important;
  &:hover {
    background-color: #1ba1ff;
    border-color: #1ba1ff;
    color: #fff;
  }

  &:active {
    border-color: #94a8c0;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
`;

export const OptionalButton = styled(AButton)<{ ghost?: boolean }>`
  width: 130px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background-color: transparent !important;
  color: ${(props) => props.theme.colors.gray} !important;
  height: 42px !important;
  padding: 4px 20px !important;
  border-radius: 7px !important;
  border: 1px solid ${(props) => props.theme.colors.gray100} !important;
  transition: all ease 0.3s;

  img {
    width: 14px;
    height: 14px;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
  }

  ${(props) =>
    props.ghost &&
    css`
      border-color: #40a9ff !important;
      color: #40a9ff !important;
      box-shadow: rgba(0, 123, 255, 0.25) 0 0 0 2px !important;
    `}
`;
