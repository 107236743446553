import axios, { AxiosRequestConfig, Method } from 'axios'

import { history } from '../store'
import { ACCESS_TOKEN_KEY, API_SERVER, EXPIRED_TIME, REFRESH_TOKEN_KEY } from '../constants'
import { Modal } from "antd";

const http = axios.create({ baseURL: `${API_SERVER}/api` })

const isTokenExpired = (expiredTime: number) => {
  const currentTime = Date.now();
  return currentTime > expiredTime;
};

const request = async (method: Method, url: string, options: AxiosRequestConfig) => {
  const expiredTime = Number(localStorage.getItem(EXPIRED_TIME)) || 0;
  const isExpired = isTokenExpired(expiredTime);

  const getToken = () => {
    if (!isExpired) {
      return localStorage.getItem(ACCESS_TOKEN_KEY);
    } else {
      return localStorage.getItem(REFRESH_TOKEN_KEY);
    }
  };

  try {
    const response = await http
      .request({
        ...options,
        method,
        url,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${getToken()}`,
        }
      });
    return response.data;
  } catch (err) {
    const error = err.response;
    if (error?.status === 401) {
      history.push('/');
    }

    const data = error?.data;
    if (!data) {
      history.push('/');
    }

    if (data.message === 'jwt expired') {
      history.push('/');
      Modal.error({title: 'Token has been expired! You have to sign in again.'});
    }

    // eslint-disable-next-line no-throw-literal
    throw {
      ...data,
      message: data?.message || 'Network Error!'
    };
  }
}

const Http = {
  get(url: string, params: AxiosRequestConfig["params"] = {}, headers: AxiosRequestConfig["headers"] = {}) {
    return request('GET', url, { params, headers })
  },
  post(url: string, body: AxiosRequestConfig["data"] = {}, headers: AxiosRequestConfig["headers"] = {}) {
    return request('POST', url, { data: body, headers })
  },
  put(url: string, body: AxiosRequestConfig["data"] = {}, headers: AxiosRequestConfig["headers"] = {}) {
    return request('PUT', url, { data: body, headers })
  },
  patch(url: string, body: AxiosRequestConfig["data"] = {}, headers: AxiosRequestConfig["headers"] = {}) {
    return request('PATCH', url, { data: body, headers })
  },
  delete(url: string, body: AxiosRequestConfig["data"] = {}, headers: AxiosRequestConfig["headers"] = {}) {
    return request('DELETE', url, { data: body, headers })
  }
}

export default Http
