import React from 'react';
import classNames from "classnames";
import Select, { CSSObjectWithLabel, SingleValue } from "react-select";
import { StylesConfig } from "react-select/dist/declarations/src/styles";
import { SelectComponentsConfig } from "react-select/dist/declarations/src/components";
import { GroupBase } from "react-select/dist/declarations/src/types";
import { ReactElement } from "react";
import { Box } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import * as S from './style';

export interface SelectOptionsProp {
  value: any;
  label: string | ReactElement;
  [key: string]: any;
}

// TODO: add types for control

export interface SelectProps {
  options: SelectOptionsProp[];
  defaultValue?: SelectOptionsProp | SelectOptionsProp[];
  components?: SelectComponentsConfig<SelectOptionsProp, false, GroupBase<SelectOptionsProp>>;
  value?: SelectOptionsProp | null;
  classNamePrefix?: string;
  name: string;
  className?: string;
  label?: string;
  control?: any;
  placeholder?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  styles?: StylesConfig<SelectOptionsProp, false>;
  onChange?: (newValue: SingleValue<SelectOptionsProp>) => void;
  noOptionsMessage?: () => string;
  onBlur?: () => void;
  onClear?: () => void;
}

const CustomSelect = (props: SelectProps) => {
  const className = classNames(props.className, "select");
  const componentProps = {
    ...props,
    classNamePrefix: "select",
    className,
    isSearchable: props.isSearchable ?? false
  };

  return(
    <S.SelectWrapper>
      {!!props.label && <label className="control-label">{props.label}</label>}
      <Box position="relative">
        <Select
          {...componentProps}
          styles={{
            option: (styles: CSSObjectWithLabel, { isDisabled }) => ({
              ...styles,
              "whiteSpace": "nowrap",
              "color": isDisabled ? "#ccc !important" : "currentColor",
              "cursor": isDisabled ? "not-allowed" : "default",
              ":hover": {
                background: isDisabled ? "transparent !important" : "#DEEBFF"
              }
            }),
            ...componentProps.styles
          }}
        />
        {props.value && <Clear className="clear-icon" onClick={props.onClear} />}
      </Box>
    </S.SelectWrapper>
  )
};

export default CustomSelect;
