import React, { useEffect } from 'react';
import { Modal } from "antd";
import { useHistory, useParams } from "react-router";
import * as AuthApi from '../../../apis/auth.api';
import { ACCESS_TOKEN_KEY } from "../../../constants";

const VerifyEmail = () => {
  const history = useHistory();
  const param = useParams() as { token: string };

  useEffect(() => {
    AuthApi.verifyEmail(param.token)
      .then((res) => {
        localStorage.setItem(ACCESS_TOKEN_KEY, res.accessToken);
        history.push('/welcome');
        Modal.success({ title: res.message });
      })
      .catch((err) => Modal.error({ title: err.message }));
  }, []);

  return (
    <></>
  )
}
export default VerifyEmail;
