import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { setProjects } from "../../../../store/actions/project.actions";
import { setMembers } from "../../../../store/actions/member.action";
import { Box } from '@material-ui/core'
import { ArrowBackIos, Settings } from '@material-ui/icons'
import { BackButton, IconButton, ItemWrapper, SubPanel, SettingButton, FilterGroup } from '../styles'
import { AppPage } from '../../../../components/Dashboard/CommonStyled'
import DatePicker from '../../../../components/Dashboard/DatePicker'
import Button from '../../../../components/Dashboard/Button'
import FilterSideMenu from '../FilterSideMenu'
import ClockIcon from '../../../../assets/images/dashboard/clock-icon.png'
import SendIcon from '../../../../assets/images/dashboard/send-icon.png'
import ExportIcon from '../../../../assets/images/dashboard/export-icon.png'
import NoDataImg from '../../../../assets/images/dashboard/nothing.png'
import * as UserApi from "../../../../apis/user.api";
import * as ProjectApi from "../../../../apis/project.api";

const PaymentsReport = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    UserApi.getAll().then((res) =>　dispatch(setMembers(res)));
    ProjectApi.getAll('').then((res) => dispatch(setProjects(res)));
  }, []);

  return (
    <AppPage>
      <Box mb={3}>
        <BackButton to='#'><ArrowBackIos /> Reports</BackButton>
      </Box>
      <Box mb={2} display='flex' justifyContent='space-between' alignItems='flex-end' flexWrap='wrap'>
        <Box mr={2}><h1>Payments report</h1></Box>
        <IconButton>
          <img src={ClockIcon} alt='Clock' />
          Scheduled reports
        </IconButton>
      </Box>
      <Box display='flex' justifyContent='flex-end'>
        <FilterGroup>
          <DatePicker
            date={date}
            setDate={setDate}
          />
          <Box ml={2}>
            <Button
              className='primary-contained'
              onClick={() => setFilterOpen(true)}
            >
              Filters
            </Button>
          </Box>
        </FilterGroup>
      </Box>
      <ItemWrapper gridTemplateColumns='1fr 1fr'>
        <div className='item'>
          <span className='label'>Payments</span>
          <h1>-</h1>
        </div>
        <div className='item'>
          <span className='label'>Amount</span>
          <h1>-</h1>
        </div>
      </ItemWrapper>
      <SubPanel>
        <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
          <Box display='flex' alignItems='flex-end' className='title'>
            <h2>Estey-Hoover, Inc</h2>
            <span>America - New York</span>
          </Box>
          <Box display='flex' className='actions'>
            <IconButton>
              <img src={SendIcon} alt='Send' />
              Send
            </IconButton>
            <IconButton>
              <img src={ClockIcon} alt='Schedule' />
              Schedule
            </IconButton>
            <IconButton>
              <img src={ExportIcon} alt='Export' />
              Export
            </IconButton>
          </Box>
        </Box>
        <Box display='flex' justifyContent='flex-end' mt={2}>
          <SettingButton>
            <Settings />
          </SettingButton>
        </Box>
        <div className='no-data-container'>
          <img src={NoDataImg} alt='Nothing' />
          <h3>Nothing to report</h3>
          <p>Expecting to see something? Try adjusting the report.</p>
        </div>
      </SubPanel>
      <FilterSideMenu
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
      />
    </AppPage>
  )
}

export default PaymentsReport;
