import React, { useEffect, useState } from "react";
import { stringAvatar } from "../../../pages/Dashboard/Financials/Expenses/helper";
import { XCircle } from "react-feather";
import { Avatar } from "antd";
import { IMemberInterface } from "../../Dashboard/Team/AddTeamModal";
import SearchInput from "../SearchInput";
import clsx from "clsx";
import EmptyBoxImage from '../../../assets/images/empty_box.svg';
import * as S from "./style";

export interface IMember {
  name: string;
  email: string;
  checked?: boolean;
}

const MemberSelector = (props: any) => {
  const { label, description, members, data } = props;
  const [isShowOnlySelected, setIsShowOnlySelected] = useState<boolean>(false);
  const [modifiedMembers, setModifiedMembers] = useState<IMemberInterface[]>(members);
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  useEffect(() => {
    props.setFieldValue(`${label.toLowerCase()}`, modifiedMembers.filter((member) => member.checked).map((item) => item.id));
  }, [modifiedMembers]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (data !== null) {
      setModifiedMembers(
        members.map((member: IMemberInterface) => (
          props.values[`${label.toLowerCase()}`].find((item: IMemberInterface) => member.id === item.id) ? (
            {
              ...member,
              checked: true,
            }
          ) : (
            {
              ...member,
            }
          )
        ))
      );
    } else {
      setModifiedMembers(members);
    }
  }, [members, data]);

  const handleSelectMember = (e: React.MouseEvent<HTMLDivElement>, member: IMemberInterface) => {
    e.preventDefault();
    e.stopPropagation();
    const newMembers = modifiedMembers.map(item =>
      item.email === member.email
        ? {...item, checked: !item.checked}
        : item
    );
    setModifiedMembers(newMembers);
  };

  const handleSelectAll = () => {
    setModifiedMembers(modifiedMembers.map(item => ({...item, checked: true})));
  }

  const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setModifiedMembers(modifiedMembers.map(item => ({...item, checked: false})));
  }

  const isAllSelected = modifiedMembers.every(item => item.checked);

  return (
    <S.SelectorWrapper>
      <div className="app-members-selector-title">
        <label className="control-label">{label}</label>
        <div className="label-secondary">
          <span>{description}</span>
        </div>
      </div>

      <S.SelectSelection>
        {modifiedMembers.filter(member => member.checked).length > 0 ?
          <div onClick={handleClick} className="app-select" >
            <div data-select2-ignore="" className="select-container">
              <button onClick={(e) => handleClear(e)} className="clear-all-trigger">
                <XCircle className="search-clear" />
              </button>
              <div className="selection">
                <div className="selection-counter">
                  {modifiedMembers.filter(member => member.checked).length} &nbsp;
                  {modifiedMembers.filter(member => member.checked).length === 1 ? 'member' : 'members'}
                </div>
              </div>
            </div>
          </div>
          :
          <div onClick={handleClick} className="app-selector">Select members</div>
        }
      </S.SelectSelection>
      <S.PopoverContainer
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        style={{ borderRadius: 8 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
        disableRestoreFocus
      >
        <S.PopoverContent>
          <div className="lazy-select-content-header d-flex flex-column pt-15">
            <SearchInput
              onChange={(e) => setSearchKeyword(e.target.value)}
              value={searchKeyword}
              placeholder="Search members"
              onClear={() => setSearchKeyword('')}
            />
          </div>
          <div className="lazy-select-content-results">
            <div className="lazy-select-content-results-list pb-30">
              {modifiedMembers.filter(member => member.name.includes(searchKeyword)).length > 0 && (
                <div
                  className={clsx("lazy-select-content-results-list-item select-all-trigger", isAllSelected && "disabled")}
                  onClick={handleSelectAll}
                >
                  <label className="custom-checkbox-wrapper text-blue">
                    <strong>Select all</strong>
                  </label>
                </div>
              )}
              <div role="group" className="" style={{ padding: 0 }}>
                <div role="listitem" className="">
                  {modifiedMembers.filter(member => member.name.includes(searchKeyword)).length > 0 ?
                    modifiedMembers.filter(member => member.name.includes(searchKeyword))
                      .map(member =>
                        <div
                          key={member.id}
                          className={clsx(
                            "lazy-select-content-results-list-item",
                            member.checked && 'selected',
                            isShowOnlySelected && !member.checked && 'hidden')}
                          onClick={(e) => handleSelectMember(e, member)}
                        >
                          <label className="custom-checkbox-wrapper">
                            <input
                              type="checkbox"
                              checked={member.checked}
                              onChange={() => setModifiedMembers((prevState) =>
                                prevState.map((item) =>
                                  item.email === member.email ? { ...item, checked: !item.checked } : item))}
                            />
                            <span className="custom-checkbox-box" />
                            <span className="check-box-label">
                              <div
                                className="lazy-select-content-results-list-item-content d-flex align-items-center w-full pr-10">
                                {
                                  member.image ? (
                                    <img
                                      src={`${process.env.REACT_APP_API_SERVER}/${member.image.path}`}
                                      height="25"
                                      width="25"
                                      className="avatar avatar-speck"
                                      alt="avatar"
                                    />
                                  ) : (
                                    <div style={{ marginRight: 5 }}>
                                      <Avatar
                                        size={25}
                                        {...stringAvatar(member.name)}
                                      />
                                    </div>
                                  )
                                }
                                <p className="lazy-select-content-results-list-item-content-name">
                                  {member.name}
                                </p>
                              </div>
                            </span>
                          </label>
                        </div>
                      ):
                    <S.EmptyBox>
                      <img src={EmptyBoxImage} alt='empty' />
                      No members found
                    </S.EmptyBox>
                  }
                </div>
              </div>
              <div style={{ width: "100%", height: 0 }} />
            </div>
          </div>
          <div className="lazy-select-content-footer d-flex align-items-center px-15 py-10">
            <label
              onClick={(e) => {
                e.preventDefault();
                // tslint:disable-next-line:no-unused-expression
                modifiedMembers.some(item => item.checked) && setIsShowOnlySelected(!isShowOnlySelected);
              }}
              className="toggle-switch-wrapper text-muted"
            >
              <input
                type="checkbox"
                autoComplete="off"
                disabled={!modifiedMembers.some(item => item.checked)}
                checked={isShowOnlySelected}
                onChange={() => setIsShowOnlySelected(!isShowOnlySelected)}
              />
              <div className="toggle-switch" />
              <span className="toggle-switch-label">
                  Show only selected
              </span>
            </label>
          </div>
        </S.PopoverContent>
      </S.PopoverContainer>

    </S.SelectorWrapper>
  );
};

export default MemberSelector;
