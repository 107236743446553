import styled from 'styled-components'
import { AppPage } from '../../../components/Dashboard/CommonStyled'

// @ts-ignore
const Container = styled(AppPage)`
  max-width: 1400px;
  margin: 0 auto;

  .divider {
    height: 1px;
    width: 100%;
    background-color: ${props => props.theme.colors.gray100};
    margin: 15px 0;
  }

  button:disabled {
    opacity: 0.5;
  }

  ${props => props.theme.breakpoints.down('xs')} {
    button {
      width: 100%;
    }
  }
`

export { Container }
