import React from "react";
import { WorkTimeContainer } from "../style";
import { ExclamationCircleFilled } from "@ant-design/icons";
import HorizontalDiagram from "./component/HorizontalDiagram";
import ProgressBar from "./component/ProgressBar";

const data = {
  core_work: 34,
  non_core_work: 56,
  unproductive: 10
}

const WorkTimeDiagram = () => {
  return (
    <WorkTimeContainer>
      <div style={{ padding: '0.5rem 0.6rem' }}>
        <div className='work_time_title'>
          <span>WORK TIME CLASSIFICATION</span>
          <ExclamationCircleFilled />
        </div>
        <div className='work_time_diagram_container'>
          <p style={{ fontWeight: 600, fontSize: 20, color: 'black' }}>{data.core_work}%</p>
          <p style={{ fontSize: 13 }}>Core work</p>
          <div style={{ marginTop: '1.1rem' }}>
            <HorizontalDiagram data={data} />
            <div style={{ marginTop: '2rem' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className='list_circle' style={{ backgroundColor: '#2aa7ff' }} />
                <span className='list_menu'>{data.core_work}% Core work</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className='list_circle' style={{ backgroundColor: '#6b7280' }} />
                <span className='list_menu'>{data.non_core_work}% Non-core work</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className='list_circle' style={{ backgroundColor: '#e7721a' }} />
                <span className='list_menu'>{data.unproductive}% Unproductive</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: '0.5rem 0.6rem', borderTop: '1.5px solid #d1d4d5' }}>
        <ProgressBar average={data.core_work} />
      </div>
    </WorkTimeContainer>
  )
}
export default WorkTimeDiagram;
