import React, { useMemo } from 'react'
import { useSelector } from "react-redux";
import { getAccount } from "../../../store/selectors";
import { stringAvatar } from "../Financials/Expenses/helper";
import { Box } from '@material-ui/core'
import { Avatar, Table } from "antd";
import { TableContainer } from "../../../components/Dashboard/CommonStyled";
import { EmojiObjectsOutlined } from '@material-ui/icons'
import styled from 'styled-components'
import CheckIcon from "@material-ui/icons/Check";
import * as S from './styles'
import moment from "moment-timezone";

const Container = styled(Box)`
  .hint-text {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: ${props => props.theme.palette.dashboard.textLight};

    svg {
      width: 15px;
      height: 15px;
    }
  }
`

const Billing = () => {
  const account = useSelector(getAccount);

  const columns = [
    {
      title: 'Organization',
      dataIndex: 'name',
      render: (name: string) => (
        <>
          <Avatar
            {...stringAvatar(name)}
          />
          <span style={{ marginLeft: 5, fontWeight: 500 }}>{name}</span>
        </>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: boolean) => (
        <div>
          {
            status ? <CheckIcon color='primary' /> : <></>
          }
        </div>
      )
    },
    {
      title: 'Created On',
      dataIndex: 'createdAt',
      render: (createdAt: Date) => (
        <div>{moment(createdAt).format('DD/MM/YYYY')}</div>
      )
    },
    {
      title: 'Plan',
      dataIndex: 'plan',
      render: (plan: string) => (
        <div>{plan}</div>
      )
    }
  ];

  const tableData = useMemo(() => {
    if (account?.organization) {
      return [{
        ...account.organization,
        key: account.organization.id,
        name: account.organization.name,
        status: account.organization.amount_owed,
      }]
    }
  }, [account]);

  return (
    <Container pt={3}>
      <Box>
        <span className='hint-text'><EmojiObjectsOutlined /> Each organization is billed separately</span>
      </Box>
      <>
        {
          account?.organization ? (
            <Box mt={3}>
              <TableContainer>
                <Table
                  columns={columns}
                  dataSource={tableData}
                  pagination={false}
                  scroll={{x: 'max-content'}}
                />
              </TableContainer>
            </Box>
          ) : (
            <Box mt={3}>
              <S.TableHeader>
                <Box width='30%'>Organization</Box>
                <Box width='20%'>Status</Box>
                <Box width='30%'>Created on</Box>
                <Box width='20%'>Plan</Box>
              </S.TableHeader>
              <Box mt={2} ml={2}>
                <p>You do not own any organizations. Only owenrs can manage the billing for an organization.</p>
              </Box>
            </Box>
          )
        }
      </>
    </Container>
  )
}

export default Billing;
