import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { getAccount } from "../../../store/selectors";
import { FaFileUpload } from "react-icons/all";
import { setFetchMe } from "../../../store/actions/member.action";
import { Box, Typography } from "@material-ui/core";
import { Tooltip } from "antd";
import { Delete } from "@material-ui/icons";
import styled from "styled-components";
import * as FileApi from "../../../apis/file.api";

export const AvatarWrapper = styled(Box)(
  () => `
    position: relative;
    border: none;
    background: transparent;
    height: 200px;
    width: 200px;
    padding: 0px;
    .hover-container.MuiBox-root {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 34, 78, 0.6);
      opacity: 0;
    }
    &:hover {
      opacity: 1;
    }

    .user-avatar {
      img: {
        width: 200px;
        height: 200px;
      }
    }
`
);

export const HoverContainer = styled(Box)(
  () => `
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 34, 78, 0.6);
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  &:hover {
    opacity: 1
  }
`
);

export const UploadButton = styled(Box)(
  () => `
    color: rgb(255, 255, 255);
    background-color: rgb(27, 104, 179);
    border: 1px solid rgb(27, 104, 179);
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background-color: rgb(27, 104, 179);
      opacity: 0.8;
    }

`
);

export interface UploadedFileType {
  id: string;
  path: string;
  size: string;
  name: string;
  type: string;
}

const AvatarBox = () => {
  const dispatch = useDispatch();
  const [uploadedFile, setUploadedFile] = useState<UploadedFileType | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const account = useSelector(getAccount);

  useEffect(() => {
    if (account) {
      setUploadedFile(account.image);
    }
  }, [account]);

  const onDropFile = (acceptedFiles: any) =>  {
    if (!acceptedFiles.length) {
      return;
    }

    setImageFile(acceptedFiles[0]);
    if (uploadedFile?.id) {
      FileApi.updateAvatar(uploadedFile.id, acceptedFiles[0]).then((res) => setUploadedFile(res));
    } else {
      FileApi.uploadAvatar(acceptedFiles[0]).then((res) => setUploadedFile(res));
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    noClick: true,
    accept: {
      'image/*': ['.jpg', '.png']
    },
    maxSize: 315621376,
    onDrop: onDropFile
  });

  const fileInputRef = React.createRef<HTMLInputElement>();

  const avatarUrl = useMemo(() => {
    if (imageFile) {
      const blob = new Blob([imageFile], { type: imageFile.type });
      return URL.createObjectURL(blob);
    }
    if (uploadedFile) {
      return `${process.env.REACT_APP_API_SERVER}/${uploadedFile.path}`;
    }
  }, [uploadedFile, imageFile]);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleDeleteAvatar = () => {
    const id = uploadedFile?.id;
    FileApi.deleteAvatar(id as string).then((res) => dispatch(setFetchMe(res)));
    setImageFile(null);
  };

  return (
   <AvatarWrapper { ...getRootProps() }>
     <input
       { ...getInputProps() }
       ref={fileInputRef}
       type="file"
       style={{ display: 'none' }}
     />
     <Box
       display="flex"
       position="relative"
       className="user-avatar"
     >
       <Box
         bgcolor="rgb(255, 128, 0)"
         position="absolute"
         width="200px"
         height="200px"
         display="flex"
         alignItems="center"
         justifyContent="center"
       >
         <Typography variant="h1">
           { account?.name ? account?.name.charAt(0).toUpperCase() : '' }
         </Typography>
       </Box>
       <Box position="absolute">
         {(uploadedFile || imageFile) && (
           <img
             src={avatarUrl}
             style={{ width: '200px', height: '200px' }}
             alt='user_image'
           />
         )}
       </Box>
     </Box>
     <HoverContainer>
       <Tooltip
         title={
           <Box>
             <Typography>
               Accepted Files: GIF, JPEG, PNG
             </Typography>
             <Typography>Max file size: 3 MB</Typography>
           </Box>
         }
       >
         <UploadButton onClick={handleClick}>
           <FaFileUpload />
         </UploadButton>
       </Tooltip>

       {uploadedFile && (
         <UploadButton onClick={handleDeleteAvatar}>
           <Delete />
         </UploadButton>
       )}
     </HoverContainer>
   </AvatarWrapper>
 )
}

export default AvatarBox;
