import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Box } from '@material-ui/core'
import { AppPage } from '../../../../components/Dashboard/CommonStyled'
import { levelTypeOption, sourceTypeOption, timeTypeOption, timeZones } from "../Apps";
import { setMembers } from "../../../../store/actions/member.action";
import { setProjects } from "../../../../store/actions/project.actions";
import { getMembers, getProjects } from "../../../../store/selectors";
import DatePicker from '../../../../components/Dashboard/DatePicker'
import Select, { Option } from '../../../../components/Dashboard/Select'
import Button from '../../../../components/Dashboard/Button'
import styled from 'styled-components'
import LookingImg from '../../../../assets/images/dashboard/looking.svg'
import * as S from '../Apps/styles'
import * as ProjectApi from "../../../../apis/project.api";
import * as UserApi from "../../../../apis/user.api";

const StyledSelect = styled(Select)`
  min-width: 140px;
`

const Urls = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const members = useSelector(getMembers);
  const projects = useSelector(getProjects);

  useEffect(() => {
    UserApi.getAll().then((res) =>　dispatch(setMembers(res)));
    ProjectApi.getAll('').then((res) => dispatch(setProjects(res)));
  }, []);

  return (
    <AppPage>
      <Box mb={2}>
        <h1>URL activity</h1>
      </Box>
      <S.FilterGroup mt={2}>
        <Box className='left'>
          <DatePicker
            date={date}
            setDate={setDate}
          />
          <Box ml='10px'>
            <Button className='outlined' onClick={() => setDate(new Date())}>Today</Button>
          </Box>
          <Box ml='10px'>
            <Button className='outlined'>All notes</Button>
          </Box>
        </Box>
        <Box className='right'>
          <Box display='flex'>
            <Box className='group' display='flex' mr='20px'>
              <Box>
                <StyledSelect placeholder='All types' label='Time Type'>
                  {
                    timeTypeOption.map((item) => (
                      <Option key={item.value} value={item.value}>{item.label}</Option>
                    ))
                  }
                </StyledSelect>
              </Box>
              <Box ml='20px'>
                <StyledSelect placeholder='All sources' label='Source'>
                  {
                    sourceTypeOption.map((item) => (
                      <Option key={item.value} value={item.value}>{item.label}</Option>
                    ))
                  }
                </StyledSelect>
              </Box>
            </Box>
            <Box className='group' display='flex'>
              <Box>
                <StyledSelect placeholder='All levels' label='Activity Level'>
                  {
                    levelTypeOption.map((item) => (
                      <Option key={item.value} value={item.value}>{item.label}</Option>
                    ))
                  }
                </StyledSelect>
              </Box>
              <Box ml='20px'>
                <StyledSelect placeholder='Select a timezone' label='Timezone'>
                  {
                    timeZones.map((item) => (
                      <Option key={item.value} value={item.value}>{item.label}</Option>
                    ))
                  }
                </StyledSelect>
              </Box>
            </Box>
          </Box>
          <Box className='group' display='flex' justifyContent='flex-end' mt={2}>
            <Box>
              <StyledSelect placeholder='Select a member' label='Member'>
                {
                  members.map((member) => (
                    <Option key={member.id} value={member.id as string}>{member.name}</Option>
                  ))
                }
              </StyledSelect>
            </Box>
            <Box ml={2}>
              <StyledSelect placeholder='All projects' label='Project'>
                {
                  projects.map((project) => (
                    <Option key={project.id} value={project.id as string}>{project.name}</Option>
                  ))
                }
              </StyledSelect>
            </Box>
          </Box>
        </Box>
      </S.FilterGroup>
      <S.TableHeader>
        <Box width='30%'>Project</Box>
        <Box width='60%'>Site</Box>
        <Box>Time spent (hrs)</Box>
      </S.TableHeader>
      <S.NoDataContainer>
        <img src={LookingImg} alt='No Activity' />
        <p>No data for this date range</p>
      </S.NoDataContainer>
    </AppPage>
  )
}

export default Urls