import React, { useMemo, useState } from "react";
import { useHistory } from "react-router";
import { ForgotContainer } from "./style";
import { Form, Formik, FormikProps } from "formik";
import { Alert } from "antd";
import { Button } from "../../../components/Common/Button";
import { IForgotPassword } from "../../../shared/interfaces";
import Input from "../../../components/Common/Input";
import * as Yup from 'yup'
import * as AuthApi from "../../../apis/auth.api";

const ForgotPassword = () => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const initialValues = useMemo(() => {
    return {
      email: ''
    }
  }, []);

  const emailValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email is not valid')
      .required('Email is required')
  });

  const sendEmail = (value: IForgotPassword) => {
    const { email } = value;
    setErrorMessage('');

    AuthApi.forgotPassword(email)
      .then((res) => {
        if (res) {
          history.push(`/reset-password/${email}`);
        }
      })
      .catch((err) => setErrorMessage(err.message));
  };

  return (
    <ForgotContainer>
      <div className='password_container'>
        <p className='title'>Forgot password?</p>
        <p className='description'>Enter the email address you used to create an account</p>
        <Formik
          initialValues={initialValues}
          validationSchema={emailValidationSchema}
          onSubmit={sendEmail}
        >
          {(props: FormikProps<IForgotPassword>) => (
            <Form onSubmit={props.handleSubmit}>
              <div className='alert_message'>
                {
                  !!errorMessage && (
                    <Alert message={errorMessage} type='error' showIcon />
                  )
                }
              </div>
              <div>
                <span>Email</span>
                <Input
                  name='email'
                  type='email'
                  placeholder='example@email.com'
                  value={props.values.email}
                  onChange={props.handleChange}
                  error={props.errors.email && props.touched.email ? props.errors.email : ''}
                />
              </div>
              <div style={{ display: 'flex' }}>
                <Button
                  htmlType='submit'
                  disabled={!props.isValid}
                  className='reset-button'
                >
                  Reset Password
                </Button>
              </div>
              <div className='foot-word'>
                Already have account?&nbsp;
                <span onClick={() => history.push('/auth/sign-in')}>Login</span>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ForgotContainer>
  )
}
export default ForgotPassword;
