import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { getAccount } from "../../../../store/selectors";
import { Subscribe } from "../style";
import { CheckCircle } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";

export interface ISubscribeOptionInterface {
  id: string,
  title: string,
  description: string,
  state?: boolean,
}

interface IProps {
  options: ISubscribeOptionInterface[],
  setOptions: (value: ISubscribeOptionInterface[]) => void;
}

export const subscribeOptions = [
  {
    id: 'time_activity',
    title: 'Time Activity',
    description: 'See team members`s time worked, activity levels, and amounts earned per project/work orders.',
  },
  {
    id: 'amount_owed',
    title: 'Amounts owed',
    description: 'See how much the hourly paid team members are currently owed',
  },
  {
    id: 'daily_total',
    title: 'Daily totals',
    description: 'See team members`time worked, activity levels, and amount earned per days',
  }
];

const SubscribeToReport: FC<IProps> = ({ options, setOptions }) => {
  const account = useSelector(getAccount);

  useEffect(() => {
    if (account) {
      setOptions(options.map((option) => (
        {
          ...option,
          state: account.organization[option.id]
        }
      )));
    }
  }, [account]);

  const handleSubscribeReport = (value: ISubscribeOptionInterface) => {
    setOptions(options.map((item) => {
      if (item.id === value.id) {
        return {
          ...item,
          state: !value.state
        }
      } else
        return item;
    }));
  };

  return (
    <Subscribe>
      <div className='sub_title'>Subscribe to a Report</div>
      <div className='sub_description'>
        Pick a report that you`d valuable to receive on a regular basis and we`ll make sure it lands in your inbox
        with up-to-date data from your team.
      </div>
      <div className='card_container'>
        {
          options.map((option) => (
            <div key={option.id} className='sub_card'>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <p className='card_title'>{option.title}</p>
                {
                  option.state && (
                    <CheckCircle style={{ color: '#31c48d' }} />
                  )
                }
              </div>
              <p className='card_description'>{option.description}</p>
              {
                option.state ? (
                  <div
                    className='card_subscribe_button'
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: 'white',
                      color: '#77e9be',
                      borderColor: '#77e9be'}}
                    onClick={() => handleSubscribeReport(option)}

                  >
                    Subscribe
                    <CheckIcon fontSize='small' style={{ color: '#77e9be', marginLeft: '0.5rem' }}/>
                  </div>
                ) : (
                  <div
                    className='card_subscribe_button'
                    onClick={() => handleSubscribeReport(option)}
                  >
                    Subscribe
                  </div>
                )
              }
            </div>
          ))
        }
      </div>
    </Subscribe>
  )
}
export default SubscribeToReport;
