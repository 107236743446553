import React from "react";
import { AppUrlContainer } from "../style";
import { ExclamationCircleFilled } from "@ant-design/icons";

const diagramOptions = [
  {
    title: 'Slack',
    progress: 61,
    time: '2:00',
    style: '#5c6a7b'
  },
  {
    title: 'Figma',
    progress: 22,
    time: '0:40',
    style: '#2aa7ff'
  },
  {
    title: 'VS Code',
    progress: 8,
    time: '0:32',
    style: '#2aa7ff'
  },
  {
    title: 'facebook.com',
    progress: 2,
    time: '0:20',
    style: '#f08050'
  },
  {
    title: 'Terminal',
    progress: 2,
    time: '0:20',
    style: '#2aa7ff'
  }
];

const AppUrlDiagram = () => {
  return (
    <AppUrlContainer>
      <div className="app_url_title">
        <span>TOP APPS & URLS</span>
        <ExclamationCircleFilled />
      </div>
      <div>
        {
          diagramOptions.map((option, index) => (
            <div key={option.title + index} style={{ marginTop: '1rem' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: 12 }}>
                <div>
                  <span style={{ color: 'black', fontWeight: 600 }}>{option.progress}%</span>
                  <span style={{ marginLeft: 8 }}>{option.title}</span>
                </div>
                <span style={{ color: '#919aa6' }}>{option.time}</span>
              </div>
              <div className='progress_bar' style={{ width: `${option.progress}%`, backgroundColor: `${option.style}` }} />
            </div>
          ))
        }
      </div>
    </AppUrlContainer>
  )
}
export default AppUrlDiagram;
