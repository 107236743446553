import React from 'react'
import {
  AccessTime,
  AssessmentOutlined,
  MapOutlined,
  CalendarTodayOutlined,
  DescriptionOutlined,
  MonetizationOnOutlined,
  OpenInNewOutlined,
  GroupWorkOutlined,
  DashboardOutlined
} from '@material-ui/icons'

// Auth Components
import SignIn from '../pages/Auth/SignIn'
import SignUp from '../pages/Auth/SignUp'

// Landing Page Components
import Home from '../pages/Home'

// Dashboard Components
import Default from '../pages/Dashboard/Default'
import ViewAndEdit from '../pages/Dashboard/TimeSheets/ViewAndEdit'
import Approvals from '../pages/Dashboard/TimeSheets/Approvals'
import Screenshots from '../pages/Dashboard/Activity/Screenshots'
import Apps from '../pages/Dashboard/Activity/Apps'
import Urls from '../pages/Dashboard/Activity/Urls'
import Map from '../pages/Dashboard/Map'
import Projects from '../pages/Dashboard/ProjectManagement/Projects'
import Todos from '../pages/Dashboard/ProjectManagement/Todos'
import Calendar from '../pages/Dashboard/Schedules/Calendar'
import TimeOffRequests from '../pages/Dashboard/Schedules/TimeOffRequests'
import TimeActivity from '../pages/Dashboard/Reports/TimeActivity'
import WeeklyReport from '../pages/Dashboard/Reports/Weekly'
import AmountsOwedReport from '../pages/Dashboard/Reports/AmountsOwed'
import PaymentsReport from '../pages/Dashboard/Reports/Payments'
import AllReports from "../pages/Dashboard/Reports/All"
import Invoices from '../pages/Dashboard/Financials/Invoices'
import Expenses from '../pages/Dashboard/Financials/Expenses'
import CreateInvoice from '../pages/Dashboard/Financials/Invoices/CreateInvoice'
import Settings from '../pages/Dashboard/Settings'
import Notifications from '../pages/Dashboard/Notifications'
import Team from '../pages/Dashboard/Team'
import Welcome from "../pages/Welcome";
import OrganizationWizard from "../pages/Organization/Wizard";
import OrganizationWizardSettings from "../pages/Organization/WizardSettings";
import Account from "../pages/Dashboard/Account";
import EmailConfirmation from "../pages/Auth/EmailConfirmation";
import VerifyEmail from "../pages/Auth/EmailConfirmation/VerifyEmail";
import GettingStart from "../components/Dashboard/GettingStart";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import ResetPassword from "../pages/Auth/ResetPassword";

const authRoutes = [
  {
    id: 'Auth',
    path: '/auth',
    children: [
      {
        path: '/auth/sign-in',
        name: 'Sign In',
        component: SignIn,
        authRoute: true
      },
      {
        path: '/auth/sign-up',
        name: 'Sign Up',
        component: SignUp,
        authRoute: true
      },
    ],
    component: null,
  },
  {
    id: 'forgot_password',
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    id: 'reset_password',
    path: '/reset-password/:email',
    component: ResetPassword
  },
  {
    id: 'confirmation_email',
    path: '/confirmation_sent/:token',
    component: EmailConfirmation,
  },
  {
    id: 'verify_email',
    path: '/verify/:token',
    component: VerifyEmail,
  }
];

const landingRoutes = {
  id: 'Home',
  path: '/',
  component: Home,
  children: null
}

const welcomeRoutes = {
  id: 'Welcome',
  name: 'Welcome',
  path: '/welcome',
  component: Welcome,
  children: null
}

const organizationRoutes = {
  id: 'Organization',
  path: '/organizations',
  children: [
    {
      path: '/organizations/wizard',
      name: 'Organizations wizard',
      component: OrganizationWizard,

    },
    {
      path: '/organizations/wizard/:id',
      name: 'Organizations wizard settings',
      component: OrganizationWizardSettings,
    }
  ]
}

const dashboardsRoutes = [
  {
    id: 'getStarted',
    name: 'Get started',
    path: '/getting_started/:id',
    component: GettingStart,
    children: null,
  },
  {
    id: 'Dashboard',
    name: 'Dashboard',
    path: '/dashboard',
    icon: <DashboardOutlined />,
    component: Default,
    children: null
  },
  {
    id: 'Timesheets',
    name: 'TimeSheets',
    path: '/dashboard/timesheets',
    icon: <AccessTime />,
    component: null,
    children: [
      {
        id: 'view_edit',
        name: 'View & edit',
        path: '/dashboard/timesheets/view_edit',
        component: ViewAndEdit,
        children: null
      },
      {
        id: 'approvals',
        name: 'Approvals',
        path: '/dashboard/timesheets/approvals',
        component: Approvals,
        children: null
      }
    ]
  },
  {
    id: 'activity',
    name: 'Activity',
    path: '/dashboard/activity',
    component: null,
    icon: <AssessmentOutlined />,
    children: [
      {
        id: 'screenshots',
        name: 'Screenshots',
        path: '/dashboard/activity/screenshots',
        component: Screenshots,
        children: null
      },
      {
        id: 'apps',
        name: 'Apps',
        path: '/dashboard/activity/apps',
        component: Apps,
        children: null
      },
      {
        id: 'urls',
        name: 'Urls',
        path: '/dashboard/activity/urls',
        component: Urls,
        children: null
      }
    ]
  },
  {
    id: 'map',
    name: 'Map',
    path: '/dashboard/map',
    component: Map,
    icon: <MapOutlined />,
    children: null
  },
  {
    id: 'project_management',
    name: 'Project management',
    path: '/dashboard/project_management',
    icon: <OpenInNewOutlined />,
    component: null,
    children: [
      {
        id: 'projects',
        name: 'Projects',
        path: '/dashboard/project_management/projects',
        component: Projects,
        children: null
      },
      {
        id: 'todos',
        name: 'To-dos',
        path: '/dashboard/project_management/todos',
        component: Todos,
        children: null
      }
    ]
  },
  {
    id: 'schedules',
    name: 'Schedules',
    path: '/dashboard/schedules',
    component: null,
    icon: <CalendarTodayOutlined />,
    children: [
      {
        id: 'calendar',
        name: 'Calendar',
        path: '/dashboard/schedules/calendar',
        component: Calendar,
        children: null
      },
      {
        id: 'time_off_requests',
        name: 'Time off requests',
        path: '/dashboard/schedules/time_off_requests',
        component: TimeOffRequests,
        children: null
      }
    ]
  },
  {
    id: 'reports',
    name: 'Reports',
    path: '/dashboard/reports',
    component: null,
    icon: <DescriptionOutlined />,
    children: [
      {
        id: 'time_activity',
        name: 'Time & activity',
        path: '/dashboard/reports/time_activity',
        component: TimeActivity,
        children: null
      },
      {
        id: 'weekly',
        name: 'Weekly',
        path: '/dashboard/reports/weekly',
        component: WeeklyReport,
        children: null
      },
      {
        id: 'amounts_owed',
        name: 'Amounts owed',
        path: '/dashboard/reports/amounts_owed',
        component: AmountsOwedReport,
        children: null
      },
      {
        id: 'payments',
        name: 'Payments',
        path: '/dashboard/reports/payments',
        component: PaymentsReport,
        children: null
      },
      {
        id: 'all_reports',
        name: 'All reports',
        path: '/dashboard/reports/all_reports',
        component: AllReports,
        children: null
      }
    ]
  },
  {
    id: 'teams',
    name: 'Teams',
    path: '/dashboard/teams',
    component: Team,
    icon: <GroupWorkOutlined />,
    children: null
  },
  {
    id: 'financials',
    name: 'Financials',
    path: '/dashboard/financials',
    component: null,
    icon: <MonetizationOnOutlined />,
    children: [
      {
        id: 'invoices',
        name: 'Invoices',
        path: '/dashboard/financials/invoices',
        component: Invoices,
        children: null
      },
      {
        path: '/dashboard/financials/invoices/new',
        component: CreateInvoice,
      },
      {
        id: 'expenses',
        name: 'Expenses',
        path: '/dashboard/financials/expenses',
        component: Expenses,
        children: null
      },
    ]
  },
  {
    id: 'settings',
    path: '/dashboard/settings',
    component: Settings,
    children: null
  },
  {
    id: 'notifications',
    path: '/dashboard/notifications',
    component: Notifications,
    children: null
  },
  {
    id: 'account',
    path: '/dashboard/account',
    component: Account,
    children: null
  }
]

export const landingLayoutRoutes = [landingRoutes]

export const authLayoutRoutes = authRoutes

export const dashboardLayoutRoutes = dashboardsRoutes

export const welcomeLayoutRoutes = [welcomeRoutes]

export const organizationLayoutRoutes = [organizationRoutes]