import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #dddddd;
  z-index: 100;
`

const TimeBox = styled.div`
  width: 145px;
  height: 30px;
  border: 1px solid #e4e9ef;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin-left: 50px;
  cursor: pointer;

  svg {
    font-size: 20px;

    &:first-child {
      color: #8e9eb3;
    }

    &:last-child {
      color: #1890ff;
    }
  }

  span {
    color: #1890ff;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    margin-left: 0;
  }
`

const HideMenuButton = styled.div`
  position: absolute;
  top: 10px;
  left: -12.5px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dddddd;
  border-radius: 50%;
  cursor: pointer;
  background-color: white;

  svg {
    font-size: 16px;
    color: ${(props) => props.theme.colors.dark};
  }
`

const RightMenu = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 15px;
`

const IconButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  margin: 0 10px;

  svg {
    color: #617083;
    transition: all ease 0.3s;

    &:hover {
      color: #1890ff;
    }
  }

  .badge {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #ff4c4c;
    padding: 0 3px;
    border-radius: 2px;
    color: white;
    font-size: 11px;
  }
`

const MenuButton = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease 0.3s;
  background-color: #826a5f;
  color: white;
  font-weight: 600;

  &:hover {
    opacity: 0.8;
  }

  &.profile-menu {
    background-color: #53aaff;

    svg {
      color: white;
      width: 15px;
      height: 15px;
    }
  }
`

const Divider = styled.div`
  width: 2px;
  height: 28px;
  background-color: #dddddd;
  margin: 0 10px;
`

export { Container, TimeBox, HideMenuButton, RightMenu, IconButton, MenuButton, Divider }