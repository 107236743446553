import React, { useMemo } from 'react'
import { useSelector } from "react-redux";
import { getAccount } from "../../../store/selectors";
import { Box } from '@material-ui/core'
import { InfoOutlined, MoreVert } from '@material-ui/icons'
import { Avatar, Dropdown, Menu, Table } from 'antd'
import { SectionTitle, Tooltip, TableHeader } from './styles'
import { IconButton } from '../Default/styles'
import { TableContainer } from "../../../components/Dashboard/CommonStyled";
import { makeStyles } from '@material-ui/styles'
import { stringAvatar } from "../Financials/Expenses/helper";
import Button from '../../../components/Dashboard/Button'
import PaymentAccountModal from '../../../components/Dashboard/PaymentAccountModal'
import styled from 'styled-components'
import PaypalIcon from '../../../assets/images/dashboard/paypal.png'

const Container = styled(Box)`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${props => props.theme.breakpoints.down(500)}{
      flex-direction: column;
      align-items: flex-start;

      button {
        width: 100%;
        margin-top: 15px;
      }
    }
  }

  .payment-account-item {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 400px;
    margin-right: 20px;
    padding: 20px;
    border: 1px solid ${props => props.theme.colors.gray100};
    border-radius: 10px;

    .icon {
      margin-right: 20px;
    }

    .content {
      .payment-name {
        color: ${props => props.theme.palette.dashboard.textDark};
        font-weight: 600;
      }

      .email {
        color: ${props => props.theme.palette.dashboard.textLight};
      }
    }

    .menu-btn {
      position: absolute;
      right: 20px;
    }
  }
`

const NoDataText = styled.h3 `
  font-weight: bold;
  color: ${props => props.theme.palette.dashboard.textLight};
`

const useStyles = makeStyles(() => ({
  dropdown: {
    '& .ant-dropdown-menu-item': {
      padding: '8px 16px'
    }
  }
}))

const PaymentAccountItem = () => {
  const classes = useStyles()

  return (
    <Box className='payment-account-item'>
      <img className='icon' src={PaypalIcon} alt='Payment Icon' />
      <Box className='content'>
        <p className='payment-name'>PayPal</p>
        <span className='email'>harry1207@gmail.com</span>
      </Box>
      <Dropdown
        overlay={
          <Menu className={classes.dropdown}>
            <Menu.Item key='reauthenticate'>
              Reauthenticate
            </Menu.Item>
            <Menu.Item key='payment_account'>
              Delete payment account
            </Menu.Item>
          </Menu>
        }
        placement='bottomRight'
        trigger={['click']}
      >
        <IconButton size='small' className='menu-btn'><MoreVert /></IconButton>
      </Dropdown>
    </Box>
  )
}

const PaymentAccounts = () => {
  const [open, setOpen] = React.useState(false);
  const account = useSelector(getAccount);

  const columns = [
    {
      title: 'Organization',
      dataIndex: 'name',
      render: (name: string) => (
        <>
          <Avatar
            {...stringAvatar(name)}
          />
          <span style={{ marginLeft: 5, fontWeight: 500 }}>{name}</span>
        </>
      )
    },
    {
      title: 'Account',
      dataIndex: 'account',
      render: (account: string) => (
        <div>{account}</div>
      )
    },
    {
      title: 'Period',
      dataIndex: 'period',
      render: (period: string) => (
        <div>{period}</div>
      )
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      render: (rate: string) => (
        <div>{rate}</div>
      )
    }
  ];

  const tableData = useMemo(() => {
    if (account?.organization) {
      return [{
        ...account.organization,
        key: account.organization.id,
        name: account.organization.name,
      }];
    }
  }, [account]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container pt={3}>
      <Box className='header'>
        <Box display='flex' alignItems='center'>
          <SectionTitle className='mb-0'>Default payment accounts</SectionTitle>&nbsp;
          <Tooltip overlay={'Default payment accounts'} placement='bottom'><InfoOutlined fontSize='small'/></Tooltip>
        </Box>
        <Button className='primary-contained' onClick={handleOpen}>Add account</Button>
      </Box>
      <Box className='payment-account' py={2}>
        <PaymentAccountItem />
      </Box>
      <Box className='payroll-accounts' py={2}>
        <Box display='flex' alignItems='center' mb={3}>
          <SectionTitle className='mb-0'>Payroll accounts</SectionTitle>&nbsp;
          <Tooltip overlay={'Payroll accounts'} placement='bottom'><InfoOutlined fontSize='small'/></Tooltip>
        </Box>
        {
          account?.organization ? (
            <TableContainer>
              <Table
                columns={columns}
                dataSource={tableData}
                pagination={false}
                scroll={{ x: 'max-content' }}
              />
            </TableContainer>
          ) : (
            <>
              <TableHeader>
                <Box width='30%'>Organization</Box>
                <Box width='20%'>Account</Box>
                <Box width='30%'>Period</Box>
                <Box width='20%'>Rate</Box>
              </TableHeader>
              <Box className='payment-account' py={2} pl={2}>
                <NoDataText>No payroll accounts</NoDataText>
              </Box>
            </>
          )
        }
      </Box>
      <PaymentAccountModal
        open={open}
        onClose={handleClose}
      />
    </Container>
  )
}

export default PaymentAccounts