import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getMembers } from "../../../../store/selectors";
import { FormikProps } from "formik";
import { ITimeSheetInterface } from "./index";
import CustomSelect from "../../../Common/Select/Select";
import * as S from "./style";
import * as ProjectApi from '../../../../apis/project.api';

const PersonProjectTab = (props: FormikProps<ITimeSheetInterface>) => {
  const members = useSelector(getMembers);
  const [projectOption, setProjectOption] = useState<Array<{value: string, label: string }>>([]);

  const memberOption = useMemo(() => {
    return members.map((member) => ({
      value: member.id,
      label: member.name,
    }))
  }, [members]);

  useEffect(() => {
    if (props.values.developer) {
      ProjectApi.getProjectsByDeveloper(props.values.developer.value).then((res) => setProjectOption(res));
    }
  }, [props.values.developer]);

  const handelClearDeveloper = (props: FormikProps<ITimeSheetInterface>) => {
    props.setFieldValue('developer', null);
  };

  const handleClearProject = (props: FormikProps<ITimeSheetInterface>) => {
    props.setFieldValue('project', null);
  };

  return (
    <>
      <S.FormControl>
        <CustomSelect
          label="Developer"
          options={memberOption}
          name='developer'
          placeholder="Select a developer"
          styles={{
            control: (style) => ({ ...style, width: '100%', marginBottom: '1rem' })
          }}
          value={props.values.developer}
          onChange={(e) => props.setFieldValue('developer', { value: e?.value, label: e?.label })}
          onClear={() => handelClearDeveloper(props)}
        />
        <CustomSelect
          label="Project"
          options={projectOption}
          name='project'
          placeholder="Select a project"
          styles={{
            control: (style) => ({ ...style, width: '100%' })
          }}
          value={props.values.project}
          onChange={(e) => props.setFieldValue('project', { value: e?.value, label: e?.label })}
          onClear={() => handleClearProject(props)}
        />
      </S.FormControl>
    </>
  )
}
export default PersonProjectTab;
