import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getAccount } from "../../../store/selectors";
import { GetStartContainer } from "./style";
import { CardContentOptions } from "./CardContent";
import { Grid } from "@material-ui/core";
import ManageCard from "./ManageCard";
import DetailContainer from "./Details/DetailContainer";

const GettingStart = () => {
  const [step, setStep] = useState<number>(1);
  const account = useSelector(getAccount);

  return (
    <>
      <GetStartContainer>
        <div className='title'>👋 Welcome, {account?.name}!</div>
        <div className='description'>
          Let`s get you started with TimeGo so you can begin boosting your team`s productivity.
        </div>
        <Grid container spacing={2}>
          {
            CardContentOptions.map((option) => (
              <Grid key={option.key} item md={4} xs={12}>
                <ManageCard
                  order={option.key}
                  title={option.title}
                  description={option.description}
                  details={option.details}
                  step={step}
                  setStep={setStep}
                />
              </Grid>
            ))
          }
        </Grid>
      </GetStartContainer>
      <DetailContainer
        step={step}
        setStep={setStep}
      />
    </>
  )
}
export default GettingStart;
