import React from "react";
import { useSelector } from "react-redux";
import { stringAvatar } from "../../../../pages/Dashboard/Financials/Expenses/helper";
import { getAccount } from "../../../../store/selectors";
import { timeStyle } from "../../../../pages/Dashboard/TimeSheets/ViewAndEdit/timeCalculator";
import { TableContainer } from "../../CommonStyled";
import { Avatar, Table } from "antd";
import { ViewScreenshot } from "../style";

const tableData = [
  {
    key: 1,
    project: 'Website',
    manual: 10,
    totalTime:　2289,
    duration: '05:30 am ~ 06:08 am'
  },
  {
    key: 2,
    project: 'Blog',
    manual: 0,
    totalTime:　2289,
    duration: '05:30 am ~ 06:08 am'
  },
  {
    key: 3,
    project: 'Video production',
    manual: 25,
    totalTime:　2289,
    duration: '05:30 am ~ 06:08 am'
  }
];

const ApproveTimesheetTable = ({ teamMember }) => {
  const account = useSelector(getAccount);

  const columns = [
    {
      title: 'Project',
      dataIndex: 'project',
      render: (name: string) => (
        <div style={{ display: 'flex', alignItems: 'start' }}>
          <Avatar
            {...stringAvatar(name)}
          />
          <div style={{ marginLeft: '0.5rem' }}>
            <p style={{ fontWeight: 600, fontSize: 14, color: 'black' }}>{name}</p>
            <p style={{ fontWeight: 400, fontSize: 10, color: '#919aa6' }}>{account?.organization?.name}</p>
          </div>
        </div>
      )
    },
    {
      title: 'Manual',
      dataIndex: 'manual',
      render: (manual: number) => (
        <div>{manual}%</div>
      )
    },
    {
      title: 'Total Worked',
      dataIndex: 'totalTime',
      render: (time: number) => (
        <div>
          {timeStyle(time).hour}:{timeStyle(time).minute}:{timeStyle(time).second}
        </div>
      )
    },
    {
      title: 'Time Span',
      dataIndex: 'duration',
      render: (duration: string) => (
        <div>{duration}</div>
      )
    },
    {
      title: 'Screenshots',
      dataIndex: 'screenshots',
      render: () => (
        <ViewScreenshot>
          View Screenshot
        </ViewScreenshot>
      )
    }
  ];

  return (
    <TableContainer>
      <Table
        columns={columns}
        dataSource={teamMember ? tableData : []}
        pagination={false}
        scroll={{x: 'max-content'}}
      />
    </TableContainer>
  )
}
export default ApproveTimesheetTable;
