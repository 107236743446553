import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import { useDispatch } from 'react-redux'

import * as S from './styles'
import { setScrollPos } from '../../store/actions/home.actions'

export interface LayoutProps {
  children?: React.ReactNode
  isAnonym: boolean
}

const Layout = ({ children }: LayoutProps) => {
  const location = useLocation()
  const dispatch = useDispatch()

  const gotoTop = (smooth?: boolean) => {
    const scrollDiv = document.getElementById('root-container')
    if (scrollDiv) {
      scrollDiv.style.scrollBehavior = smooth ? 'smooth' : 'initial'
      scrollDiv.scrollTop = 0
    }
  }

  const handleScroll = (e: HTMLElement) => {
    dispatch(setScrollPos(e.scrollTop))
  }

  useEffect(() => {
    gotoTop()
  }, [location])

  return (
    <S.Root>
      <S.AppContent>
        <S.MainContent>
          <S.Container id='root-container' onScrollY={handleScroll}>
            { children }
          </S.Container>
        </S.MainContent>
      </S.AppContent>
    </S.Root>
  )
}

Layout.defaultProps = {
  isAnonym: false
}

export default Layout
