import React, { useMemo } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { setProject, setProjects } from "../../../../store/actions/project.actions";
import { Avatar, Table, Tag, Tooltip } from 'antd'
import { Box } from '@material-ui/core'
import { getProjects } from "../../../../store/selectors";
import { stringAvatar } from "../../Financials/Expenses/helper";
import { TableContainer } from '../../../../components/Dashboard/CommonStyled'
import { MemberList } from "../../Team/TeamTable";
import { IMemberInterface } from "../../../../components/Dashboard/Team/AddTeamModal";
import { IProjectInterface } from "../../../../components/Dashboard/Poject/AddProjectModal";
import styled from 'styled-components'
import TableActionMenu from '../../../../components/Dashboard/TableActionMenu'
import moment from "moment-timezone";
import _ from 'lodash'
import * as ProjectApi from "../../../../apis/project.api";

const TeamList = styled.div`
  display: flex;
  gap: 5px;

  .team {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #549a58;
    color: white;
    margin-right: 10px;
  }
`

const ProjectTable = ({ searchValue, setIsAddProjectOpen }) => {
  const dispatch = useDispatch();
  const projects = useSelector(getProjects);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name: string) => (
        <>
          <Avatar
            {...stringAvatar(name)}
          />
          <span style={{ marginLeft: 5, fontWeight: 500 }}>{name}</span>
        </>
      )
    },
    {
      title: 'Teams',
      dataIndex: 'teams',
      render: (teams: string[]) => (
        <TeamList>
          {
            teams.map((team, index) => (
              <Tooltip placement='top' title={_.upperFirst(team)} key={index} >
                <Avatar
                  {...stringAvatar(team)}
                />
              </Tooltip>
            ))
          }
        </TeamList>
      )
    },
    {
      title: 'Managers',
      dataIndex: 'managers',
      render: (managers: IMemberInterface[]) => (
        <MemberList>
          { managers.map((member, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: 3, marginBottom: 3 }}>
              {
                member.image ? (
                  <img
                    src={`${process.env.REACT_APP_API_SERVER}/${member.image.path}`}
                    alt="managers"
                    style={{ width: 32, height: 32, borderRadius: '50%' }}
                  />
                ) : (
                  <Avatar
                    {...stringAvatar(member.name)}
                  />
                )
              }
              <div style={{ marginLeft: 5 }}>{member.name}</div>
            </div>))
          }
        </MemberList>
      )
    },
    {
      title: 'Developers',
      dataIndex: 'developers',
      render: (developers: IMemberInterface[]) => (
        <MemberList>
          { developers.map((member, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: 3, marginBottom: 3 }}>
              {
                member.image ? (
                  <img
                    src={`${process.env.REACT_APP_API_SERVER}/${member.image.path}`}
                    alt="developers"
                    style={{ width: 32, height: 32, borderRadius: '50%' }}
                  />
                ) : (
                  <Avatar
                    {...stringAvatar(member.name)}
                  />
                )
              }
              <div style={{ marginLeft: 5 }}>{member.name}</div>
            </div>))
          }
        </MemberList>
      )
    },
    {
      title: 'Budget',
      dataIndex: 'cost',
      render: (cost: string) => {
        return (
          <div>{cost}{!!cost && '$'}</div>
        )
      }
    },
    {
      title: 'Trackable by me',
      dataIndex: 'basedOn',
      render: (basedOn: string) => {
        return (
          <div>{_.upperFirst(basedOn).replace('_', ' ')}</div>
        )
      }
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      render: (startDate: Date) => {
        return (
          <div>{moment(new Date(startDate)).format('DD/MM/YYYY')}</div>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: string) => <Tag color="green-inverse">{status}</Tag>
    },
    {
      title: '',
      key: 'operation',
      width: 100,
      render: (row: IProjectInterface) => (
        <TableActionMenu
          row={row}
          handleDeleteRow={handleDeleteRow}
          handleEditRow={handleEditRow}
        />
      )
    }
  ];

  const tableData = useMemo(() => {
    if (projects.length > 0) {
      return projects.map((item) => (
        {
          ...item,
          key: item.id,
          status: item.disableActivity ? 'Active' : 'Inactive'
        }
      ));
    }
  }, [projects]);

  const handleDeleteRow = async (id: string) => {
    await ProjectApi.deleteById(id);
    ProjectApi.getAll(searchValue).then((res) => dispatch(setProjects(res)));
  };

  const handleEditRow = async (id: string) => {
    await ProjectApi.getById(id).then((res) => dispatch(setProject(res)));
    setIsAddProjectOpen(true);
  };

  return (
    <TableContainer>
      {
        tableData &&
        tableData.length > 0 ? (
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            scroll={{x: 'max-content'}}
          />
        ) : (
          <Table
            columns={columns}
            dataSource={[]}
            pagination={false}
            scroll={{x: 'max-content'}}
          />
        )
      }
      <Box mt={2}>
        <p>Showing { tableData?.length } of { tableData?.length } { tableData?.length === 1 ? 'project' : 'projects' }</p>
      </Box>
    </TableContainer>
  )
}

export default ProjectTable;
