import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { setMembers } from "../../../../store/actions/member.action";
import { setProjects } from "../../../../store/actions/project.actions";
import { getMembers, getProjects } from "../../../../store/selectors";
import Select, { Option } from '../../../../components/Dashboard/Select'
import { Box } from '@material-ui/core'
import { AppPage } from '../../../../components/Dashboard/CommonStyled'
import DatePicker from '../../../../components/Dashboard/DatePicker'
import Button from '../../../../components/Dashboard/Button'
import styled from 'styled-components'
import LookingImg from '../../../../assets/images/dashboard/looking.svg'
import * as UserApi from "../../../../apis/user.api";
import * as S from './styles'
import * as ProjectApi from "../../../../apis/project.api";

export const StyledSelect = styled(Select)`
  min-width: 140px;
`

export const timeTypeOption = [
  {
    value: 'normal',
    label: 'Normal'
  },
  {
    value: 'idle',
    label: 'Idle'
  },
  {
    value: 'resumed',
    label: 'Resumed'
  },
  {
    value: 'manual',
    label: 'Manual'
  },
];

export const sourceTypeOption = [
  {
    value: 'desktop',
    label: 'Desktop'
  },
  {
    value: 'mobile',
    label: 'Mobile'
  },
  {
    value: 'browser',
    label: 'Browser'
  },
  {
    value: 'web-timer',
    label: 'Web Timer'
  },
];

export const levelTypeOption = [
  {
    value: '> 80',
    label: '> 80%'
  },
  {
    value: '> 60',
    label: '> 60%'
  },
  {
    value: '< 40',
    label: '< 40%'
  },
  {
    value: '< 20',
    label: '< 20%'
  },
];

export const timeZones = [
  { value: '-11', label: '(GMT -11:00) Samoa' },
  { value: '-10', label: '(GMT -10:00) Hawaii' },
  { value: '-9', label: '(GMT -09:00) Alaska' },
  { value: '-8', label: '(GMT -08:00) Los Angeles (PST)' },
  { value: '-7', label: '(GMT -07:00) Denver (MST)' },
  { value: '-6', label: '(GMT -06:00) Mexico City (CST)' },
  { value: '-5', label: '(GMT -05:00) New York (EST)' },
  { value: '-4', label: '(GMT -04:00) Atlantic Time (Canada)' },
  { value: '-3.5', label: '(GMT -03:30) Newfoundland' },
  { value: '-3', label: '(GMT -03:00) Brasília (BRT)' },
  { value: '-2', label: '(GMT -02:00) Mid-Atlantic (Brazil)' },
  { value: '-1', label: '(GMT -01:00) Cape Verde' },
  { value: '0', label: '(GMT +00:00) London (GMT)' },
  { value: '1', label: '(GMT +01:00) Berlin (CET)' },
  { value: '2', label: '(GMT +02:00) Cairo (EET)' },
  { value: '3', label: '(GMT +03:00) Moscow (MSK)' },
  { value: '3.5', label: '(GMT +03:30) Tehran (IRST)' },
  { value: '4', label: '(GMT +04:00) Abu Dhabi (GST)' },
  { value: '4.5', label: '(GMT +04:30) Kabul (AFGT)' },
  { value: '5', label: '(GMT +05:00) Karachi (PKT)' },
  { value: '5.5', label: '(GMT +05:30) Mumbai (IST)' },
  { value: '6', label: '(GMT +06:00) Dhaka (BDT)' },
  { value: '6.5', label: '(GMT +06:30) Yangon (MMT)' },
  { value: '7', label: '(GMT +07:00) Bangkok (ICT)' },
  { value: '8', label: '(GMT +08:00) Beijing (CST)' },
  { value: '8.5', label: '(GMT +08:30) Pyongyang (KST)' },
  { value: '9', label: '(GMT +09:00) Tokyo (JST)' },
  { value: '9.5', label: '(GMT +09:30) Adelaide (ACST)' },
  { value: '10', label: '(GMT +10:00) Brisbane (AEST)' },
  { value: '11', label: '(GMT +11:00) Vladivostok (VLAT)' },
  { value: '12', label: '(GMT +12:00) Fiji (FJT)' },
  { value: '13', label: '(GMT +13:00) Tonga (TOT)' },
  { value: '14', label: '(GMT +14:00) Kiribati (LINT)' }
];

const Apps = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date());
  const members = useSelector(getMembers);
  const projects = useSelector(getProjects);

  useEffect(() => {
    UserApi.getAll().then((res) =>　dispatch(setMembers(res)));
    ProjectApi.getAll('').then((res) => dispatch(setProjects(res)));
  }, []);

  return (
    <AppPage>
      <Box mb={2}>
        <h1>App activity</h1>
      </Box>
      <S.FilterGroup mt={2}>
        <Box className='left'>
          <DatePicker
            date={date}
            setDate={setDate}
          />
          <Box ml='10px'>
            <Button className='outlined' onClick={() => setDate(new Date())}>Today</Button>
          </Box>
          <Box ml='10px'>
            <Button className='outlined'>All notes</Button>
          </Box>
        </Box>
        <Box className='right'>
          <Box display='flex'>
            <Box className='group' display='flex' mr='20px'>
              <Box>
                <StyledSelect placeholder='All types' label='Time Type'>
                  {
                    timeTypeOption.map((item) => (
                      <Option key={item.value} value={item.value}>{item.label}</Option>
                    ))
                  }
                </StyledSelect>
              </Box>
              <Box ml='20px'>
                <StyledSelect placeholder='All sources' label='Source'>
                  {
                    sourceTypeOption.map((item) => (
                      <Option key={item.value} value={item.value}>{item.label}</Option>
                    ))
                  }
                </StyledSelect>
              </Box>
            </Box>
            <Box className='group' display='flex'>
              <Box>
                <StyledSelect placeholder='All levels' label='Activity Level'>
                  {
                    levelTypeOption.map((item) => (
                      <Option key={item.value} value={item.value}>{item.label}</Option>
                    ))
                  }
                </StyledSelect>
              </Box>
              <Box ml='20px'>
                <StyledSelect placeholder='Select a timezone' label='Timezone'>
                  {
                    timeZones.map((item) => (
                      <Option key={item.value} value={item.value}>{item.label}</Option>
                    ))
                  }
                </StyledSelect>
              </Box>
            </Box>
          </Box>
          <Box className='group' display='flex' justifyContent='flex-end' mt={2}>
            <Box>
              <StyledSelect placeholder='Select a member' label='Member'>
                {
                  members.map((member) => (
                    <Option key={member.id} value={member.id as string}>{member.name}</Option>
                  ))
                }
              </StyledSelect>
            </Box>
            <Box ml={2}>
              <StyledSelect placeholder='All projects' label='Project'>
                {
                  projects.map((project) => (
                    <Option key={project.id} value={project.id as string}>{project.name}</Option>
                  ))
                }
              </StyledSelect>
            </Box>
          </Box>
        </Box>
      </S.FilterGroup>
      <S.TableHeader>
        <Box width='20%'>Project</Box>
        <Box width='30%'>App name</Box>
        <Box width='40%'>Time spent (hrs)</Box>
        <Box>Sessions</Box>
      </S.TableHeader>
      <S.NoDataContainer>
        <img src={LookingImg} alt='No Activity' />
        <p>No data for this date range</p>
      </S.NoDataContainer>
    </AppPage>
  )
}

export default Apps;
