import React from 'react'
import { Box } from '@material-ui/core'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import PaypalIcon from '../../../assets/images/dashboard/paypal-icon.png'
import Modal from '../Modal'
import * as S from './styles'
import Input from '../Input'
import Button from '../Button'

interface IProps {
  open: boolean;
  onClose: () => void;
}

const formSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email is not valid')
    .required('Email is required.'),
});

const CreatePaypalAccountModal = (props: IProps) => {
  const { open, onClose } = props

  const handleSubmit = (values: { email: string }) => {
    const { email } = values
    console.log(email)
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Create Payment Account'
    >
      <S.CreatePaymentAccountContainer>
        <Box display='flex' justifyContent='center' py={2}>
          <img src={PaypalIcon} alt='Paypal' />
        </Box>
        <Box py={2}>
          <h3>Instructions</h3>
          <Box px={2} mb={2}>
            <p>1. Enter the Paypal email address that will receive the funds</p>
          </Box>
          <S.DescriptionPanel>
            <p>If the PayPal account is in a different currency than the organization currency then PayPal will convert the sum of money transferred.</p>
            <p>Older unpaid time will not be paid. The payment period starts as soon as this account is enabled.</p>
          </S.DescriptionPanel>
        </Box>
        <S.Divider />
        <Formik
          initialValues={{ email: '', submit: false }}
          validationSchema={formSchema}
          onSubmit={handleSubmit}
        >
          {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit
          }) => (
            <Form className="form" onSubmit={handleSubmit}>
              <S.FormControl error={Boolean(errors.email || touched.email)}>
                <span className='label'>Paypal Email*</span>
                <Input
                  name='email'
                  type='email'
                  value={values.email}
                  onChange={handleChange}
                  error={errors.email || touched.email ? errors.email : ''}
                />
              </S.FormControl>
              <Box className='footer'>
                <Button className='outlined' onClick={onClose}>Cancel</Button>&nbsp;&nbsp;
                <Button htmlType='submit' className='primary-contained'>Connect</Button>
              </Box>
            </Form>
          )}
        </Formik>
      </S.CreatePaymentAccountContainer>
    </Modal>
  )
}

export default CreatePaypalAccountModal
