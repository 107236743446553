import React, { ReactNode } from "react";
import { Input as AInput, InputProps } from 'antd'
import styled, { css } from 'styled-components'

interface Props extends InputProps {
  error?: string;
  label?: ReactNode;
  name: string;
  values: object;
  setFieldValue: (key: string, target: string) => void;
}

const InputGroupContainer = styled.div`
  label {
    color: #79899d;
    font-size: 11px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
`;

const StyledInputGroup = styled(AInput)<{error?: string}>`
  &.ant-input-group-wrapper .ant-input-wrapper input {
    padding: 12px 12px !important;
    color: ${(props) => props.theme.palette.text.primary} !important;
    font: 14px 'Lato-Regular' !important;
    line-height: 1.25 !important;
    border: 1px solid #CCCCCC;
    outline: 0 !important;
    box-sizing: border-box !important;
    width: 100% !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    &:hover:not(:focus) {
      border-color: #c4cfdc;
    }
    &:focus {
      border-color: #2aa7ff;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(42, 167, 255, 0.6);
    }

    ${(props) => props.error && css`
      border-color: red !important;
    `}
  }

    .ant-input-group-addon {
      padding: 9px 20px;
      font-size: 14px;
      font-weight: 400;
      color: #3e4956;
      text-align: center;
      background-color: #eeeeee;
      border: 1px solid #e4e9ef;
      border-radius: 6px;
    }
`

const ErrorText = styled.p`
  margin: 2px 0 0 0 !important;
  font-size: 12px !important;
  color: red !important;
  line-height: 20px !important;
`

const InputGroup = (props: Props) => {
  return (
    <InputGroupContainer>
      {!!props.label && <label>{props.label}</label>}
      <StyledInputGroup
        name={props.name}
        value={props.values[`${props.name}`]}
        onChange={(e) => props.setFieldValue(`${props.name}`, e.target.value)}
        addonAfter={props.addonAfter}
        type={props.type}
      />
      {
        props.error &&
        <ErrorText>{props.error}</ErrorText>
      }
    </InputGroupContainer>
  )
}

export default InputGroup;
