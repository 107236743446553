import React from 'react'
import { Add } from '@material-ui/icons'
import styled from 'styled-components'

import { Card } from '../styles'
import { Box } from '@material-ui/core'
import * as S from '../styles'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import Button from '../../../../components/Dashboard/Button'

// @ts-ignore
const Container = styled(Card)`
  background-color: #eaeaea;
  transition: all ease 0.3s;

  .add-btn {
    display: none;
  }

  .todo-list {
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    .todo-item {
      display: flex;
      width: 100%;
      padding: 15px 0;
      border-bottom: 2px solid #d8d8d8;

      > div {
        width: 50%;
      }

      .left {
        display: flex;
        align-items: center;

        .avatar {
          width: 33px;
          height: 33px;
          background-color: #c3c5c5;
          border-radius: 50%;
          margin-right: 5px;
        }

        .progress {
          position: relative;
          flex: 1;
          height: 18px;
          background-color: transparent;

          .content {
            position: absolute;
            left: 0;
            height: 100%;
            background-color: #d8d8d8;
          }
        }
      }

      .right {
        display: flex;
        align-items: center;

        .avatar {
          width: 25px;
          height: 15px;
          background-color: #c3c5c5;
          margin-right: 10px;
        }

        .progress {
          position: relative;
          flex: 1;
          max-width: 100px;
          height: 4px;
          background-color: #d8d8d8;
          border-radius: 2px;

          .content {
            position: absolute;
            left: 0;
            height: 100%;
            background-color: #c3c5c5;
          }
        }
      }
    }
  }

  &:hover {
    background-color: white;

    .todo-list {
      .todo-item {
        .right {
          .progress {
            .content {
              background-color: ${props => props.theme.colors.blue50};
            }
          }
        }
      }
    }

    .plus-btn {
      display: none;
    }

    .add-btn {
      display: inline-block;
    }
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 0;

  .title {
    font-size: 12px;
    text-transform: uppercase;
    color: ${props => props.theme.palette.dashboard.textLight};
  }

  .sub-title {
    font-size: 10px;
    color: ${props => props.theme.palette.dashboard.textLight};
  }
`

const SortItem = SortableElement((props) => <Container {...props} />)
const DragHandle = SortableHandle((props) => <Header {...props} />)

const Todo = (props: { index: number }) => {
  const { index } = props
  return (
    <SortItem index={index}>
      <DragHandle>
        <Box display='flex' flexDirection='column'>
          <span className='title'>To-Dos</span>
          <span className='sub-title'>Recently worked on to-do items.</span>
        </Box>
        <Box>
          <S.IconButton className='plus-btn'><Add /></S.IconButton>
          <Button className='add-btn primary-contained'>Add a to-do</Button>
        </Box>
      </DragHandle>
      <div className='card-body'>
        <div className='todo-list'>
          <div className='todo-item'>
            <div className='left'>
              <div className='avatar' />
              <div className='progress'>
                <Box width='25%' className='content' />
              </div>
            </div>
            <div className='right'>
              <div className='avatar' />
              <div className='progress'>
                <Box width='30%' className='content' />
              </div>
            </div>
          </div>
          <div className='todo-item'>
            <div className='left'>
              <div className='avatar' />
              <div className='progress'>
                <Box width='35%' className='content' />
              </div>
            </div>
            <div className='right'>
              <div className='avatar' />
              <div className='progress'>
                <Box width='30%' className='content' />
              </div>
            </div>
          </div>
          <div className='todo-item'>
            <div className='left'>
              <div className='avatar' />
              <div className='progress'>
                <Box width='30%' className='content' />
              </div>
            </div>
            <div className='right'>
              <div className='avatar' />
              <div className='progress'>
                <Box width='30%' className='content' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SortItem>
  )
}

export default Todo