import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMembers } from "../../../../store/actions/member.action";
import { getMembers } from "../../../../store/selectors";
import { ReviewTeam } from "../style";
import { Select } from "antd";
import { Grid } from "@material-ui/core";
import ActivityDiagram from "./ActivityDiagram";
import AppUrlDiagram from "./AppUrlDiagram";
import noDataIcon from '../../../../assets/images/no_data.png';
import * as UserApi from "../../../../apis/user.api";
import WorkTimeDiagram from "./WorkTimeDiagram";

const { Option } = Select;

const ReviewTeamInsights = () => {
  const dispatch = useDispatch();
  const [teamMember, setTeamMember] = useState<string>();
  const members = useSelector(getMembers);

  useEffect(() => {
    UserApi.getAll().then((res) => dispatch(setMembers(res)));
  }, []);

  return (
    <ReviewTeam>
      <div className='team_title'>Review Team Insights</div>
      <div
        className='team_description'
      >
        See what your team is focusing on, how they`re spending their time,
        and where they can be more productive.
      </div>
      <Select
        placeholder='Team member'
        className='team_member_select'
        value={teamMember}
        onChange={(e) => setTeamMember(e)}
      >
        {
          members.map((member) => (
            <Option key={member.id} value={member.id as string} >{member.name}</Option>
          ))
        }
      </Select>
      <Grid container spacing={3} style={{ marginTop: '2rem' }}>
        <Grid item md={4} xs={12} >
          <div className='no_data'>
            {
              teamMember ? (
                <ActivityDiagram />
              ) : (
                <img src={noDataIcon} width={170} height={130} alt='no_data' />
              )
            }
          </div>
        </Grid>
        <Grid item md={4} xs={12} >
          <div className='no_data'>
            {
              teamMember ? (
                <AppUrlDiagram />
              ) : (
                <img src={noDataIcon} width={170} height={130} alt='no_data' />
              )
            }
          </div>
        </Grid>
        <Grid item md={4} xs={12} >
          <div className='no_data'>
            {
              teamMember ? (
                <WorkTimeDiagram />
              ) : (
                <img src={noDataIcon} width={170} height={130} alt='no_data' />
              )
            }
          </div>
        </Grid>
      </Grid>
    </ReviewTeam>
  )
}
export default ReviewTeamInsights;
