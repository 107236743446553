import React, { useMemo } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getTeams } from "../../../store/selectors";
import { setTeam, setTeams } from "../../../store/actions/team.action";
import { stringAvatar } from "../Financials/Expenses/helper";
import { TableContainer } from '../../../components/Dashboard/CommonStyled'
import { Box } from "@material-ui/core";
import { Avatar, Table } from 'antd'
import styled from 'styled-components'
import TableActionMenu from '../../../components/Dashboard/TableActionMenu'
import * as TeamApi from '../../../apis/team.api'
import {IMemberInterface, ITeamInterface} from "../../../components/Dashboard/Team/AddTeamModal";

export const MemberList = styled.div`

  img {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
  }
`

export const Name = styled.div`
  display: flex;
  align-items: center;
  color: #53aaff;

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    background-color: #82b55e;
    margin-right: 10px;
    text-transform: uppercase;
  }

  img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
`

const TeamTable = ({ searchValue, setOpenModal }) => {
  const dispatch = useDispatch();
  const teams = useSelector(getTeams);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name: string) => (
        <Box display='flex' alignItems='center'>
          <Avatar
            {...stringAvatar(name)}
          />
          <span style={{ marginLeft: 5 }}>{name}</span>
        </Box>
      )
    },
    {
      title: 'Managers',
      dataIndex: 'managers',
      render: (managers: IMemberInterface[]) => (
        <MemberList>
          { managers.map((manager, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: 3, marginBottom: 3 }}>
              {
                manager.image ? (
                  <img
                    src={`${process.env.REACT_APP_API_SERVER}/${manager.image.path}`}
                    alt="manager"
                    style={{ width: 32, height: 32, borderRadius: '50%' }}
                  />
                ) : (
                  <Avatar
                    {...stringAvatar(manager.name)}
                  />
                )
              }
              <div style={{ marginLeft: 5 }}>{manager.name}</div>
            </div>))
          }
        </MemberList>
      )
    },
    {
      title: 'Members',
      dataIndex: 'members',
      render: (members: IMemberInterface[]) => (
        <MemberList>
          { members.map((member, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: 3, marginBottom: 3 }}>
              {
                member.image ? (
                  <img
                    src={`${process.env.REACT_APP_API_SERVER}/${member.image.path}`}
                    alt="member"
                    style={{ width: 32, height: 32, borderRadius: '50%' }}
                  />
                ) : (
                  <Avatar
                    {...stringAvatar(member.name)}
                  />
                )
              }
              <div style={{ marginLeft: 5 }}>{member.name}</div>
            </div>))
          }
        </MemberList>
      )
    },
    {
      title: 'Projects',
      dataIndex: 'projects',
      render: (projects: string[]) => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          {
            projects.map((name, index) => (
              <div key={index}>
                <Avatar
                  key={index}
                  {...stringAvatar(name)}
                />
                <span style={{ marginLeft: 5, fontWeight: 500 }}>{name}</span>
              </div>
            ))
          }
        </div>
      )
    },
    {
      title: '',
      key: 'operation',
      width: 100,
      render: (row: ITeamInterface) => (
        <TableActionMenu
          row={row}
          handleEditRow={handleEditRow}
          handleDeleteRow={handleDeleteRow}
        />
      )
    }
  ];

  const tableData = useMemo(() => {
    return teams.map((team) => (
      {
        ...team,
        key: team.id,
      }
    ))
  }, [teams]);

  const handleEditRow = async (id: string) => {
    await TeamApi.getById(id).then((res) => dispatch(setTeam(res)));
    setOpenModal(true);
  };

  const handleDeleteRow = async (id: string) => {
    await TeamApi.deleteById(id);
    TeamApi.getAll(searchValue).then((res) => dispatch(setTeams(res)));
  };

  return (
    <TableContainer>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        scroll={{x: 'max-content'}}
      />
      <Box mt={2}>
        <p>Showing { tableData?.length } of { tableData?.length } { tableData?.length > 1 ? 'teams' : 'team' }</p>
      </Box>
    </TableContainer>
  )
}

export default TeamTable;
