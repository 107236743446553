import React from "react";
import { ProgressBarContainer } from "../../style";
import { BiArrowFromBottom } from "react-icons/all";

const ProgressBar = ({ average }) => {
  return (
    <ProgressBarContainer>
      <span>0%</span>
      <div className='progress_bar'>
        <div className='scale_bar' style={{ left: `${average}%`, translate: `${-45}%` }}>
          <span>{average}%</span>
          <div style={{ width: '1.5px', height: '13px', backgroundColor: 'black' }} />
          <BiArrowFromBottom style={{ marginLeft: '2.5px' }} />
        </div>
      </div>
      <span>100%</span>
    </ProgressBarContainer>
  )
}
export default ProgressBar;
