import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { getAccount } from "../../../../store/selectors";
import { PickProjectContainer } from "../style";
import { Add, Close } from "@material-ui/icons";
import { Grid } from "@material-ui/core";

const pickProjectOptions = [
  'Luka`s Organization 123`s Project',
  'Admin',
  'Customer Service',
  'Data Entry',
  'Design',
  'Finance',
  'General',
  'HR',
  'IT',
  'Marketing',
  'Meetings',
  'Project Management',
  'Research',
  'Sales',
  'Training',
  'Video Editing',
  'Create your project'
];

interface IProps {
  selectedOptions: string[];
  setSelectedOptions: (value: string[]) => void;
}

const PickProject: FC<IProps> = ({ selectedOptions, setSelectedOptions }) => {
  const account = useSelector(getAccount);

  useEffect(() => {
    if (account.organization.pick_projects) {
      setSelectedOptions(JSON.parse(account.organization.pick_projects) || []);
    }
  }, [account]);

  const handlePickOptions = (value: string) => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== value));
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  return (
    <PickProjectContainer>
      <div className='pick_project_title'>Pick Projects</div>
      <div className='pick_project_description'>
        Your team`s time needs to be kept organized. Create the projects your team can track time towards.
      </div>
      <Grid container spacing={2} className='pick_project_items'>
        {
          pickProjectOptions.map((option, index) => (
            <Grid
              key={index + option}
              item
            >
              <div
                className='pick_project_item'
                style={selectedOptions.includes(option)
                  ? { backgroundColor: '#eaf6ff', borderColor: '#2c88f4', color: '#2c88f4' }
                  : {}}
                onClick={() => handlePickOptions(option)}
              >
                {
                  !selectedOptions.includes(option) && (
                    <Add className='add_icon'/>
                  )
                }
                <span>{option}</span>
                {
                  selectedOptions.includes(option) && (
                    <Close className='close_icon'/>
                  )
                }
              </div>
            </Grid>
          ))
        }
      </Grid>
    </PickProjectContainer>
  )
}
export default PickProject;
