import Http from "./http";

interface IUpdateAccountProps {
  name: string;
  email: string;
  password: string;
  role: string;
  team: string | null;
}

export const getAll = () => {
  return Http.get('/user/all');
}

export const fetchMe = () => {
  return Http.get('/user/me');
}

export const updateAccount = (value: IUpdateAccountProps) => {
  return Http.patch('/user/account', value);
}
