
export const CardContentOptions = [
  {
    key: 1,
    title: 'Review Activity',
    description: 'Manage your team`s productivity.',
    details: [
      {
        order: 1,
        menu: 'Subscribe to a Report'
      },
      {
        order: 2,
        menu: 'Review Team Insights',
      },
      {
        order: 3,
        menu: 'Approve a Timesheet',
      }
    ]
  },
  {
    key: 2,
    title: 'Get Organized',
    description: 'Get things ready for your team to work.',
    details: [
      {
        order: 4,
        menu: 'Pick Projects'
      },
      {
        order: 5,
        menu: 'Create To-Dos',
      }
    ]
  },
  {
    key: 3,
    title: 'Manage Team',
    description: 'Invite your team to start trying TimeGo.',
    details: [
      {
        order: 6,
        menu: 'Create Teams'
      },
      {
        order: 7,
        menu: 'Invite Members',
      },
    ]
  }
];
