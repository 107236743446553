import React, { useState } from "react";
import { useHistory, useParams } from "react-router";

import * as S from "./styles";
import * as OrganizationApi from '../../../apis/organization.api';
import { IOrganization, IOrganizationMember } from "../../../shared/interfaces";
import OrganizationWizardPreferences from "./Preferences";
import OrganizationWizardIntegrations from "./Integrations";
import OrganizationWizardManageTeam from "./ManageTeam";
import WizardLayout from "../../../components/Organization/WizardLayout/WizardLayout";

const OrganizationWizardSettings = () => {
  const { id } = useParams();
  const history = useHistory();
  const [step, setStep] = useState(1);

  const onBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const onNext = async (value: IOrganization) => {
    if (step < 3) {
      setStep(step + 1);
      await OrganizationApi.update({id, value});
    } else {
      const errorEmail = JSON.parse(value.members as string).filter((item: IOrganizationMember) =>
        !item.email.match(/^(([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+)?$/)
      );

      if (errorEmail.length === 0) {
        OrganizationApi.update({id, value}).then((res) => history.push(`/getting_started/${res.id}`));
        localStorage.removeItem('userId');
      }
    }
  };

  return (
    <WizardLayout step={step}>
      <S.FormContainer>
        <div className="form-wrapper">
          {step === 1 && <OrganizationWizardPreferences onBack={onBack} onNext={onNext} />}
          {step === 2 && <OrganizationWizardIntegrations onBack={onBack} onNext={onNext} />}
          {step === 3 && <OrganizationWizardManageTeam onBack={onBack} onNext={onNext} />}
        </div>
      </S.FormContainer>
    </WizardLayout>
  );
};

export default OrganizationWizardSettings;
