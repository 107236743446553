import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { CardImage1, CardImage2, CardImage3, CardImage4, CardImage5 } from '../../../components/Common/Images'
import * as S from './styles'

const ProductivitySection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <S.Container>
      <div className='container'>
        <h2 className='title'>The productivity platform</h2>
        <p className='description'>The complete platform for time tracking, workforce and project management.</p>
        <div className='card-list'>
          <S.CardItem direction='vertical' image={CardImage1}>
            <div className='card-image' />
            <div className='card-body'>
              <div className='title'>
                <h3>TimeGo <span>Time</span></h3>
              </div>
              <p>Simple time tracking and reporting</p>
            </div>
          </S.CardItem>
          <S.CardItem direction='vertical' image={CardImage2}>
            <div className='card-image' />
            <div className='card-body'>
              <div className='title'>
                <h3>TimeGo <span>Desk</span></h3>
              </div>
              <p>Proof of work, time tracking, and team management</p>
            </div>
          </S.CardItem>
          <S.CardItem direction='vertical' image={CardImage3}>
            <div className='card-image' />
            <div className='card-body'>
              <div className='title'>
                <h3>TimeGo <span>Field</span></h3>
              </div>
              <p>GPS location tracking and team management</p>
            </div>
          </S.CardItem>
          <S.CardItem direction={isMobile ? 'vertical' : 'horizontal'} image={CardImage4}>
            <div className='card-image' />
            <div className='card-body'>
              <div className='title'>
                <h3>TimeGo <span>Tasks</span></h3>
              </div>
              <p>Simple, Agile project management for productive teams of all sizes</p>
            </div>
          </S.CardItem>
          <S.CardItem direction={isMobile ? 'vertical' : 'horizontal'} image={CardImage5}>
            <div className='card-image' />
            <div className='card-body'>
              <div className='title'>
                <h3>TimeGo <span>Talent</span></h3>
              </div>
              <p>The free and easy way to find remote talent across the world</p>
            </div>
          </S.CardItem>
        </div>
      </div>
      <div className='background-brand-circle green' />
      <div className='background-brand-circle blue' />
      <div className='background-brand-circle yellow' />
      <div className='background-brand-circle white' />
    </S.Container>
  )
}

export default ProductivitySection;
