import React from "react";
import { Alert, Input as AInput, InputProps, Select } from "antd";
import styled from "styled-components";
const { Option } = Select;

interface Props extends InputProps {
  options: Array<{ value: string; label: string }>;
  onSelectChange: (value: string) => void;
  inputValue: string;
  selectValue: string;
}

const StyledInput = styled(AInput)<{ error?: string }>`
  &.ant-input-group-wrapper {
    .ant-input-group {
      width: fit-content;
    }

    .ant-select {
      width: 100%;
    }
    input {
      border: 1px solid #d6dbe6;
      height: 40px;
      width: 320px;
      padding: 10px 16px 10px 16px;
      border-radius: 6px 0 0 6px;
      &:focus {
        border-color: #40a9ff !important;
        box-shadow: rgba(0, 123, 255, 0.25) 0 0 0 2px;
      }
    }

    .ant-input-group-addon {
      border-radius: 0 6px 6px 0 !important;
      background: white;
      width: 160px;
    }
  }
`;

const SelectInput = (props: Props) => {
  const { options, onSelectChange, inputValue, selectValue, onSelect, ...restProps } = props;
  const selectAfter = (
    <Select onChange={onSelectChange} defaultValue={selectValue} className="select-after">
      {options.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
  return (
    <>
      <StyledInput value={inputValue} {...restProps} addonAfter={selectAfter} />
      {
        !inputValue.match(/^(([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+)?$/) && (
          <Alert message='failed email' type="error" showIcon />
        )
      }
    </>
  );
};

export default SelectInput;
