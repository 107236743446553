import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { setTimeSheets } from "../../../../store/actions/timesheet.action";
import { useTheme } from '@material-ui/core/styles'
import { setMembers } from "../../../../store/actions/member.action";
import { getMembers, getProjects, getTimeSheets } from "../../../../store/selectors";
import { BsFillSkipStartFill, BsFillStopwatchFill } from "react-icons/all";
import { setProjects } from "../../../../store/actions/project.actions";
import { saveAs} from "file-saver";
import { CSVService } from "../../../../service/csv.service";
import { PdfService } from "../../../../service/pdf.service";
import { Box, IconButton } from '@material-ui/core'
import { AppPage, TabContainer, Tabs } from '../../../../components/Dashboard/CommonStyled'
import { Close } from '@material-ui/icons'
import { Tooltip } from "antd";
import { levelTypeOption, sourceTypeOption, StyledSelect, timeTypeOption } from "../../Activity/Apps";
import { divideByDateRange, timeStyle } from "./timeCalculator";
import Select, { Option } from '../../../../components/Dashboard/Select'
import DateRange, { IDateRange } from '../../../../components/Dashboard/DateRange'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Tab from '../../../../components/Dashboard/Tab'
import Button from '../../../../components/Dashboard/Button'
import TimeLine from '../../../../components/Dashboard/TimeLine'
import WeeklyTimesheet from './WeeklyTimesheet'
import CalendarTimesheet from './CalendarTimesheet'
import TimeSheetModal from "../../../../components/Dashboard/TimeSheet/TimeSheetModal";
import TimeSheetTable from "./TimeSheetTable";
import moment from 'moment-timezone'
import _ from "lodash";
import * as S from './styles'
import * as TimeSheetApi from "../../../../apis/timesheet.api";
import * as UserApi from "../../../../apis/user.api";
import * as ProjectApi from "../../../../apis/project.api";

const { TabPane } = Tabs

const tabs = [
  {
    id: '1',
    label: 'Daily'
  },
  {
    id: '2',
    label: 'Weekly'
  },
  {
    id: '3',
    label: 'Monthly'
  },
]

export interface IFilterInterface {
  developer: string | null;
  project: string | null;
  timeType: string | null;
  sourceType: string | null;
  levelType: string | null;
  during: IDateRange;
}

const ViewAndEdit = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const members = useSelector(getMembers);
  const projects = useSelector(getProjects);
  const timeSheets = useSelector(getTimeSheets);
  const xxsDown = useMediaQuery(theme.breakpoints.down(450));
  const [activeTabId, setActiveTabId] = useState('1');
  const [workTime, setWorkTime] = useState<number>(0);
  const [secondReading, setSecondReading] = useState<number>(0);
  const [progress, setProgress] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf('day').toDate(),
    endDate: moment().endOf('day').toDate()
  });
  const [filterOpen, setFilterOpen] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<IFilterInterface>({
    developer: null,
    project: null,
    timeType: null,
    sourceType: null,
    levelType: null,
    during: dateRange,
  });

  useEffect(() => {
    UserApi.getAll().then((res) => dispatch(setMembers(res)));
    ProjectApi.getAll('').then((res) => dispatch(setProjects(res)));
  }, []);

  useEffect(() => {
    if (members.length !== 0) {
      setFilterParams({
        ...filterParams,
        developer: members[0]?.id as string,
        during: dateRange,
      });
    }
  }, [members, projects, dateRange]);

  useEffect(() => {
    TimeSheetApi.getTimeSheets(filterParams).then((res) => {
      dispatch(setTimeSheets(res.data));
      setWorkTime(res.time);
    });
  }, [filterParams]);

  useEffect(() => {
    if (activeTabId === '1') {
      setDateRange({
        startDate: moment().startOf('day').toDate(),
        endDate: moment().endOf('day').toDate()
      });
    } else if (activeTabId === '2') {
      setDateRange({
        startDate: moment().startOf('week').toDate(),
        endDate: moment().endOf('week').toDate()
      });
    } else {
      setDateRange({
        startDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('month').toDate()
      });
    }
  }, [activeTabId]);

  const handleClearFilter = () => {
    setFilterParams({
      ...filterParams,
      developer: null,
      project: null,
      timeType: null,
      sourceType: null,
      levelType: null,
    });
  };

  const handleStartTiming = async () => {
    const timesheet = timeSheets[0];
    const progressing = _.filter(timeSheets, item => item.progressing);
    if (progressing.length === 0) {
      await TimeSheetApi.create({
        developer: timesheet.developer.id,
        project: timesheet.project.id,
        startDate: new Date(),
        endDate: new Date(),
        duration: 0,
        progressing: true,
      });
      await TimeSheetApi.getTimeSheets(filterParams).then((res) => dispatch(setTimeSheets(res.data)));
    }
    setProgress(true);
  }

  useEffect(() => {
    let interval: number;
    if(progress) {
      interval = setInterval(() => {
        setSecondReading(preState => preState + 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      }
    }
  }, [progress]);

  const handleStopTiming = () => {
    setSecondReading(0);
    setProgress(false);
    const timesheet = timeSheets.filter(item => item.progressing)[0];
    if (timesheet) {
      TimeSheetApi.stopTime({
        id: timesheet.id,
        value: {
          ...timesheet,
          developer: timesheet.developer.id,
          project: timesheet.project.id,
          endDate: moment(timesheet.endDate).add(secondReading, 'second'),
          duration: secondReading,
          progressing: false
        },
        filterParams,
      }).then((res) => {
        setWorkTime(res.time);
        dispatch(setTimeSheets(res.data));
      });
    }
  };

  const excelData = useMemo(() => {
    return timeSheets.map((timeSheet) => (
      {
        ...timeSheet,
        developer: timeSheet.developer.name,
        project: timeSheet.project.name
      }
    ));
  }, [timeSheets]);

  const handleExportProject = (value:　string) => {
    switch (value) {
      case 'csv': {
        return CSVService.export({ excelData }, 'csv').then((res) => {
          saveAs(res, `Time sheet.csv`);
        });
      }
      case 'pdf': {
        return PdfService.export({
          elementId: 'print-pdf',
          name: 'Time sheet'
        })
      }
      default:
        return;
    }
  };

  return (
    <AppPage>
      <Box>
        <h1>View & edit timesheets</h1>
      </Box>
      <TabContainer>
        <Tab
          tabs={tabs}
          activeTabId={activeTabId}
          onChangeTab={(id: string) => setActiveTabId(id)}
        />
      </TabContainer>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='flex-start'
        flexDirection={['column', 'column', 'row']}
      >
        <Box
          display='flex'
          alignItems={xxsDown ? 'flex-start' : 'center'}
          pt='20px'
          flexDirection={xxsDown ? 'column' : 'row'}
          width={xxsDown ? '100%' : 'fit-content'}
        >
          <DateRange
            showActionButtons
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
          <Box ml={xxsDown ? 0 : 2} mt={xxsDown ? 2 : 0}>
            <Select defaultValue='cest' bordered={false}>
              <Option value='cest'>CEST</Option>
            </Select>
          </Box>
        </Box>
        <Box
          display='flex'
          alignItems='flex-end'
          mt={['20px', '20px', 0]}
          flexDirection={['column', 'row', 'column']}
          width='100%'
          justifyContent='space-between'
        >
          <Box
            display='flex'
            justifyContent='flex-end'
            alignItems='flex-end'
            width={xxsDown ? '100%' : 'fit-content'}
          >
            <Box mr={2} flex={1}>
              <Select
                label='Member'
                placeholder='Select a developer'
                value={filterParams.developer}
                onChange={(e: string) => setFilterParams({ ...filterParams, developer: e })}
                style={{ width: '10rem' }}
              >
                {
                  members.map((member) => (
                    <Option key={member.id} value={member.id as string}>{ member.name }</Option>
                  ))
                }
              </Select>
            </Box>
            <Button
              className='primary-outlined'
              onClick={() => setFilterOpen(true)}
            >
              Filters
            </Button>
          </Box>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            mt={1}
            ml={[0, '20px', 0]}
          >
            <Tooltip title='Start Timing' placement='bottom'>
              <Box mr={1} onClick={handleStartTiming}>
                <S.IconButton size='small'><BsFillSkipStartFill /></S.IconButton>
              </Box>
            </Tooltip>
            <Tooltip title='Stop Timing' placement='bottom'>
              <Box mr={1} onClick={handleStopTiming}>
                <S.IconButton size='small'><BsFillStopwatchFill /></S.IconButton>
              </Box>
            </Tooltip>
            <Button
              className='primary-contained'
              onClick={() => setOpenModal(true)}
            >
              Add time
            </Button>
            <Box ml={3}>
              <Select
                placeholder="Export"
                onChange={(e: string) => handleExportProject(e)}
              >
                <Option value="csv">CSV</Option>
                <Option value="pdf">PDF</Option>
              </Select>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box mt={2} overflow='auto'>
        <S.TimeLabel>
          {divideByDateRange(dateRange)} &nbsp;
          <span style={{ fontWeight: 600 }}>
            {timeStyle(workTime + secondReading).hour}
            :{timeStyle(workTime + secondReading).minute}
            :{timeStyle(workTime + secondReading).second}
          </span>
          <span style={{ marginLeft: 20 }}>
            {
              _.filter(projects, project => project.id === filterParams.project)[0]?.name
            }
          </span>
        </S.TimeLabel>
        {
          activeTabId === '1' &&
            <TimeLine dateRange={dateRange} />
        }
        {
          activeTabId === '2' &&
            <WeeklyTimesheet
              dateRange={dateRange}
              developer={filterParams.developer}
            />
        }
        {
          activeTabId === '3' &&
            <CalendarTimesheet
              dateRange={dateRange}
            />
        }
      </Box>
      <S.Drawer
        variant='temporary'
        anchor='right'
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        style={{ zIndex: 99 }}
      >
        <S.DrawerContainer>
          <Tabs defaultActiveKey='1'>
            <TabPane tab='Filters' key='1'>
              <Box mt={2}>
                <Select
                  label='Member'
                  placeholder='Select a developer'
                  value={filterParams.developer}
                  onChange={(e: string) => setFilterParams({ ...filterParams, developer: e })}
                >
                  {
                    members.map((member) => (
                      <Option key={member.id} value={member.id as string}>{ member.name }</Option>
                    ))
                  }
                </Select>
              </Box>
              <Box mt={2}>
                <Select
                  label='Project'
                  placeholder='Select a project'
                  value={filterParams.project}
                  onChange={(e: string) => setFilterParams({ ...filterParams, project: e })}
                >
                  {
                    projects.map((project) => (
                      <Option key={project.id} value={project.id as string}>{ project.name }</Option>
                    ))
                  }
                </Select>
              </Box>
              <Box mt={2}>
                <StyledSelect
                  placeholder='All types'
                  label='Time Type'
                  value={filterParams.timeType}
                  onChange={(e: string) => setFilterParams({ ...filterParams, timeType: e })}
                >
                  {
                    timeTypeOption.map((item) => (
                      <Option key={item.value} value={item.value}>{item.label}</Option>
                    ))
                  }
                </StyledSelect>
              </Box>
              <Box mt={2}>
                <StyledSelect
                  placeholder='All sources'
                  label='Source'
                  value={filterParams.sourceType}
                  onChange={(e: string) => setFilterParams({ ...filterParams, sourceType: e })}
                >
                  {
                    sourceTypeOption.map((item) => (
                      <Option key={item.value} value={item.value}>{item.label}</Option>
                    ))
                  }
                </StyledSelect>
              </Box>
              <Box mt={2}>
                <StyledSelect
                  placeholder='All levels'
                  label='Activity Level'
                  value={filterParams.levelType}
                  onChange={(e: string) => setFilterParams({ ...filterParams, levelType: e })}
                >
                  {
                    levelTypeOption.map((item) => (
                      <Option key={item.value} value={item.value}>{item.label}</Option>
                    ))
                  }
                </StyledSelect>
              </Box>
              <Box mt={2}>
                <Button
                  className='full-width'
                  onClick={handleClearFilter}
                >
                  Clear filters
                </Button>
              </Box>
            </TabPane>
          </Tabs>
          <IconButton
            className='close-icon'
            size='small'
            onClick={() => setFilterOpen(false)}
          >
            <Close />
          </IconButton>
        </S.DrawerContainer>
      </S.Drawer>
      <Box id='print-pdf' pt={3}>
        <TimeSheetTable
          setOpenModal={setOpenModal}
          filterParams={filterParams}
          setWorkTime={setWorkTime}
          secondReading={secondReading}
          setProgress={setProgress}
          setSecondReading={setSecondReading}
        />
      </Box>
      <TimeSheetModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        filterParams={filterParams}
        setWorkTime={setWorkTime}
        secondReading={secondReading}
      />
    </AppPage>
  )
}

export default ViewAndEdit;
