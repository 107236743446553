import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTeams } from "../../../../store/actions/team.action";
import { getTeams } from "../../../../store/selectors";
import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import Select, { Option } from "../../Select";
import { FormikProps } from "formik";
import { IProjectInterface } from "./index";
import * as S from "../../../../pages/Dashboard/Schedules/Calendar/styles";
import * as TeamApi from "../../../../apis/team.api";

const useStyles = makeStyles(() => ({
  multiSelect: {
    '& .ant-select-selector': {
      paddingLeft: '5px !important'
    }
  }
}));

export interface ITagRenderProps {
  label: string,
  closable: boolean,
  onClose: () => void
}

function tagRender(props: ITagRenderProps) {
  const { label, closable, onClose } = props;

  return (
    <S.Tag closable={closable} onClose={onClose}>
      {label}
    </S.Tag>
  );
}

const TeamTab = ({ ...props }: FormikProps<IProjectInterface>) => {
  const dispatch = useDispatch();
  const teams = useSelector(getTeams);
  const classes = useStyles();

  useEffect(() => {
    TeamApi.getAll('').then((res) => dispatch(setTeams(res)));
  }, []);

  return (
    <Box p={0.25} pb={8} display="flex" flexDirection="column" gridGap={15}>
      <Select
        label='Teams'
        mode='multiple'
        placeholder='Select teams'
        showArrow
        tagRender={tagRender}
        className={classes.multiSelect}
        isCustom={true}
        value={props.values.teams}
        onChange={(e: React.MouseEvent<HTMLDivElement>) => props.setFieldValue('teams', e)}
      >
        {
          teams.map((team) => (
            <Option key={team.id} value={team.id as string}>{team.name}</Option>
          ))
        }
      </Select>
    </Box>
  );
};

export default TeamTab;
