import React from "react";
import { DiagramContainer } from "../../style";

const scales = [100, 75, 50, 25, 0];

const Diagram = ({ average }) => {
  return (
    <DiagramContainer>
      <div style={{ height: '50%', backgroundColor: '#31c48d', borderTopLeftRadius: 3, borderTopRightRadius: 3 }} />
      <div style={{ height: '30%', backgroundColor: '#ffac51' }} />
      <div style={{ height: '20%', backgroundColor: '#f05252', borderBottomLeftRadius: 3, borderBottomRightRadius: 3 }} />
      {
        scales.map((scale, index) => (
          <span key={scale + index} style={{ top: `${(100 - scale) - 7}%` }}>{scale}%_</span>
        ))
      }
      <div className='diagram_bar' style={{ height: `${average}%` }} />
    </DiagramContainer>
  )
}
export default Diagram;
