import styled from "styled-components";

export const ForgotContainer = styled.div`
  display: flex;
  background-color: #f7f7f7;
  height: 100%;

  .password_container {
    width: 35rem;
    height: 25rem;
    margin: 10rem auto 0;
    background-color: white;
    border-radius: 1rem;
    padding: 0 3rem;
    box-shadow: 1px 2px 6px 2px lightgray;

    @media(max-width: 320px) {
      height: 30rem;
    }

    .title {
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      margin-top: 2rem;
      margin-bottom: 0.8rem;
    }

    .description {
      text-align: center;
      font-size: 17px;
    }

    Form {
      position: relative;
      margin-top: 2.5rem;

      .alert_message {
        position: absolute;
        top: -38px;
        left: 50%;
        width: 10rem;
        transform: translate(-50%);
        z-index: 1;
      }

      .reset-button {
        width: 10rem;
        margin: 2.5rem auto 2rem;
        height: 2.5rem !important;
      }

      .foot-word {
        text-align: center;
        font-size: 16px;

        span {
          font-weight: 600;
          cursor: pointer;
          color: #1890ff;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
`
