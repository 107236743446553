import styled from 'styled-components'

const Container = styled.section`
  .container {
    position: relative;
    padding-top: 78px;
    padding-bottom: 48px;

    ${props => props.theme.breakpoints.down('md')} {
      padding-top: 40px;
    }
  }
`

const TopSection = styled.div`
  display: flex;

  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }

  .left-container {
    width: 530px;

    ${props => props.theme.breakpoints.down('md')} {
      width: 400px;
      margin-right: 15px;
    }

    ${props => props.theme.breakpoints.down('sm')} {
      width: 100%;
    }

    .message-content {
      max-width: 500px;
      padding-bottom: 10px;

      h1 {
        font: 60px/65px 'Proxima Nova Bold';
        color: ${(props) => props.theme.palette.text.primary};
        margin-bottom: 24px;

        span {
          color: ${(props) => props.theme.colors.blue};
        }

        ${props => props.theme.breakpoints.down('sm')} {
          font-size: 40px;
          line-height: 1;
        }
      }

      p {
        font: 20px/30px 'Lato-Bold';
        margin-bottom: 32px;
      }

      input {
        margin-top: 16px;
      }

      a {
        font-weight: 500;
        color: ${(props) => props.theme.colors.dark};

        &:hover {
          color: ${(props) => props.theme.colors.blue};
        }
      }

      .no-required-text {
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;

        span {
          color: rgba(37, 48, 83, 0.7);
          font-family: 'Lato-Regular', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.25;
          display: block;
        }

        &::before, &::after {
          background: #E4E6EA;
          box-sizing: border-box;
          content: '';
          display: block;
          height: 1px;
          width: 100%;
        }

        &::before {
          margin-right: 16px;
        }

        &::after {
          margin-left: 16px;
        }
      }

      ${props => props.theme.breakpoints.down('sm')} {
        max-width: unset;
        text-align: center;
      }
    }
  }

  .logos-container {
    align-self: flex-end;
    flex: 1;

    p {
      font: 16px 'Lato-Regular';
      color: #666E86;
      margin: 0 0 8px;
    }

    .logos {
      display: flex;
      align-items: center;

      .logo-item {
        flex: 1 0 auto;

        img {
          margin-right: 16px;

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    ${props => props.theme.breakpoints.down('sm')} {
      margin-top: 20px;
      width: 100%;
      text-align: center;

      p {
        text-align: center;
      }
    }

    ${props => props.theme.breakpoints.down(550)} {
      .logos {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        margin-top: 40px;
      }
    }
  }
`

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  max-height: 558px;
  box-sizing: border-box;
  left: calc(50% - 80px);
  width: 700px;
  overflow: hidden;
  border-right: 1px solid white;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    border-top: 558px solid transparent;
    border-left: 170px solid white;
  }

  ${props => props.theme.breakpoints.down('lg')} {
    width: 650px;
    height: 100%;
    left: calc(50% - 60px);
    margin: 0;
    position: absolute;
    top: 0;
  }

  ${props => props.theme.breakpoints.down('md')} {
    width: 550px;
    height: 100%;
    left: calc(50% - 60px);
    margin: 0;
    position: absolute;
    top: 0;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    position: relative;
    width: 100%;
    left: 0;
    border-radius: 0;
    margin-bottom: 30px;
    overflow: visible;

    video {
      width: 100%;
    }

    &::after {
      border: none;
    }
  }
`

const PlayButton = styled.button`
  position: absolute;
  width: 100px;
  height: 100px;
  margin: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  padding: 0;
  background-color: ${(props) => props.theme.colors.blue};
  color: white;
  border-radius: 50px;
  border: none;
  z-index: 10;
  cursor: pointer;
  transition: all ease 0.3s;

  svg {
    width: 35px;
    height: 35px;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    bottom: auto;
    left: 28px;
    right: auto;
    top: calc(50% - (124px/2));
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    position: absolute;
    bottom: -20px;
    right: 30px;
    width: 60px;
    height: 60px;

    ::before {
      border-width: 8px !important;
      left: -8px !important;
      top: -8px !important;
      width: calc(100% + 16px) !important;
      height: calc(100% + 16px) !important;
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.dark};
  }

  ::before {
    left: -12px;
    top: -12px;
    width: calc(100% + 24px);
    height: calc(100% + 24px);
    border: 12px solid rgba(41,77,255,0.2);
    box-shadow: 0 22px 24px rgba(0,0,0,0.1);
    content: '';
    display: block;
    border-radius: 50%;
    box-sizing: border-box;
    position: absolute;
    z-index: -1;
  }
`

export {
  Container,
  TopSection,
  VideoContainer,
  PlayButton
}
