import Http from './http'
import { IRegisterFormData } from '../shared/interfaces'

export const login = (email: string, password: string) => {
  return Http.post('/auth/login', { email, password });
}

export const register = (data: IRegisterFormData) => {
  return Http.post('/auth/register', data);
}

export const getAdmin = () => {
  return Http.get('/auth/admin');
}

export const getConfirmation = (token: string) => {
  return Http.get(`/auth/get-confirmation/${token}`);
}

export const resendEmail = (token: string) => {
  return Http.post('/auth/resend-email', { token });
}

export const verifyEmail = (token: string) => {
  return Http.get(`/auth/verify/${token}`);
}

export const forgotPassword = (email: string) => {
  return Http.post('/auth/forgot-password', { email });
}

export const resetPassword = (email: string, password: string) => {
  return Http.post('/auth/reset-password', { email, password });
}
