import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router";
import { ArrowBack, EmailOutlined } from "@material-ui/icons";
import { Modal } from "antd";
import Button from '../../../components/Dashboard/Button';
import * as S from './styles';
import * as AuthApi from '../../../apis/auth.api';

const EmailConfirmation = () => {
  const history = useHistory();
  const param = useParams() as { token: string };
  const [email, setEmail] = useState<string>('');

  useEffect(() => {
    AuthApi.getConfirmation(param.token)
      .then((res) => setEmail(res))
      .catch((err) => Modal.error({ title: err.message }));
  }, []);

  const handleResendEmail = () => {
    AuthApi.resendEmail(param.token).then((res) => setEmail(res));
  };

  return (
    <S.Container>
      <div className="left-container"></div>
      <div className="right-container">
        <div className="confirm-content">
          <div className="Email-icon">
            <EmailOutlined fontSize="medium" />
          </div>
          <p className="title">Verify your email</p>
          <div className="description">
            You`re in.&nbsp;
            An email has been sent to <a href={`/verify/${param.token}`}>{ email }.</a>&nbsp;
            Hit<br/>
            confirm and you`ll be ready to start working.<br/>
            <p>Don`t see an email?</p>
          </div>
          <div className="button-group">
            <Button className="primary-contained" onClick={handleResendEmail}>Resent it</Button>
            <div className="back-button" onClick={() => history.push('/auth/sign-in')}>
              <ArrowBack fontSize="small" />
              Back to sign in
            </div>
          </div>
        </div>
      </div>
    </S.Container>
  )
}
export default EmailConfirmation;
