import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { setFetchMe } from "../../../../store/actions/member.action";
import { onSetStep } from "../../../../store/actions/gettingStart.action";
import { DetailLayout } from "../style";
import { IconButton } from "../../../../pages/Dashboard/Reports/styles";
import { ArrowForward } from "@material-ui/icons";
import { CardContentOptions } from "../CardContent";
import SubscribeToReport, { ISubscribeOptionInterface, subscribeOptions } from "./SubscribeToReport";
import ReviewTeamInsights from "./ReviewTeamInsights";
import ApproveTimesheet from "./ApproveTimesheet";
import PickProject from "./PickProject";
import * as OrganizationApi from "../../../../apis/organization.api";
import _ from "lodash";

interface IProps {
  step: number,
  setStep: (value: number) => void,
}

const DetailContainer: FC<IProps> = ({
 step,
 setStep
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [ options, setOptions ] = useState<ISubscribeOptionInterface[]>(subscribeOptions);
  const [pickedProjects, setPickedProjects] = useState<string[]>([]);

  const detailComponents = (value: number) => {
    switch (value) {
      case 1:
        return <SubscribeToReport options={options} setOptions={setOptions} />
      case 2:
        return <ReviewTeamInsights />
      case 3:
        return <ApproveTimesheet />
      case 4:
        return <PickProject selectedOptions={pickedProjects} setSelectedOptions={setPickedProjects} />
      default:
        return <></>
    }
  };

  const NextItem = (step: number) => {
    const details = [] as Array<{ order: number, menu: string }>;
    _.map(CardContentOptions, 'details').map(
      (items) =>
        items.map((item) => details.push(item))
    );

    return details.filter((item) => item.order === step + 1)[0]?.menu;
  };

  const handleContinue = () => {
    setStep(step + 1);
    dispatch(onSetStep(step + 1));

    switch (step) {
      case 1: {
        OrganizationApi.subscribeToReport({ id, options }).then((res) => dispatch(setFetchMe(res)));
        break;
      }
      case 4: {
        OrganizationApi.pickProjects({ id, pickedProjects }).then((res) => dispatch(setFetchMe(res)));
        break;
      }
      default:
        return;
    }
  };

  return (
    <DetailLayout>
      {
        step < 7 && (
          <div className='continue_ button'>
            <IconButton onClick={handleContinue}>
              <ArrowForward fontSize='small' style={{ color: '#2aa7ff' }} />
              <span>Continue to { NextItem(step) }</span>
            </IconButton>
          </div>
        )
      }
      { detailComponents(step) }
    </DetailLayout>
  )
}
export default DetailContainer;
