import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Modal as MuiModal, ModalProps, Box, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import styled from 'styled-components'

const Container = styled(Box)`
  position: relative;
  background-color: white;
  margin-top: 20px;
  border-radius: 10px;
  min-width: 500px;
  max-width: 660px;
  padding: 30px;
  overflow: auto;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h3 {
      font-size: 20px;
    }
  }

  .close-btn {
    position: absolute;
    right: 20px;
    top: 20px;

    svg {
      font-size: 20px;
      color: gray;
    }
  }

  ${props => props.theme.breakpoints.down('xs')} {
    min-width: unset;
    padding: 15px;
    width: 90%;
  }
`

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
  }),
);

interface Props extends ModalProps {
  onClose: () => void
}

const Modal = (props: Props) => {
  const classes = useStyles()
  const { open, onClose, children, title } = props

  return (
    <MuiModal
      className={classes.modal}
      open={open}
      onClose={onClose}
      style={{ zIndex:　100 }}
    >
      <Container>
        <div className='header'>
          <h3>{title}</h3>
          <IconButton
            className='close-btn'
            size='small'
            onClick={onClose}
          ><Close /></IconButton>
        </div>
        <div className='content'>
          { children }
        </div>
      </Container>
    </MuiModal>
  )
}

export default Modal