import React, { useState } from 'react'
import { useSelector } from "react-redux";
import { getMembers, getProjects } from "../../../store/selectors";
import { Close } from '@material-ui/icons'
import { Select as ASelect } from 'antd'
import { Tabs } from '../../../components/Dashboard/CommonStyled'
import { Box, IconButton } from '@material-ui/core'
import { levelTypeOption, sourceTypeOption, StyledSelect, timeTypeOption } from "../Activity/Apps";
import Select from '../../../components/Dashboard/Select'
import Button from '../../../components/Dashboard/Button'
import * as S from '../Activity/Screenshots/styles'

const { TabPane } = Tabs
const { Option } = ASelect

interface Props {
  open: boolean
  onClose: () => void
}

interface IFilterParams {
  developer: string | null;
  project: string | null;
  timeType: string | null;
  sourceType: string | null;
  levelType: string | null;
}

const FilterSideMenu = ({open, onClose}: Props) => {
  const [filterParams, setFilterParams] = useState<IFilterParams>({
    developer: null,
    project: null,
    timeType: null,
    sourceType: null,
    levelType: null,
  });
  const members = useSelector(getMembers);
  const projects = useSelector(getProjects);

  const handleClearFilterParams = () => {
    setFilterParams({
      ...filterParams,
      developer: null,
      project: null,
      timeType: null,
      sourceType: null,
      levelType: null
    });
  }

  return (
    <S.Drawer
      variant='temporary'
      anchor='right'
      open={open}
      onClose={onClose}
      style={{ zIndex: 99 }}
    >
      <S.DrawerContainer>
        <Tabs defaultActiveKey='1'>
          <TabPane tab='Filters' key='1'>
            <Box mt={2}>
              <Select
                label='Member'
                placeholder='Select a member'
                value={filterParams.developer}
                onChange={(e: string) => setFilterParams({ ...filterParams, developer: e })}
              >
                {
                  members.map((member) => (
                    <Option key={member.id} value={member.id as string}>{member.name}</Option>
                  ))
                }
              </Select>
            </Box>
            <Box mt={2}>
              <Select
                label='Project'
                placeholder='Select a project'
                value={filterParams.project}
                onChange={(e: string) => setFilterParams({ ...filterParams, project: e })}
              >
                {
                  projects.map((project) => (
                    <Option key={project.id} value={project.id as string}>{project.name}</Option>
                  ))
                }
              </Select>
            </Box>
            <Box mt={2}>
              <StyledSelect
                placeholder='All types'
                label='Time Type'
                value={filterParams.timeType}
                onChange={(e: string) => setFilterParams({ ...filterParams, timeType: e })}
              >
                {
                  timeTypeOption.map((item) => (
                    <Option key={item.value} value={item.value}>{item.label}</Option>
                  ))
                }
              </StyledSelect>
            </Box>
            <Box mt={2}>
              <StyledSelect
                placeholder='All sources'
                label='Source'
                value={filterParams.sourceType}
                onChange={(e: string) => setFilterParams({ ...filterParams, sourceType: e })}
              >
                {
                  sourceTypeOption.map((item) => (
                    <Option key={item.value} value={item.value}>{item.label}</Option>
                  ))
                }
              </StyledSelect>
            </Box>
            <Box mt={2}>
              <StyledSelect
                placeholder='All levels'
                label='Activity Level'
                value={filterParams.levelType}
                onChange={(e: string) => setFilterParams({ ...filterParams, levelType: e })}
              >
                {
                  levelTypeOption.map((item) => (
                    <Option key={item.value} value={item.value}>{item.label}</Option>
                  ))
                }
              </StyledSelect>
            </Box>
            <Box mt={2}>
              <Button className='full-width' onClick={handleClearFilterParams}>Clear filters</Button>
            </Box>
          </TabPane>
        </Tabs>
        <IconButton
          className='close-icon'
          size='small'
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </S.DrawerContainer>
    </S.Drawer>
  )
}

export default FilterSideMenu;