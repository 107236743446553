import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { setMembers } from "../../../../store/actions/member.action";
import { setExpenses } from "../../../../store/actions/expense.action";
import { getExpenses, getLoading } from "../../../../store/selectors";
import { makeStyles } from "@material-ui/styles";
import { saveAs } from "file-saver";
import { statusOptions } from "../Invoices";
import { Box, Grid } from '@material-ui/core'
import { Search } from "@material-ui/icons";
import { CSVService } from "../../../../service/csv.service";
import { PdfService } from "../../../../service/pdf.service";
import Select, { Option } from "../../../../components/Dashboard/Select";
import Button from '../../../../components/Dashboard/Button'
import AddExpenseModal from "../../../../components/Dashboard/Expense";
import ExpenseTable from "./ExpenseTable";
import Input from "../../../../components/Dashboard/Input";
import DateRange from "../../../../components/Dashboard/DateRange";
import clsx from "clsx";
import ExpensesImg from '../../../../assets/images/dashboard/expenses.png'
import moment from "moment/moment";
import * as S from './styles';
import * as UseApi from '../../../../apis/user.api';
import * as ExpenseApi from '../../../../apis/expense.api';

const useStyles = makeStyles(() => ({
  select: {
    "width": '50%',
    '& .label': {
      fontSize: 20
    }
  },
  dateRange: {
    '& .show-date': {
      width: 500
    }
  },
  button: {
    padding: '7px 22px !important',
    borderColor: '#18d06b !important',
    backgroundColor: '#18d06b !important',
    marginLeft: 30,
  },
  export: {
    width: 100,
    marginLeft: 20
  }
}));

const Expenses = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const expenses = useSelector(getExpenses);
  const loading = useSelector(getLoading);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [statusExpense, setStatusExpense ] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number>(0);
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf('week').toDate(),
    endDate: moment().endOf('week').toDate()
  });

  useEffect(() => {
    UseApi.getAll().then((res) => dispatch(setMembers(res)));
  }, []);

  useEffect(() => {
    ExpenseApi.getAll({ searchValue, dateRange }).then((res) => {
      dispatch(setExpenses(res.data));
      setTotalCount(res.count);
    });
  }, [searchValue, dateRange]);

  const handleStatusApply = async () => {
    if (selectedRow.length > 0) {
      await ExpenseApi.applyStatus({ ids: selectedRow, status: statusExpense });
      ExpenseApi.getAll({ searchValue, dateRange }).then((res) => dispatch(setExpenses(res.data)));
    }
  };

  const excelData = useMemo(() => {
    return expenses.map((expense) => (
      {
        ...expense,
        payer: expense.payer.name,
        member: expense.member.name,
        project: expense.project.name
      }
    ));
  }, [expenses]);

  const handleExportExcel = (value: string) => {
    switch (value) {
      case 'csv': {
        return CSVService.export({ excelData }, 'csv').then((res) => {
          saveAs(res, `expense.csv`);
        });
      }
      case 'pdf': {
        return PdfService.export({
          elementId: 'print-pdf',
          name: 'expense'
        });
      }
      default:
        return;
    }
  };

  return (
    <S.Container>
      {
        totalCount > 0 ? (
          <>
            <Box mb={2}>
              <h1>Expense</h1>
            </Box>
            <Grid container spacing={2}>
              <Grid item lg={6} xs={12}>
                <Input
                  className='search'
                  prefix={<Search />}
                  placeholder="Search members"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </Grid>
              <Grid item lg={6} xs={12} style={{ display: 'flex' }}>
                <Box marginLeft='auto'>
                  <DateRange
                    showActionButtons={true}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                  />
                </Box>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Box display='flex' alignItems='self-end'>
                  <Select
                    label='Status'
                    placeholder='Select a payment status'
                    className={classes.select}
                    onChange={(e: string) => setStatusExpense(e)}
                  >
                    {
                      statusOptions.map((option, index) => (
                        <Option key={index} value={option.value}>
                          {option.label}
                        </Option>
                      ))
                    }
                  </Select>
                  <Button
                    className={clsx(classes.button, 'primary-contained')}
                    onClick={handleStatusApply}
                  >
                    Apply
                  </Button>
                </Box>
              </Grid>
              <Grid item lg={6} xs={12} style={{ display: 'flex', alignItems: 'self-end' }}>
                <Box display='flex' marginLeft='auto'>
                  <Button
                    className='primary-contained'
                    onClick={() => setOpenModal(true)}
                  >
                    Create expense
                  </Button>
                  <Select
                    placeholder="Export"
                    className={classes.export}
                    onChange={(e: string) => handleExportExcel(e)}
                  >
                    <Option value="csv">CSV</Option>
                    <Option value="pdf">PDF</Option>
                  </Select>
                </Box>
              </Grid>
            </Grid>
            <Box id='print-pdf' mt={5}>
              <ExpenseTable
                setSelectedRow={setSelectedRow}
                searchValue={searchValue}
                dateRange={dateRange}
                setOpenModal={setOpenModal}
                setTotalCount={setTotalCount}
              />
            </Box>
          </>
        ) : !loading && (
          <Box display='flex' flexDirection='column' alignItems='center'>
            <h1>Expenses</h1>
            <p>Keep track of all your expenses. You can upload receipts, categorize expenses, connect them to projects or client budgets, and even include them in invoices.</p>
            <img src={ExpensesImg} alt='Expenses' style={{ width: '618px', height: '318px' }}/>
            <Box mt={5}>
              <Button
                className='primary-contained'
                onClick={() => setOpenModal(true)}
              >
                Got it!
              </Button>
            </Box>
          </Box>
        )
      }
      <AddExpenseModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        searchValue={searchValue}
        dateRange={dateRange}
        setTotalCount={setTotalCount}
      />
    </S.Container>
  )
}

export default Expenses;
