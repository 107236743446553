import { Box } from "@material-ui/core";
import styled, { css } from "styled-components";

export const MenuContainer = styled(Box)<{ open: boolean, step: number }>`
  padding: 1rem 1.25rem 1rem 1.75rem;
  color: #617083;
  cursor: pointer;

  &:hover {
    background-color: #e5edff;
    color: #40a9ff;
  }

  ${props => props.open && css`
    background-color: #e5edff;
    color: #40a9ff;
  `}

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-size: 15px;
      font-weight: 600;
    }
  }
  .progress_items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 5px;
    margin-top: 0.7rem;

    .progress_item {
      width: 3.5rem;
      height: 5px;
      border-radius: 3px;
      background-color: #d1d4da;
      overflow: hidden;

      div {
        height: 100%;
      }
    }
  }
`

export const GetStartContainer = styled(Box)`
  padding: 2rem;
  background-color: #f9fafb;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.125);

  .title {
    font-size: 18px;
    font-weight: 500;
  }

  .description {
    color: #617083;
    margin-top: 0.7rem;
    margin-bottom: 1rem;
    font-size: 13px;
  }
`

export const CardContainer = styled(Box)`
  padding: 1rem;
  background-color: white;
  border-radius: 0.6rem;
  border: 1px solid #d1d4d5;
  height: 196px;
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 600;

    .confirmed {
      color: #919aa6;
      letter-spacing: 1px;
    }
  }

  .manage_description {
    color: #919aa6;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    font-size: 12px;
    font-weight: 500;
  }

  .steps_container {
    margin-top: 1rem;
  }
`

export const Step = styled(Box)`
  display: flex;
  align-items: start;
  margin-top: 8px;
  cursor: pointer;

  div {
    position: relative;

    .link_line {
      position: absolute;
      width: 1px;
      height: 19px;
      background-color: green;
      left: 48%;
      top: 21px;
    }
  }

  span {
    margin-left: 0.5rem;
    margin-top: 2px;
    white-space: nowrap;
  }
`

export const DetailLayout = styled(Box)`
  margin: 1rem 2rem;
  position: relative;

  .continue_ button {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    top: 0;
    font-size: 13px;

    span {
      margin-left: 3px;

      @media (max-width: 500px) {
        display: none;
      }
    }
  }
`

export const Subscribe = styled(Box)`
  .sub_title {
    font-size: 1rem;
    font-weight: 600;
    color: black;
  }

  .sub_description {
    font-size: 13px;
    color: #617083;
    margin-top: 0.8rem;
  }

  .card_container {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;

    @media (max-width: 900px) {
      display: block;
    }

    .sub_card {
      position: relative;
      max-width: 300px;
      height: 200px;
      border-radius: 0.8rem;
      padding: 1.2rem;
      border: 1.5px solid #d1d4d5;
      margin-right: 1.5rem;
      overflow: hidden;

      @media (max-width: 900px) {
        margin: auto auto 1.5rem;
      }

      .card_title {
        font-weight: 600;
        color: black;
      }

      .card_description {
        margin-top: 1.5rem;
        color: #617083;
      }

      .card_subscribe_button {
        position: absolute;
        display: inline-block;
        background-color: #2aa7ff;
        color: white;
        padding: 5px 18px;
        font-weight: 600;
        font-size: 13px;
        border: 1px solid #2aa7ff;
        border-radius: 5px;
        cursor: pointer;
        right: 1.3rem;
        bottom: 1.3rem;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`

export const ReviewTeam = styled(Box)`
  .team_title {
    font-size: 1rem;
    font-weight: 600;
    color: black;
  }

  .team_description {
    font-size: 13px;
    color: #617083;
    margin-top: 0.8rem;
  }

  .team_member_select {
    margin-top: 1rem;
    min-width: 350px;

    @media (max-width: 400px) {
      min-width: 100% !important;
    }
  }

  .no_data {
    display: flex;
    border: 1.5px solid #d1d4d5;
    border-radius: 5px;
    height: 19rem;

    img {
      margin: auto;
    }
  }
`

export const ApproveContainer = styled(Box)`
  .approve_title {
    font-size: 1rem;
    font-weight: 600;
    color: black;
  }

  .approve_description {
    font-size: 13px;
    color: #617083;
    margin-top: 0.8rem;
  }

  .team_member_select {
    margin-top: 1rem;
    min-width: 350px;

    @media (max-width: 400px) {
      min-width: 100% !important;
    }
  }

  .approve_button {
    color: #2aa7ff;
    margin-top: 1rem;
  }
`

export const PickProjectContainer = styled(Box)`
  .pick_project_title {
    font-size: 1rem;
    font-weight: 600;
    color: black;
  }

  .pick_project_description {
    font-size: 13px;
    color: #617083;
    margin-top: 0.8rem;
  }

  .pick_project_items {
    width: 50%;
    margin-top: 1.5rem;

    @media(max-width: 900px) {
      width: 80%;
    }

    .pick_project_item {
      display: flex;
      align-items: center;
      border: 1.5px solid #617083;
      color: #617083;
      padding: 0.5rem 0.8rem;
      border-radius: 1rem;
      cursor: pointer;

      &:hover {
        background-color: #eaf6ff;
        border-color: #2c88f4;
        color: #2c88f4;
      }

      span {
        font-size: 12px;
        white-space: nowrap;
        font-weight: 500;
      }

      .add_icon {
        margin-right: 3px;
        font-size: 15px;
      }

      .close_icon {
        margin-left: 3px;
        font-size: 15px;
      }
    }
  }
`

export const ViewScreenshot = styled(Box)`
  display: inline-block;
  color: #2c88f4;
  box-shadow: 0 7px 7px 1px lightgrey;
  padding: 0.2rem 0.6rem;
  border-radius: 0.8rem;
  font-size: 13px;
  cursor: pointer;
`

export const ActivityContainer = styled(Box)`
  width: 100%;

  .activity_title {
    color: #617083;
    font-size: 11px;
    font-weight: 500;

    span {
      margin-right: 4px;
    }
  }

  .diagram_vertical_container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    .average_number {
      font-weight: 600;
      color: black;
      font-size: 20px;
    }

    p {
      font-size: 11px;
    }
  }
`

export const DiagramContainer = styled(Box)`
  position: relative;
  width: 1rem;
  height: 10rem;
  margin-top: 0.7rem;

  span {
    position: absolute;
    color: #98a7c2;
    font-size: 9px;
    right: 18px;
  }

  .diagram_bar {
    position: absolute;
    width: 2px;
    background-color: black;
    border-radius: 1px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
`

export const ProgressBarContainer = styled(Box)`
  display: flex;
  align-items: center;
  transform: translate(0, 22px);

  span {
    font-size: 11px;
  }

  .progress_bar {
    position: relative;
    height: 0.5rem;
    width: 100%;
    background-color: #f3f4f6;
    border-radius: 2px;
    margin-right: 3px;
    margin-left: 3px;

    .scale_bar {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: -17px;

      span {
        font-size: 11px;
        height: 14px;
      }

      div {
        margin-left: 9px;
      }
    }
  }
`

export const AppUrlContainer = styled(Box)`
  padding: 0.5rem 0.6rem;
  width: 100%;

  .app_url_title {
    color: #617083;
    font-size: 11px;
    font-weight: 500;

    span {
      margin-right: 4px;
    }
  }

  .progress_bar {
    height: 0.5rem;
    border-radius: 10px;
    margin-top: 4px;
  }
`

export const WorkTimeContainer = styled(Box)`
  width: 100%;

  .work_time_title {
    color: #617083;
    font-size: 11px;
    font-weight: 500;

    span {
      margin-right: 4px;
    }
  }

  .work_time_diagram_container {
    margin-top: 2rem;

    .list_circle {
      width: 7px;
      height: 7px;
      border-radius: 50%;
    }

    .list_menu {
      font-size: 12px;
      margin-left: 5px;
      color: #919aa6;
    }
  }
`

export const HorizontalDiagramContainer = styled(Box)`
  display: flex;
  position: relative;
  height: 1rem;
  width: 100%;

  .scale {
    position: absolute;
    display: flex;
    flex-direction: column;
    color: #8795ae;
    top: 18px;
  }
`
