import styled from "styled-components";

const Container = styled.div`
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;

  .left-container {
    width: 50%;
    background: url(https://server-assets.hubstaff.com/62899462f8/illustrations/customer_retention_illustration-600-cc50f04f2444ff659ec26fca553b5547d05575e87c6060131f9d8e821d391d76.png) no-repeat center center;
  }

  .right-container {
    display: flex;
    width: 50%;

    .confirm-content {
      margin: 15rem auto 0;
      text-align: center;

      .Email-icon {
        display: inline-block;
        color: #27a7ff;
        background-color: #ebf4ff;
        width: 2.5rem;
        height: 2.5rem;
        text-align: center;
        border-radius: 50%;
        padding-top: 7px;
      }

      .title {
        font-family: "Proxima Nova Bold", sans-serif;
        font-size: 30px;
      }

      .description {
        color: #9ca4b0;
        margin-bottom: 1rem;
        p {
          margin-top: 1rem;
        }
      }

      .button-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: 15px;

        .back-button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          margin-top: 1rem;
          color: #7b88a8;
          font-weight: 500;
          cursor: pointer;
          &:hover {
            color: #2e3037;
          }
        }
      }
    }
  }
`
export { Container };
