import React, { useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { ResetContainer} from "./style";
import { Form, Formik, FormikProps } from "formik";
import { Button } from "../../../components/Common/Button";
import { IResetPassword } from "../../../shared/interfaces";
import Input from "../../../components/Common/Input";
import * as Yup from "yup";
import * as AuthApi from "../../../apis/auth.api";

const ResetPassword = () => {
  const history = useHistory();
  const { email: resetEmail } = useParams();

  const initialValues = useMemo(() => {
    return {
      email: resetEmail,
      password: '',
      confirmPassword: ''
    }
  }, []);

  const resetPasswordValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email is not valid.')
      .required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(/[a-zA-Z]/, 'Password should contain at least one alphabet.')
      .matches(/\d/, 'Password should contain as least one number')
      .min(6, 'Password should contain at least 6 characters.'),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .min(6, 'Password should contain at least 6 characters.')
      .oneOf([Yup.ref('password')], 'Must be equal'),
  });

  const resetPassword = (value: IResetPassword, { setSubmitting }) => {
    const { email, password } = value;
    AuthApi.resetPassword(email, password)
      .then((res) => {
        if (res?.id) {
          history.push('/auth/sign-in');
        }
      })
      .catch(() => {
        setSubmitting(true);
      });
  };

  return (
    <ResetContainer>
      <div className='reset_container'>
        <p className='title'>Reset Password</p>
        <Formik
          initialValues={initialValues}
          validationSchema={resetPasswordValidationSchema}
          onSubmit={resetPassword}
        >
          {(props: FormikProps<IResetPassword>) => (
            <Form onSubmit={props.handleSubmit}>
              <div className='form-control'>
                <span>Email</span>
                <Input
                  name='email'
                  type='email'
                  placeholder='example@email.com'
                  value={props.values.email}
                  onChange={props.handleChange}
                  error={props.errors.email && props.touched.email ? props.errors.email : ''}
                />
              </div>
              <div className='form-control'>
                <span>Password</span>
                <Input
                  name='password'
                  type='password'
                  value={props.values.password}
                  onChange={props.handleChange}
                  error={props.errors.password && props.touched.password ? props.errors.password : ''}
                />
              </div>
              <div className='form-control'>
                <span>Confirm Password</span>
                <Input
                  name='confirmPassword'
                  type='password'
                  value={props.values.confirmPassword}
                  onChange={props.handleChange}
                  error={props.errors.confirmPassword && props.touched.confirmPassword ? props.errors.confirmPassword : ''}
                />
              </div>
              <div style={{ display: 'flex' }}>
                <Button
                  htmlType='submit'
                  disabled={!props.isValid}
                  loading={props.isSubmitting}
                  className='reset-button'
                >
                  Save & Continue
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ResetContainer>
  )
}
export default ResetPassword;
