import Http from "./http";
import { ICheckNote } from "../pages/Dashboard/Notifications";
import { INotificationProps } from "../components/Dashboard/Notification";

export const create = (value: INotificationProps) => {
  return Http.post('/notification/create', value)
}

export const getAll = () => {
  return Http.get('/notification');
}

export const getById = (id: string) => {
  return Http.get(`/notification/${id}`);
}

export const updateById = ({ id, value }) => {
  return Http.patch(`/notification/${id}/update`, value);
}

export const checkedState = (value: ICheckNote[]) => {
  return Http.patch('/notification/checked', value);
}

export const deleteById = (id: string) => {
  return Http.delete(`/notification/${id}`);
}
