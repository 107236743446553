import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { getAccount, getTeams } from "../../../store/selectors";
import { setFetchMe } from "../../../store/actions/member.action";
import { setTeams } from "../../../store/actions/team.action";
import { Form, Formik, FormikProps } from "formik";
import { Box, Grid } from "@material-ui/core";
import Select, { Option } from "../../../components/Dashboard/Select";
import AvatarBox from "./AvatarBox";
import CustomSelect from "../../../components/Common/Select/Select";
import Button from "../../../components/Dashboard/Button";
import Input from "../../../components/Dashboard/Input";
import * as S from "./styles";
import * as Yup from 'yup';
import * as UserApi from '../../../apis/user.api';
import * as TeamApi from '../../../apis/team.api';

interface IAccountProps {
  name: string;
  email: string;
  password: string;
  role: string;
  team: {
    value: string;
    label: string;
  }
}

const Account = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const account = useSelector(getAccount);
  const teams = useSelector(getTeams);

  useEffect(() => {
    TeamApi.getAll('').then((res) => dispatch(setTeams(res)));
  }, []);

  const teamOptions = useMemo(() => {
    return teams.map((team) => (
      {
        value: team.id,
        label: team.name
      }
    ))
  }, [teams]);

  const initialValues = useMemo(() => {
    return {
      name: account?.name || '',
      email: account?.email || '',
      password: '',
      role: account?.role || '',
      team: account?.team as any || null
    };
  }, [account]);

  const formSchema = Yup.object().shape({
    name:　Yup.string().required('Name is required'),
    email: Yup.string().email('Email is not valid').required('Email is required'),
    password: Yup.string()
      .required('Password is required.')
      .matches(/[a-zA-Z]/, 'Password should contain at least one alphabet.')
      .matches(/\d/, 'Password should contain at least one number.')
      .min(6, 'Password should contain at least 6 characters.'),
    role: Yup.string().required(),
    team: Yup.object().nullable(),
  });

  const handleSubmit = (value: IAccountProps) => {
    UserApi.updateAccount({
      ...value,
      team: value.team?.value || null
    }).then((res) => dispatch(setFetchMe(res)));
  };

  const handleGoOrganization = (props: FormikProps<IAccountProps>) => {
    if (!!props.values.password) {
      history.push('/organizations/wizard');
      handleSubmit(props.values);
    }
  };

  const handleClear = (props: FormikProps<IAccountProps>) => {
    props.setFieldValue('team', null);
  };

  return (
    <S.Container>
      <Box>
        <h1>My account</h1>
      </Box>
      {
        account && (
          <Formik
            initialValues={initialValues}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {(props: FormikProps<IAccountProps>) => (
              <Form onSubmit={props.handleSubmit}>
                <Box my={3}>
                  <AvatarBox />
                </Box>
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <Input
                      name='name'
                      label='Full Name'
                      type='text'
                      placeholder='Full Name'
                      value={props.values.name}
                      onChange={(e) => props.setFieldValue('name', e.target.value)}
                      error={props.errors.name || props.touched.name ? props.errors.name : '' }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      name='email'
                      label='Email'
                      type='email'
                      placeholder='Email'
                      value={props.values.email}
                      onChange={(e) => props.setFieldValue('email', e.target.value)}
                      error={props.errors.email || props.touched.email ? props.errors.email : '' }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      name='password'
                      label='Password'
                      type='password'
                      placeholder='Password'
                      value={props.values.password}
                      onChange={(e) => props.setFieldValue('password', e.target.value)}
                      error={props.errors.password || props.touched.password ? props.errors.password : '' }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      name='role'
                      label='Role'
                      value={props.values.role}
                      onChange={(e: string) => props.setFieldValue('role', e)}
                    >
                      {
                        account?.role === 'super' ? (
                          <Option value='super'>Super Administrator</Option>
                        ) : (
                          <>
                            <Option value='manager'>Manager</Option>
                            <Option value='member'>Member</Option>
                          </>
                        )
                      }
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    {
                      props.values.role === 'super' ? (
                        <Button
                          htmlType='submit'
                          disabled={!props.isValid}
                          className='primary-contained'
                          onClick={() => handleGoOrganization(props)}
                        >
                          Go to my organization
                        </Button>
                      ) : (
                        <CustomSelect
                          label='Team'
                          name='team'
                          options={teamOptions}
                          placeholder='Select a team'
                          styles={{
                            control: (style) => ({ ...style, width: '100%' })
                          }}
                          value={props.values.team}
                          onChange={(e) => props.setFieldValue('team', { value: e?.value, label: e?.label })}
                          onClear={() => handleClear(props)}
                        />
                      )
                    }
                  </Grid>
                </Grid>
                <Box display='flex' mt={15}>
                  <Box ml='auto'>
                    <Button style={{ marginRight: '1rem' }}>
                      <Link to='/dashboard'>Cancel</Link>
                    </Button>
                    <Button
                      htmlType='submit'
                      className='primary-contained'
                      disabled={!props.isValid}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        )
      }
    </S.Container>
  )
}
export default Account;
