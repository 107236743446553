import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getTimeSheets } from "../../../../store/selectors";
import { ITimeSheetInterface, setTimeSheet, setTimeSheets } from "../../../../store/actions/timesheet.action";
import { timeStyle } from "../ViewAndEdit/timeCalculator";
import { stringAvatar } from "../../Financials/Expenses/helper";
import { Box } from '@material-ui/core'
import { TableContainer } from '../../../../components/Dashboard/CommonStyled'
import { Avatar, Table, Tag } from 'antd'
import { IMemberInterface } from "../../../../components/Dashboard/Team/AddTeamModal";
import styled from 'styled-components'
import TableActionMenu from '../../../../components/Dashboard/TableActionMenu'
import TimeSheetModal from "../../../../components/Dashboard/TimeSheet/TimeSheetModal";
import moment from 'moment'
import * as TimeSheetApi from '../../../../apis/timesheet.api'

const ActivityView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e1e4e8;
  color: white;
`

const ApprovalTable = ({ filterParams }) => {
  const dispatch = useDispatch();
  const timeSheets = useSelector(getTimeSheets);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [workTime, setWorkTime] = useState<number>(0);

  const columns = [
    {
      title: 'Member',
      dataIndex: 'developer',
      render: (developer: IMemberInterface) => (
        <Box display='flex' alignItems='center'>
          {
            developer.image ? (
              <img
                src={`${process.env.REACT_APP_API_SERVER}/${developer.image.path}`}
                alt="avatar"
                style={{ width: 32, height: 32, borderRadius: '50%' }}
              />
            ) : (
              <Avatar
                {...stringAvatar(developer.name)}
              />
            )
          }
          <span style={{ marginLeft: 5 }}>{developer.name}</span>
        </Box>
      )
    },
    {
      title: 'Pay period',
      dataIndex: 'pay_period',
      render: (period: { start: Date, end: Date }) => (
        <span>{moment(period.start).format('DD/MM/YYYY HH:mm:ss')} - {moment(period.end).format('DD/MM/YYYY HH:mm:ss')}</span>
      )
    },
    {
      title: 'Total',
      dataIndex: 'total',
      render: (total: number) => <span>{timeStyle(total).hour}:{timeStyle(total).minute}:{timeStyle(total).second}</span>
    },
    {
      title: 'Activity',
      dataIndex: 'activity',
      render: (activity: string) => (
        <ActivityView>{activity || '-'}</ActivityView>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: string) => <Tag color="gold-inverse">{status}</Tag>
    },
    {
      title: 'Submitted on',
      dataIndex: 'submitted_on',
      render: (submitted_on: Date) => <span>{submitted_on ? moment(submitted_on).format('DD/MM/YYYY') : '-'}</span>
    },
    {
      title: '',
      key: 'operation',
      width: 100,
      render: (row: ITimeSheetInterface) => (
        <TableActionMenu
          row={row}
          handleDeleteRow={handleDeleteRow}
          handleEditRow={handleEditRow}
        />
      )
    }
  ];

  useEffect(() => {
    if (!openModal) {
      TimeSheetApi.getTimeSheetsApproval(filterParams).then((res) => dispatch(setTimeSheets(res)));
    }
  }, [openModal]);

  const tableData = useMemo(() => {
    return timeSheets.map((timeSheet) => (
      {
        id: timeSheet.id,
        key: timeSheet.id,
        developer: timeSheet.developer,
        pay_period: {
          start: timeSheet.startDate,
          end: timeSheet.endDate
        },
        total: (new Date(timeSheet.endDate).getTime() - new Date(timeSheet.startDate).getTime()) / 1000,
        activity: '',
        status: 'Open',
        submitted_on: timeSheet.createdAt,
      }
    ))
  }, [timeSheets]);

  const handleDeleteRow = async (id: string) => {
    await TimeSheetApi.deleteById(id);
    TimeSheetApi.getTimeSheetsApproval(filterParams).then((res) => dispatch(setTimeSheets(res)));
  };

  const handleEditRow = async (id: string) => {
    await TimeSheetApi.getById(id).then((res) => dispatch(setTimeSheet(res)));
    setOpenModal(true);
  };

  return (
    <>
      <TableContainer>
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          scroll={{x: 'max-content'}}
        />
        <Box mt={2}>
          <p>Showing { tableData.length } timesheets</p>
        </Box>
      </TableContainer>
      <TimeSheetModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        filterParams={null}
        setWorkTime={setWorkTime}
        secondReading={0}
      />
    </>
  )
}

export default ApprovalTable;
