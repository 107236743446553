import { Tabs as ATabs } from 'antd'
import { Box } from '@material-ui/core'
import styled from 'styled-components'

const AppPage = styled(Box)`
  position: relative;
  width: 100%;
  min-height: 100%;
  overflow: auto hidden;
  padding: 32px;

  ${props => props.theme.breakpoints.down('xs')} {
    padding: 15px;
  }
`

const Tabs = styled(ATabs)`
  .ant-tabs-nav {
    width: fit-content;

    ${props => props.theme.breakpoints.down('sm')} {
      width: initial;
    }
  }

  .ant-tabs-tab {
    padding: 8px 15px;
    margin: 0 !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${props => props.theme.colors.blue50};
  }

  .ant-tabs-ink-bar {
    position: absolute;
    background: ${props => props.theme.colors.blue50};
    pointer-events: none;
  }

  .ant-tabs-tab-btn {
    color: ${props => props.theme.palette.dashboard.textLight};
  }
`

const TableContainer = styled(Box)`
  .ant-table {
    color: ${props => props.theme.palette.dashboard.textPrimary};

    .ant-table-thead {
      th {
        background-color: white;
        border-bottom-width: 2px;
        color: ${props => props.theme.palette.dashboard.textDark};
        font-weight: bold;

        &::before {
          display: none;
        }
      }
    }

    .ant-table-tbody {
      td {
        span:not('.ant-tag') {
          color: ${props => props.theme.palette.dashboard.textPrimary};
        }
      }
    }
  }
`

const TabContainer = styled(Box)`
  position: absolute;
  display: flex;
  justify-content: center;
  width: calc(100% - 64px);
  top: 33px;

  ${props => props.theme.breakpoints.down('md')} {
    justify-content: flex-end;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    position: relative;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
    top: 0;
  }
`

export {
  AppPage,
  Tabs,
  TableContainer,
  TabContainer
}