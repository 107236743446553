import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { onSetStep } from "../../../store/actions/gettingStart.action";
import { CardContainer, Step } from "./style";
import {
  CheckCircleOutline,
  RadioButtonUnchecked
} from "@material-ui/icons";

interface IManageCardProps {
  order: number,
  title: string,
  description: string,
  details: Array<{ order: number, menu: string }>,
  step: number,
  setStep: (value: number) => void,
}

const ManageCard: FC<IManageCardProps> = ({
  order,
  title,
  description,
  details,
  step,
  setStep
}) => {
  const dispatch = useDispatch();

  const getIcons = (value:　number) => {
    if (step > value) {
      return <CheckCircleOutline style={{ color: '#178461', backgroundColor: '#def7ec', borderRadius: '50%' }} />
    }
    if (step < value) {
      return <RadioButtonUnchecked color='disabled' />;
    }
    if (step === value) {
      return <RadioButtonUnchecked style={{ color: '#178461' }} />;
    }
  };

  const handleMoveToStep = (value: number) => {
    if (step >= value) {
      setStep(value);
      dispatch(onSetStep(value));
    }
  };

  const checkedNumber = (value: number) => {
    switch (order) {
      case 1: {
        if (value <= 3) return value;
        else return 3;
      }
      case 2: {
        if (value <= 3) return 0;
        else if (value <= 5) return value - 3;
        else return 2;
      }
      case 3: {
        if (value >= 6) return value - 5;
        else return 0;
      }
      default: return 0;
    }
  };

  return (
    <CardContainer>
      <div className='header'>
        <div>
          <span>{order}.&nbsp;</span>
          <span>{title}</span>
        </div>
        <div className='confirmed'>
          <span
            id='checked_number'
            style={(checkedNumber(step - 1)) > 0 ? { color: 'black' } : {}}
          >
            {checkedNumber(step - 1)}
          </span>
          /{details.length}
        </div>
      </div>
      <div className='manage_description'>{description}</div>
      <div className='steps_container'>
        {
          details.map((item, index) => (
            <Step
              key={item.order}
              onClick={() => handleMoveToStep(item.order)}
            >
              <div>
                {getIcons(item.order)}
                {
                  index !== details.length - 1 && (
                    <div
                      style={
                      item.order < step
                        ? { backgroundColor: '#178461' }
                        : { backgroundColor: '#919aa6' }}
                      className='link_line'
                    />
                  )
                }
              </div>
              <span
                style={
                item.order > step
                  ? { color: '#919aa6' }
                  : item.order < step
                    ? { color: '#178461' }
                    : { color: 'black', fontWeight: 600 }}
              >
                {item.menu}
              </span>
            </Step>
          ))
        }
      </div>
    </CardContainer>
  )
}
export default ManageCard;
