import React from 'react'
import styled, { css } from "styled-components";
import { Select as ASelect } from 'antd'

export const { Option } = ASelect;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  span.label {
    color: ${(props) => props.theme.palette.dashboard.textLight};
    font-size: 11px;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
`;

const StyledSelect = styled(ASelect)<{ isCustom?: boolean }>`
  .ant-select-selector {
    border: 1px solid ${(props) => props.theme.colors.gray100} !important;
    border-radius: 5px !important;
    padding: 3px 11px !important;
      &:focus {
        border-color: #2aa7ff;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(42, 167, 255, 0.6);
      }
    height: unset !important;
      ${(props) => props.isCustom && css`
      height: 40px !important;
    `}
  }

    .ant-select-open {
      .ant-select-selector {
        border: 1px solid ${props => props.theme.colors.gray100} !important;
        border-radius: 5px !important;
        padding: 3px 11px !important;
        &:focus {
          border-color: #2aa7ff;
          outline: 0;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 8px rgba(42, 167, 255, 0.6);
        }
        height: unset !important;
        ${(props) => props.isCustom && css`
      height: 40px !important;
    `}
      }
    }
`

const Select = (props) => {
  const { label, className, isCustom, onChange, value, placeholder, ...rest } = props

  return (
    <Container className={className}>
      {label && <span className='label'>{label}</span>}
      <StyledSelect placeholder={placeholder} value={value} onChange={onChange} {...rest}/>
    </Container>
  );
};

export default Select;
