import React from "react";
import { ActivityContainer } from "../style";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Box } from "@material-ui/core";
import Diagram from "./component/Diagram";
import ProgressBar from "./component/ProgressBar";

const data = { average: 45 };

const ActivityDiagram = () => {

  return (
    <ActivityContainer>
      <Box style={{ padding: '0.5rem 0.6rem' }}>
        <div className='activity_title'>
          <span>ACTIVITY</span>
          <ExclamationCircleFilled />
        </div>
        <div className='diagram_vertical_container'>
          <div>
            <p className='average_number'>{data.average}%</p>
            <p>Average</p>
          </div>
          <Diagram average={data.average} />
        </div>
      </Box>
      <Box style={{ padding: '0.5rem 0.6rem', borderTop: '1.5px solid #d1d4d5', marginTop: '0.5rem' }}>
        <ProgressBar average={data.average} />
      </Box>
    </ActivityContainer>
  )
}
export default ActivityDiagram;
