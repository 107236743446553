import _ from "lodash";

export function stringToColor(string: string) {
  let hash = 0;
  let i: number;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    // tslint:disable-next-line:no-bitwise
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    // tslint:disable-next-line:no-bitwise
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string) {
  return {
    style: {
      backgroundColor: stringToColor(name)
    },
    children: _.upperFirst(name.slice(0, 1))
  };
}

export const paymentStatus = (status: string) => {
  switch (status) {
    case 'invoiced':
      return {
        text: 'Invoiced',
        style: {backgroundColor: '#2aa7ff', color: 'white'},
      }
    case 'uninvoiced':
      return {
        text: 'Uninvoiced',
        style: {backgroundColor: '#ff4949', color: 'white'},
      }
    case 'paid':
      return {
        text: 'Paid',
        style: {backgroundColor: '#18d06b', color: 'white'},
      }
    case 'not_billable':
      return {
        text: 'Not billable',
        style: {backgroundColor: '#e3e8ef', color: '#919caa'},
      }
    default:
      return;
  }
};

export const categoryItem = (category: string) => {
  switch (category) {
    case 'uncategorized':
      return {
        text: 'Uncategorized',
        style: {border: 'none', color: '#919caa'}
      }
    case 'travel':
      return {
        text: 'Travel',
        style: {borderColor: '#7ee6aa', color: '#7ee6aa'}
      }
    case 'equipment':
      return {
        text: 'Equipment',
        style: {borderColor: '#8b1dff', color: '#8b1dff'}
      }
    default:
      return;
  }
};

export const amountStyle = (value: number) => {
  const data = [] as string[];
  const textArray = value.toString().split('').reverse();
  textArray.map((item, index) => {
    data.push(item);
    if ((index + 1) % 3 === 0 && index !== textArray.length - 1) {
      data.push(',');
    }
  });

  return '$' + data.reverse().join('');
};
