import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { onGetStep } from "../../../store/selectors/gettingStart.selector";
import { getAccount } from "../../../store/selectors";
import { ArrowForward } from "@material-ui/icons";
import { MenuContainer } from "./style";

const GettingStartMenuItem = ({ name }) => {
  const location = useLocation();
  const history = useHistory();
  const step = useSelector(onGetStep);
  const account = useSelector(getAccount);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
   if (location.pathname.split('/')[1] === 'getting_started') {
     setOpen(true);
   } else {
     setOpen(false);
   }
  }, [location]);

  const handleGettingStart = () => {
    if (account) {
      history.push(`/getting_started/${account.organization.id}`);
      setOpen(true);
    }
  };

  return (
    <MenuContainer
      onClick={handleGettingStart}
      open={open}
      step={step}
    >
      <div className='item'>
        <span>{name}</span>
        <ArrowForward fontSize='small' />
      </div>
      <div className='progress_items'>
        <div className='progress_item'>
          {
            step >= 4 ? (
              <div style={{ backgroundColor: '#2aa7ff', width: '100%' }} />
            ) : (
              <div style={{ backgroundColor: '#2aa7ff', width: `${(step - 1) * 100 / 3}%` }} />
            )
          }
        </div>
        <div className='progress_item'>
          {
            step >= 6 ? (
              <div style={{ backgroundColor: '#2aa7ff', width: '100%' }} />
            ) : step >= 5 ? (
              <div style={{ backgroundColor: '#2aa7ff', width: `${(step - 4) * 100 / 2}%` }} />
            ) : <></>
          }
        </div>
        <div className='progress_item'>
          {
            step <= 6 ? (
              <></>
            ) : (
              <div style={{ backgroundColor: '#2aa7ff', width: `${(step - 6) * 100 / 2}%` }} />
            )
          }
        </div>
      </div>
    </MenuContainer>
  )
}
export default GettingStartMenuItem;
