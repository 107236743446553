import * as types from "../actionTypes";

export interface IClientInterface {
  id: string,
  name: string,
}

export const setClients = (value: IClientInterface[]) => {
  return {
    type: types.SET_CLIENTS,
    payload: value,
  }
}
