import React from "react";
import { useHistory } from "react-router";
import { Logo } from "../../Common/Icons";
import Stepper from "../Stepper";
import * as S from "./style";

interface Props {
  children: JSX.Element;
  step: number;
}

const WizardLayout = (props: Props) => {
  const { children, step } = props;
  const history = useHistory();

  return (
    <S.Container>
      <div className="illustration-container no-steps">
        <div className="steps-wrapper">
          <div className="title">Organization set up</div>
          <Stepper step={step} />
        </div>
      </div>
      <div className="onboarding-container">
        <div className="content welcome">
          <div className="hubstaff-logo">
            <div
              className="mb-5 ml-auto welcome-top-logo logo-new-onboarding"
              style={{ cursor: 'pointer' }}
              onClick={() => history.push('/auth/sign-in')}
            >
              <Logo height={50} />
            </div>
          </div>
          <div className="form-container">{children}</div>
        </div>
      </div>
    </S.Container>
  );
};

export default WizardLayout;
