import React, { useState } from "react";
import { CssBaseline } from "@material-ui/core";
import styled, { createGlobalStyle } from "styled-components/macro";
import CheckIcon from "@material-ui/icons/Check";
import AdjustIcon from "@material-ui/icons/Adjust";
import { RadioButtonUnchecked } from "@material-ui/icons";

const Root = styled.div`
  width: 100%;
  height: 100vh;
`;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }
  body {
    background: white;
  }
`;

const OrganizationsWizardLayout = ({ children }) => {
  const [status, setStatus] = useState(0);
  const getIcons = (value: number) => {
    return status > value ? (
      <div className="check-icon">
        <CheckIcon style={{ color: "white" }} width={6} height={6} color="inherit" />
      </div>
    ) : status < value ? (
      <RadioButtonUnchecked style={{ color: "white" }} />
    ) : (
      <AdjustIcon style={{ color: "white" }} />
    );
  };
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  );
};

export default OrganizationsWizardLayout;
