import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {　getTasks　} from "../../../../store/selectors/task.selector";
import { setTask, setTasks } from "../../../../store/actions/task.action";
import {　TableContainer　} from "../../../../components/Dashboard/CommonStyled";
import {　Box　} from "@material-ui/core";
import {　MemberList　} from "../../Team/TeamTable";
import { Avatar, Table } from "antd";
import { stringAvatar } from "../../Financials/Expenses/helper";
import {　IProjectInterface} from "../../../../components/Dashboard/Poject/AddProjectModal";
import { IMemberInterface } from "../../../../components/Dashboard/Team/AddTeamModal";
import { ITaskInterface } from "../../../../components/Dashboard/Todo/AddTaskModal";
import moment from "moment-timezone";
import TableActionMenu from "../../../../components/Dashboard/TableActionMenu";
import * as TaskApi from '../../../../apis/task.api';

const TaskTable = ({ setOpenModal, filterParam }) => {
  const dispatch = useDispatch();
  const tasks = useSelector(getTasks);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name: string) => (
        <>
          <Avatar
            {...stringAvatar(name)}
          />
          <span style={{ marginLeft: 5, fontWeight: 500 }}>{name}</span>
        </>
      )
    },
    {
      title: 'Developers',
      dataIndex: 'developer',
      render: (developer: IMemberInterface[]) => (
        <MemberList>
          { developer.map((member, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: 3, marginBottom: 3 }}>
              {
                member.image ? (
                  <img
                    src={`${process.env.REACT_APP_API_SERVER}/${member.image.path}`}
                    alt="developers"
                    style={{ width: 32, height: 32, borderRadius: '50%' }}
                  />
                ) : (
                  <Avatar
                    {...stringAvatar(member.name)}
                  />
                )
              }
              <div style={{ marginLeft: 5 }}>{member.name}</div>
            </div>))
          }
        </MemberList>
      )
    },
    {
      title: 'Project',
      dataIndex: 'project',
      render: (project: IProjectInterface) => (
        <>
          <Avatar
            {...stringAvatar(project.name)}
          />
          <span style={{ marginLeft: 5, fontWeight: 500 }}>{project.name}</span>
        </>
      )
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      render: (startDate: Date) => {
        return (
          <div>{moment(new Date(startDate)).format('DD/MM/YYYY')}</div>
        )
      }
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      render: (duration: number) => {
        return (
          <div>{duration}h</div>
        )
      }
    },
    {
      title: '',
      key: 'operation',
      width: 100,
      render: (row: ITaskInterface) => (
        <TableActionMenu
          row={row}
          handleDeleteRow={handleDeleteRow}
          handleEditRow={handleEditRow}
        />
      )
    }
  ];

  const tableData = useMemo(() => {
    return tasks.map((task) => ({
      ...task,
      key: task.id
    }))
  }, [tasks]);

  const handleDeleteRow = async (id: string) => {
    await TaskApi.deleteById(id);
    TaskApi.getAll(filterParam).then((res) => dispatch(setTasks(res)));
  }

  const handleEditRow = async (id: string) => {
    await TaskApi.getById(id).then((res) => dispatch(setTask(res)));
    setOpenModal(true);
  }

  return (
    <TableContainer>
      <div style={{ marginTop: '3rem' }}>
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
        <Box mt={2}>
          <p>Showing { tableData.length } of { tableData.length } { tableData.length > 1 ? 'tasks' : 'task' }</p>
        </Box>
      </div>
    </TableContainer>
  )
}
export default TaskTable;
