import * as types from "../actionTypes";

const initialValue: {
  step: number;
} = {
  step: 1,
}

const stepReducer = (state = initialValue, action: any) => {
  switch (action.type) {
    case types.SET_STEP: {
      return {
        ...state,
        step: action.payload
      }
    }
    default:
      return state;
  }
}
export default stepReducer;
