import Http from './http'
import { IOrganization } from "../shared/interfaces";
import { ISubscribeOptionInterface } from "../components/Dashboard/GettingStart/Details/SubscribeToReport";

export const create = ({ userId, values }: { userId: string | null, values: IOrganization }) => {
  return Http.post(`/organization/${userId}`, values);
}

export const getById = (id: string) => {
  return Http.get(`/organization/${id}`);
}

export const update = ({ id, value }: { id: string, value: IOrganization }) => {
  return Http.patch(`/organization/${id}`, value);
}

export const subscribeToReport = ({ id, options }: { id: string, options: ISubscribeOptionInterface[] }) =>　{
  const value = options.map((option) => (
    {
      [option.id]: option.state,
    }
  ));

  return Http.patch(`/organization/subscribe/${id}`, value);
}

export const pickProjects = ({ id, pickedProjects }: { id: string, pickedProjects: string[] }) => {
  return Http.patch(`/organization/pick_projects/${id}`, pickedProjects);
}
