import React from 'react'

import Card from './Card'
import ThisWeekImg from '../../../../assets/images/dashboard/this_week.png'

const ThisWeek = (props: { index: number }) => {
  const { index } = props
  return (
    <Card
      title='This Week'
      index={index}
    >
      <div className='card-body'>
        <img src={ThisWeekImg} alt='Recent Activity' />
        <p>No time entries for this week yet.</p>
      </div>
    </Card>
  )
}

export default ThisWeek