import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTimeSheets } from "../../../../store/selectors";
import {ITimeSheetInterface, setTimeSheet, setTimeSheets} from "../../../../store/actions/timesheet.action";
import { timeStyle } from "./timeCalculator";
import { stringAvatar } from "../../Financials/Expenses/helper";
import { TableContainer } from "../../../../components/Dashboard/CommonStyled";
import { Box } from "@material-ui/core";
import { Avatar, Table } from "antd";
import { IFilterInterface } from "./index";
import TableActionMenu from "../../../../components/Dashboard/TableActionMenu";
import moment from "moment-timezone";
import * as TimeSheetApi from "../../../../apis/timesheet.api";
import {IMemberInterface} from "../../../../components/Dashboard/Team/AddTeamModal";
import {IProjectInterface} from "../../../../components/Dashboard/Poject/AddProjectModal";

interface IProps {
  setOpenModal: (value: boolean) => void;
  filterParams: IFilterInterface;
  setWorkTime: (value: number) => void;
  secondReading: number;
  setProgress: (value: boolean) => void;
  setSecondReading: (value: number) => void;
}

const TimeSheetTable = ({
  setOpenModal,
  filterParams,
  setWorkTime,
  secondReading,
  setProgress,
  setSecondReading
}: IProps) => {
  const dispatch = useDispatch();
  const timeSheets = useSelector(getTimeSheets);

  const columns = [
    {
      title: 'Developer',
      dataIndex: 'developer',
      render: (developer: IMemberInterface) => (
        <Box display='flex' alignItems='center'>
          {
            developer.image ? (
              <img
                src={`${process.env.REACT_APP_API_SERVER}/${developer.image.path}`}
                alt="avatar"
                style={{ width: 32, height: 32, borderRadius: '50%' }}
              />
            ) : (
              <Avatar
                {...stringAvatar(developer.name)}
              />
            )
          }
          <span style={{ marginLeft: 5 }}>{developer.name}</span>
        </Box>
      )
    },
    {
      title: 'Project',
      dataIndex: 'project',
      render: (project: IProjectInterface) => (
        <Box display='flex' alignItems='center'>
          <Avatar
            {...stringAvatar(project.name)}
          />
          <span style={{ marginLeft: 5 }}>{project.name}</span>
        </Box>
      )
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      render: (startDate: Date) => (
        <div>{moment(new Date(startDate)).format('DD/MM/YYYY HH:mm:ss')}</div>
      )
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      render: (endDate: Date) => (
        <div>{moment(new Date(endDate)).format('DD/MM/YYYY HH:mm:ss')}</div>
      )
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      render: (duration: number) => (
        <div>
          {timeStyle(duration).hour}:{timeStyle(duration).minute}:{timeStyle(duration).second}
        </div>
      )
    },
    {
      title: '',
      key: 'operation',
      width: 100,
      render: (row: ITimeSheetInterface) => (
        <TableActionMenu
          row={row}
          handleDeleteRow={handleDeleteRow}
          handleEditRow={handleEditRow}
        />
      )
    }
  ];

  const tableData = useMemo(() => {
    return timeSheets.map((item) => {
      if (item.progressing) {
        return {
          ...item,
          key: item.id,
          endDate: moment(item.endDate).add(secondReading, 'second'),
          duration: item.duration + secondReading,
        }
      } else {
        return {
          ...item,
          key: item.id,
        }
      }
    })
  }, [timeSheets, secondReading]);

  const handleDeleteRow = async (id: string) => {
    setProgress(false);
    setSecondReading(0);
    await TimeSheetApi.deleteById(id);
    TimeSheetApi.getTimeSheets(filterParams).then((res) => {
     dispatch(setTimeSheets(res.data));
     setWorkTime(res.time);
    });
  };

  const handleEditRow = async (id: string) => {
    await TimeSheetApi.getById(id).then((res) => dispatch(setTimeSheet(res)));
    setOpenModal(true);
  };

  return (
    <TableContainer>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        scroll={{x: 'max-content'}}
      />
      <Box mt={2}>
        <p>{ timeSheets.length } { timeSheets.length > 1 ? 'time sheets' : 'time sheet' }</p>
      </Box>
    </TableContainer>
  )
}
export default TimeSheetTable;
