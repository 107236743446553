import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Button as AButton } from 'antd'
import { Box, Drawer as MuiDrawer } from '@material-ui/core'

const BackButton = styled(Link)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.palette.dashboard.textPrimary};
  transition: all ease 0.3s;

  svg {
    font-size: 20px;
    color: ${props => props.theme.colors.blue50};
  }

  &:hover {
    color: ${props => props.theme.colors.blue50};
  }
`

const IconButton = styled(AButton)`
  border: none !important;
  display: flex;
  align-items: center;
  padding: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;

  img {
    margin-right: 5px;
  }

  span {
    color: ${props => props.theme.colors.blue50};
  }
`

const ItemWrapper = styled(Box)`
  display: grid;
  grid-gap: 20px;
  padding: 20px;
  border: 1px solid ${props => props.theme.colors.gray100};
  border-radius: 5px;
  margin-top: 20px;

  .item {
    padding-right: 20px;
    border-right: 1px solid ${props => props.theme.colors.gray100};

    &:last-child {
      border: none;
    }

    .label {
      text-transform: uppercase;
      font-size: 11px;
      color: ${props => props.theme.colors.gray200};
    }

    h1 {
      color: ${props => props.theme.colors.gray100} !important;
    }
  }

  ${props => props.theme.breakpoints.down('xs')} {
    grid-template-columns: 1fr !important;

    .item {
      border-right: none;
      border-bottom: 1px solid ${props => props.theme.colors.gray100};
    }
  }
`

const SubPanel = styled.div`
  margin-top: 40px;

  .title {
    h2 {
      font-weight: bold;
    }

    span {
      font-size: 14px;
      margin-bottom: 4px;
      color: ${props => props.theme.palette.dashboard.textLight};
      margin-left: 5px;
    }
  }

  .actions {
    button {
      margin: 0 20px !important;
    }

    ${props => props.theme.breakpoints.down('xs')} {
      margin-top: 10px;

      button {
        margin: 0 10px !important;
      }
    }
  }

  .group-by-select {
    display: flex;
    align-items: center;

    .label {
      display: inline-flex;
      align-items: center;
      color: ${props => props.theme.palette.dashboard.textPrimary};
      font-size: 16px;
      font-weight: bold;

      svg {
        margin-right: 5px;
      }
    }
    span {
      color: ${props => props.theme.colors.blue50};
      font-weight: bold;
    }
  }

  .no-data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;

    img {
      width: 160px;
    }

    h3 {
      font-size: 20px;
      font-weight: bold;
      margin: 10px 0 12px;
    }

    p {
      font-weight: bold;
      font-size: 16px;
      text-align: center;
    }
  }
`

const SettingButton = styled.div`
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.gray100};
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  transition: all ease 0.3s;

  svg {
    color: ${props => props.theme.palette.dashboard.textPrimary};
    transition: all ease 0.3s;
    font-size: 20px;
  }

  &:hover {
    svg {
      color: ${props => props.theme.colors.blue50};
    }
  }

  &.disabled {
    background-color: #f4f5f8;
    pointer-events: none;
  }
`

const Drawer = styled(MuiDrawer)`
  width: 345px;
  flex-shrink: 0;

  .MuiDrawer-paper {
    width: 345px;
    margin-top: 45px;
    overflow: visible;
    box-shadow: -3px -5px 15px 2px rgba(103, 99, 99, 0.2);
  }

  ${props => props.theme.breakpoints.down(500)} {
    width: 70%;

    .MuiDrawer-paper {
      width: 70%;
    }
  }
`

const FilterGroup = styled.div`
  display: flex;
  justify-content: flex-end;

  ${props => props.theme.breakpoints.down(500)} {
    width: 100%;

    > div:first-child {
      flex: 1;

      .show-date {
        width: 100%;
      }
    }
  }
`

export {
  BackButton,
  IconButton,
  ItemWrapper,
  SubPanel,
  SettingButton,
  Drawer,
  FilterGroup
}
