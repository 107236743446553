import React from "react";
import { HorizontalDiagramContainer } from "../../style";

const scales = [0, 25, 50, 75, 100];

const HorizontalDiagram = ({ data }) => {
  return (
    <HorizontalDiagramContainer>
      <div
        style={{
          height: '1rem',
          width: `${data.core_work}%`,
          backgroundColor: '#2aa7ff',
          borderTopLeftRadius: 5,
          borderBottomLeftRadius: 5 }}
      />
      <div
        style={{
          height: '1rem',
          width: `${data.non_core_work}%`,
          backgroundColor: '#6b7280'}}
      />
      <div
        style={{
          height: '1rem',
          width: `${data.unproductive}%`,
          backgroundColor: '#e7721a',
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5 }}
      />
      {
        scales.map((scale, index) => (
          <div key={index} className='scale' style={{ left: `${scale}%` }}>
            <span style={{ fontSize: 6 }}>|</span>
            <span style={
              scale === 100
                ? { fontSize: 10, marginTop: '1px', marginLeft: '-20px' }
                : { fontSize: 10, marginTop: '1px', marginLeft: '-2px' }}
            >
              {scale}%
            </span>
          </div>
        ))
      }
    </HorizontalDiagramContainer>
  )
}
export default HorizontalDiagram;
