import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getMembers } from "../../../../store/selectors";
import MemberSelector from "../../../Common/MemberSelector";

const MembersTab = (props) => {
  const users = useSelector(getMembers);

  const members = useMemo(() => {
    return users.filter((user) => user.role === 'member');
  }, [users]);

  const managers = useMemo(() => {
    return users.filter((user) => user.role === 'manager');
  }, [users]);

  return (
    <>
      <MemberSelector
        members={managers}
        label="MANAGERS"
        description="Oversees and manages the team"
        { ...props }
      />
      <MemberSelector
        members={members}
        label="MEMBERS"
        description="Works on the team, will not see other users (most common)"
        { ...props }
      />
    </>
  )
}
export default MembersTab;
