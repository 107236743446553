import React, { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTeam, setTeams } from "../../../../store/actions/team.action";
import { getTeam } from "../../../../store/selectors";
import { setMembers } from "../../../../store/actions/member.action";
import { Tabs } from "../../CommonStyled";
import { Form, Formik, FormikProps } from "formik";
import { IOrganization } from "../../../../shared/interfaces";
import { UploadedFileType } from "../../../../pages/Dashboard/Account/AvatarBox";
import * as S from "./style";
import * as Yup from "yup";
import Modal from "../../Modal";
import Button from "../../Button";
import GeneralTab from "./GeneralTab";
import MembersTab from "./MembersTab";
import * as TeamApi from "../../../../apis/team.api";
import * as UserApi from "../../../../apis/user.api";

const { TabPane } = Tabs;

interface IProps {
  onClose: () => void;
  open: boolean;
  searchValue: string;
}

export interface ITeamInterface {
  id?: string;
  name: string;
  managers: string[] | string;
  members: string[] | string;
}

export interface IMemberInterface {
  id?: string;
  name: string;
  email: string;
  organization: IOrganization;
  image: UploadedFileType;
  team: string;
  role: string;
  checked: boolean;
}

const AddTeamModal: FC<IProps> = ({ onClose, open, searchValue }) => {
  const dispatch = useDispatch();
  const team = useSelector(getTeam);

  useEffect(() => {
    if (!open) {
      dispatch(setTeam(null));
    }
  }, [open]);

  useEffect(() => {
    UserApi.getAll().then((res) => dispatch(setMembers(res)));
  }, []);

  const teamSchema = Yup.object().shape({
    name: Yup.string().required('can`t be blank'),
    managers: Yup.array(),
    members: Yup.array(),
  });

  const initialValue = useMemo(() => {
    return {
      name: team?.name || '',
      managers: team?.managers || [],
      members: team?.members || [],
    };
  }, [team]);

  const handleSubmit = async (value: ITeamInterface) => {
    if (team?.id) {
      await TeamApi.update({
        id: team.id,
        data: value
      })
    } else {
      await TeamApi.create(value);
    }
    await TeamApi.getAll(searchValue).then((res) => dispatch(setTeams(res)));
    onClose();
  };

  return (
    <Modal title="Add team" onClose={onClose} open={open}>
      <S.ModalContainer>
        <Formik
          initialValues={initialValue}
          validationSchema={teamSchema}
          onSubmit={handleSubmit}
        >
          {(props: FormikProps<ITeamInterface>) => (
            <Form onSubmit={props.handleSubmit}>
              <div className="tab-content">
                <Tabs defaultActiveKey="1">
                  <TabPane tab='GENERAL' key="1">
                    <GeneralTab {...props} />
                  </TabPane>
                  <TabPane tab='MEMBERS' key="2">
                    <MembersTab data={team} { ...props } />
                  </TabPane>
                </Tabs>
              </div>
              <div className="modal-footer">
                <Button className="gray-outlined" onClick={onClose}>Cancel</Button>
                <Button
                  htmlType='submit'
                  className="primary-contained"
                  disabled={!props.isValid}
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </S.ModalContainer>
    </Modal>
  )
}
export default AddTeamModal;
